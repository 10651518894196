import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const SearchIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M16.0416 16.0413L12.9166 12.9163M3.95825 9.16634C3.95825 6.28986 6.2901 3.95801 9.16659 3.95801C12.0431 3.95801 14.3749 6.28986 14.3749 9.16634C14.3749 12.0428 12.0431 14.3747 9.16659 14.3747C6.2901 14.3747 3.95825 12.0428 3.95825 9.16634Z"
        stroke="#D8DADB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
