const EyeIconAlt = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="eye-icon-alt"
    >
      <path
        d="M12.8327 8.00065C12.8327 8.66732 11.666 12.1673 7.99935 12.1673C4.33268 12.1673 3.16602 8.66732 3.16602 8.00065C3.16602 7.33398 4.33268 3.83398 7.99935 3.83398C11.666 3.83398 12.8327 7.33398 12.8327 8.00065Z"
        stroke="var(--app-text)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8Z"
        stroke="var(--app-text)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIconAlt;
