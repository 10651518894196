import { useEffect } from 'react';

import { errorMessages } from '../services/errors';
import { ErrorNode, handleError, handleSuccess } from '../utils/notifications';

export enum NOTIFICATION_TYPES {
  success,
  error,
}

interface IUseNotification {
  type: NOTIFICATION_TYPES.success | NOTIFICATION_TYPES.error;
  status: boolean;
  textSuccess?: string;
  error?: ErrorNode | null;
  afterCreate?: (...args: any[]) => void;
}

export const useNotification = ({
  type,
  status,
  textSuccess = 'Success',
  error,
  afterCreate,
}: IUseNotification) => {
  const errorMessage =
    error &&
    error?.data &&
    Object.values((error as ErrorNode).data).reduce((acc: string, val) => acc + val, '');

  useEffect(() => {
    if (!status) return;
    if (error?.originalStatus === 500 || error?.status === 500) {
      handleError(errorMessages.DEFAULT_ERROR_MESSAGE);

      return;
    }
    if (type === NOTIFICATION_TYPES.success) {
      handleSuccess(textSuccess);
      afterCreate?.();
    } else {
      if (!errorMessage && error?.status === 'FETCH_ERROR') {
        handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
      } else {
        handleError(errorMessage as string);
      }
      afterCreate?.();
    }
  }, [type, status, error]);
};
