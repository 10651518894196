import './index.scss';

import { createRef, Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { Link, To } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import { Timer, useDebounce, useOutsideClickDetector } from '../../../../tools/events';
import ArrowRightSmoothIcon from '../../assets/svg/icons/ArrowRightSmoothIcon';
import { SmoothVisibility } from '../smoothVisibility';

export interface IDropdownItem {
  kind: 'item';
  id?: string;
  icon?: ReactNode;
  text?: string | ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onLinkClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  isLink?: boolean;
  linkTo?: To;
  linkExternal?: boolean;
  active?: boolean;
  danger?: boolean;
  actionButton?: ReactNode;
  description?: string | ReactNode;
  dontCloseOnClick?: boolean;
  onHoverCheckmarkContent?: ReactNode;
  className?: string;
  submenu?: IDropdownItem[];
}

export interface IDropdownSeparator {
  kind: 'separator';
}

export interface IDropdownInfo {
  kind: 'info';
  text: ReactNode;
  id: string;
}

export type IDropdownGenericItem = IDropdownItem | IDropdownSeparator | IDropdownInfo;

export interface IDropdown {
  children?: ReactNode;
  items?: IDropdownGenericItem[];
  disabled?: boolean;
  hidden?: boolean;
  verticalPosition?: 'top' | 'bottom';
  horizontalPosition?: 'left' | 'center' | 'right';
  className?: string;
  triggerBy?: 'click' | 'hover';
  onOpen?: () => void;
  onClose?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}
interface IDropdownItemProps {
  item: IDropdownGenericItem;
  setVisible?: (visible: boolean) => void;
}
function DropdownItem({ item, setVisible }: IDropdownItemProps) {
  // eslint-disable-next-line
  {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const innerContent =
      item.kind === 'item' ? (
        <>
          {item.icon}
          {item.text && (
            <div className="dropdown-item-title">
              {item.text}
              {item.description && (
                <div className="dropdown-item-description">{item.description}</div>
              )}
            </div>
          )}
          <div className="status-icon">
            {isHovered && item.onHoverCheckmarkContent
              ? item.onHoverCheckmarkContent
              : item.actionButton}
          </div>
        </>
      ) : item.kind === 'info' ? (
        <div className="dropdown-item-info">{item.text}</div>
      ) : null;
    const content =
      item.kind === 'item' && item.submenu ? (
        <Dropdown horizontalPosition="left" triggerBy="hover" items={item.submenu}>
          {innerContent}
          <span className="arrow-icon">
            <ArrowRightSmoothIcon />
          </span>
        </Dropdown>
      ) : (
        innerContent
      );

    const className =
      item.kind === 'item'
        ? 'dropdown-item' +
          (item?.active ? ' active' : '') +
          (item?.danger ? ' danger' : '') +
          (item?.submenu ? ' has-submenu' : '')
        : undefined;

    return (
      <Fragment>
        {item.kind === 'item' ? (
          <>
            {!item.isLink ? (
              <button
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                }}
                className={`${className} ${item.className}`}
                onClick={e => {
                  if (item.onClick) item.onClick(e);
                  if (!item.dontCloseOnClick && setVisible) setVisible(false);
                }}
              >
                {content}
              </button>
            ) : item.linkExternal ? (
              <a
                onClick={e => {
                  item.onLinkClick?.(e);
                  if (!item.dontCloseOnClick && setVisible) setVisible(false);
                }}
                className={className}
                href={item.linkTo?.toString()}
              >
                {content}
              </a>
            ) : (
              <Link className={className} to={item.linkTo ?? '#'}>
                {content}
              </Link>
            )}
          </>
        ) : item.kind === 'info' ? (
          <>{content}</>
        ) : (
          <hr />
        )}
      </Fragment>
    );
  }
}
export function Dropdown({
  triggerBy = 'click',
  horizontalPosition = 'left',
  ...props
}: IDropdown) {
  const wrapperRef = createRef<HTMLDivElement>();
  const triggerRef = useRef<HTMLDivElement>();
  const dropdownRef = useRef<HTMLDivElement>();

  const [verticalPosition, setVerticalPosition] = useState<'bottom' | 'top'>(props.verticalPosition ?? 'bottom');

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useOutsideClickDetector(wrapperRef, () => {
    setIsVisible(false);
  });

  const windowSize = useWindowSize();

  const calcPosition = () => {
    if (props.verticalPosition) return;

    const triggerRect = triggerRef.current?.getBoundingClientRect();

    const height = triggerRect?.height ?? 0;
    const y = triggerRect?.top ?? 0;
    const endY = height + y;

    console.log({ height, y, windowSize, endY });
    if (endY > windowSize.height / 2) setVerticalPosition('top');
    else setVerticalPosition('bottom');
  };

  useEffect(() => {
    const effect = async () => {
      await Timer.sleep(1 / 1000);

      if (isVisible) props.onOpen?.();
      else props.onClose?.();
    };

    effect();
  }, [isVisible]);

  useEffect(() => {
    calcPosition();
  }, [isVisible]);

  useDebounce(() => {
    if (!isHovered && !props.disabled && triggerBy === 'hover') {
      setIsVisible(false);
    }
  }, [isHovered]);

  return (
    <SmoothVisibility
      visible={!props.hidden}
      containerRef={wrapperRef}
      className={'alchemy-dropdown-wrapper' + (props.className ? ` ${props.className}` : '')}
      onMouseEnter={() => {
        if (!props.disabled && triggerBy === 'hover') setIsVisible(true);

        setIsHovered(true);
        props.onMouseLeave?.();
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        props.onMouseLeave?.();
      }}
    >
      <div
        className={
          'alchemy-dropdown-trigger' +
          (isVisible ? ' expanded' : '') +
          (props.disabled ? ' disabled' : '')
        }
        ref={triggerRef}
        onClick={e => {
          if (!props.disabled && triggerBy === 'click') {
            e.preventDefault();
            setIsVisible(prev => !prev);
          }
        }}
      >
        {props.children}
      </div>
      <SmoothVisibility
        containerRef={dropdownRef}
        visible={isVisible}
        className={
          'alchemy-dropdown' +
          ` ${verticalPosition} ${horizontalPosition}` +
          (props.items?.some(item => item.kind === 'item' && !!item.submenu) ? ' has-submenu' : '')
        }
      >
        {props.items?.map((item, index) => (
          <DropdownItem item={item} key={index} setVisible={setIsVisible} />
        ))}
      </SmoothVisibility>
    </SmoothVisibility>
  );
}
