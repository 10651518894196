import { isMacOs } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';

export interface IPromptDetailsModalHotkeys {
  onSubmit: () => void;
}
export function PromptDetailsModalHotkeys(props: IPromptDetailsModalHotkeys) {
  useHotkeys(
    isMacOs ? 'meta+return' : 'ctrl+return',
    (e) => {
      e.stopPropagation();
      props.onSubmit();
    },
    [props.onSubmit]
  );

  return <></>;
}
