import { useEffect, useRef, useState } from 'react';

import userDefaultAvatar from '../../../../assets/images/user-image.png';
import { LogOutIcon } from '../../../../assets/svg/LogOutIcon';
import { setModal } from '../../../../components/Modal/ModalSlice';
import { ModalTypes } from '../../../../components/Modal/types/enums/ModalTypes';
import { useKeyDownListener } from '../../../../hooks/useKeyDownListener';
import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { useGetSettingsQuery } from '../../../../services/settings';
import styles from './UserInfoAndLogout.module.scss';

export const UserInfoAndLogout = () => {
  const { data: userData } = useGetSettingsQuery(undefined, { refetchOnMountOrArgChange: true });
  const [logOutBtnOpened, setLogOutBtnOpened] = useState(false);
  const logOutBtnRef = useRef<HTMLButtonElement>(null);
  const userInfoBtnRef = useRef<HTMLButtonElement>(null);
  const dispatch = useTypedDispatch();
  const username = userData?.username;

  const toggleMenu = () => {
    setLogOutBtnOpened(!logOutBtnOpened);
  };

  const handleMenuClose = () => {
    setLogOutBtnOpened(false);
  };

  const handleLogout = () => {
    dispatch(
      setModal({
        modalType: ModalTypes.LOG_OUT_MODAL,
      })
    );
    handleMenuClose();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        logOutBtnRef.current &&
        !logOutBtnRef.current.contains(event.target as Node) &&
        !userInfoBtnRef?.current.contains(event.target as Node)
      ) {
        handleMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useKeyDownListener(handleMenuClose);

  return (
    <div className={styles.userInfo}>
      <button onClick={toggleMenu} ref={userInfoBtnRef} className={styles.userInfoBtn}>
        <div className={styles.userPhotoContainer}>
          {userDefaultAvatar && (
            <img src={userDefaultAvatar} alt="user avatar" width="36" height="36" />
          )}
        </div>
        <div className={styles.userNameWrapper}>
          {username && <span className={styles.userName}>{username}</span>}
        </div>
      </button>
      {logOutBtnOpened && (
        <button className={styles.logOutBtn} ref={logOutBtnRef} onClick={handleLogout}>
          <LogOutIcon />
          Sign Out
        </button>
      )}
    </div>
  );
};
