import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './DocumentContent.module.scss';

export const DocumentContentSkeleton: FC = () => {
  return (
    <div className={styles.skeletonWrapper}>
      <Skeleton className={styles.nameSkeleton} />
      <div className={styles.contentSkeletonWrapper}>
        <Skeleton className={styles.contentSkeleton} />
        <Skeleton className={styles.contentSkeleton} />
        <Skeleton className={styles.contentSkeletonShort} />
      </div>
      <div className={styles.contentSkeletonWrapper}>
        <Skeleton className={styles.contentSkeleton} />
        <Skeleton className={styles.contentSkeleton} />
        <Skeleton className={styles.contentSkeletonShort} />
      </div>
    </div>
  );
};
