import './index.scss';

import { useDrag } from 'react-dnd';
import Highlighter from 'react-highlight-words';

import { IClip } from '../../../../../service/interfaces';
import DeleteIcon from '../../../../design/assets/svg/icons/DeleteIcon';
import EditIcon from '../../../../design/assets/svg/icons/EditIcon';
import FolderIcon from '../../../../design/assets/svg/icons/FolderIcon';
import { Avatar } from '../../../../design/components/avatar';

export interface ClipProps {
  clip: IClip;
  searchQuery: string;
  onAddToFolder: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onClick: () => void;
}
export function Clip({ clip, searchQuery, onAddToFolder, onEdit, onDelete, onClick }: ClipProps) {
  const [, drag] = useDrag(() => ({
    type: 'CLIPS_ITEM',
    item: clip,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} className="clips-item" onClick={onClick}>
      <Avatar
        url={clip.preview !== 'none' ? clip.preview : undefined}
        userName={clip.title.slice(0, 1)}
        size="big"
      />
      <div className="item-info">
        <span className="item-title">
          <Highlighter
            highlightClassName="alchemy-highlight"
            searchWords={[searchQuery ?? '']}
            autoEscape={true}
            textToHighlight={clip.title}
          />
        </span>
        <span className="item-url">
          <Highlighter
            highlightClassName="alchemy-highlight"
            searchWords={[searchQuery ?? '']}
            autoEscape={true}
            textToHighlight={clip.url}
          />
        </span>
      </div>
      <div className="quick-actions">
        <button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            onAddToFolder();
          }}
          className="item-add-to-folder"
        >
          <FolderIcon color="var(--color-titanium-600)" />
        </button>
        <button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            onEdit();
          }}
          className="item-edit"
        >
          <EditIcon color="var(--color-titanium-600)" />
        </button>
        <button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            onDelete();
          }}
          className="item-delete"
        >
          <DeleteIcon color="var(--color-titanium-600)" />
        </button>
      </div>
    </div>
  );
}
