export default function PlusIcon() {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.4987 3.33398V12.6673M3.83203 8.00065H13.1654'
        stroke='currentColor'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
