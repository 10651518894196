interface ISearchIcon {
  className?: string;
}
const SearchIcon = (props: ISearchIcon) => {
  return (
    <svg
      className={props.className ?? ''}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ fill: 'transparent' }}
    >
      <path
        d='M16.0404 16.0423L12.9154 12.9173M3.95703 9.16732C3.95703 6.29083 6.28888 3.95898 9.16536 3.95898C12.0418 3.95898 14.3737 6.29083 14.3737 9.16732C14.3737 12.0438 12.0418 14.3757 9.16536 14.3757C6.28888 14.3757 3.95703 12.0438 3.95703 9.16732Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SearchIcon;
