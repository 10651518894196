export interface IDropdownIcon {
  color?: string;
  className?: string;
}
export function DropdownIcon(props: IDropdownIcon) {
  return (
    <svg
      className={props.className}
      stroke={props.color ?? 'var(--app-light)'}
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="1"></circle>
      <circle cx="19" cy="12" r="1"></circle>
      <circle cx="5" cy="12" r="1"></circle>
    </svg>
  );
}
