import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';

import { setRedirectTo404, setRedirectToLogin } from '../redux/Auth';
import { setIsRedirectToUpgradeSubscriptionPage } from '../redux/Subscription';
import { ERROR_STATUS_ENUM } from './constants';

enum Headers {
  Authorization = 'authorization',
  Accept = 'Accept',
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  paramsSerializer: params => {
    const queryParams = new URLSearchParams();

    for (const param in params) {
      if (Array.isArray(params[param])) {
        params[param].forEach(value => {
          if (value) queryParams.append(param, value.toString());
        });
      } else {
        if (params[param]) queryParams.set(param, params[param].toString());
      }
    }

    return queryParams.toString();
  },

  prepareHeaders: headers => {
    const token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');

    if (token) {
      headers.set(Headers.Authorization, `token ${token}`);
    }
    headers.set(Headers.Accept, 'application/json');

    return headers;
  },
});

type ParsingError = {
  status: 'PARSING_ERROR';
  originalStatus: number;
  data: string;
  error: string;
};

const clearUserData = api => {
  localStorage.clear();
  sessionStorage.clear();
  api.dispatch(setRedirectToLogin(true));
};

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    const error = result.error;

    console.error('🛑', error);

    const isSubscriptionError =
      error.status === ERROR_STATUS_ENUM.SUBSCRIPTION ||
      (error as ParsingError)?.originalStatus === ERROR_STATUS_ENUM.SUBSCRIPTION;

    const isAuthError =
      error.status === ERROR_STATUS_ENUM.NOT_AUTHORIZED ||
      (error as ParsingError)?.originalStatus === ERROR_STATUS_ENUM.NOT_AUTHORIZED;

    const isNotFoundError =
      error.status === ERROR_STATUS_ENUM.NOT_FOUND ||
      (error as ParsingError)?.originalStatus === ERROR_STATUS_ENUM.NOT_AUTHORIZED;

    if (isSubscriptionError) {
      api.dispatch(setIsRedirectToUpgradeSubscriptionPage(true));
    } else if (isAuthError) {
      clearUserData(api);
    } else if (isNotFoundError) {
      api.dispatch(setRedirectTo404(true));
    }
  }

  return result;
};

export { baseQueryWithReauth as baseQuery };
