import { useCallback, useMemo, useState } from 'react';

import { PlusIcon } from '../../assets/svg/PlusIcon';
import { MainContentLayout } from '../../components/Layouts/MainContentLayout';
import { ICreatedSnippet } from '../../models/ISnippet';
import { useGetSnippetsQuery } from '../../services/snippets';
import { SnippetCard } from './SnippetCard';
import { SnippetSkeleton } from './SnippetCard/SnippetSkeleton';
import { SnippetContent } from './SnippetContent';
import styles from './SnippetsPage.module.scss';
import { SNIPPET_MODE } from './types';

export const SnippetsPage = () => {
  const { data: snippetsData, isLoading } = useGetSnippetsQuery();
  const [query, setQuery] = useState('');
  const [mode, setMode] = useState<SNIPPET_MODE | null>(null);
  const [selectedSnippetId, setSelectedSnippetId] = useState<number | null>(null);
  const snippetsList = useMemo(() => {
    const results = searchSnippets(snippetsData?.results, query);

    return results;
  }, [snippetsData?.results, query]);
  const selectedSnippet = snippetsList?.find(snippet => snippet.id === selectedSnippetId);

  const onResetModeAndSelectedSnippetId = () => {
    setMode(null);
    setSelectedSnippetId(null);
  };

  const onUpdateSnippet = useCallback((id: number) => {
    setMode(SNIPPET_MODE.UPDATE);
    setSelectedSnippetId(id);
  }, []);

  const onChange = (query: string) => {
    setQuery(query);
  };

  return (
    <MainContentLayout
      title="Snippets"
      searchBarName="snippet"
      searchBarPlaceholder="Search snippets"
      isSearchDisabled={isLoading}
      onChange={onChange}
    >
      {mode ? (
        <SnippetContent
          selectedSnippet={selectedSnippet}
          mode={mode}
          onResetModeAndSelectedSnippetId={onResetModeAndSelectedSnippetId}
        />
      ) : (
        <div className={styles.snippetsList}>
          <button
            className={styles.snippetsAddBtn}
            onClick={() => setMode(SNIPPET_MODE.CREATE)}
            disabled={isLoading}
          >
            <div>
              <PlusIcon />
            </div>
            Add new snippet
          </button>
          {isLoading ? (
            <SnippetSkeleton />
          ) : (
            snippetsList?.map(snippet => (
              <SnippetCard key={snippet.id} {...snippet} onUpdateSnippet={onUpdateSnippet} />
            ))
          )}
        </div>
      )}
    </MainContentLayout>
  );
};

const searchSnippets = (items: ICreatedSnippet[], query: string) => {
  if (!query?.trim() || !items?.length) {
    return items;
  }

  const lowerCaseQuery = query?.toLowerCase()?.trim();

  return items.filter(item => item.code.toLowerCase().includes(lowerCaseQuery));
};
