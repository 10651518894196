import './SharedPromptPage.external.scss';

import copy from 'copy-to-clipboard';
import errToJSON from 'error-to-json';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

import { Badge } from '../../../components/Badge';
import { useExtensionServiceContext } from '../../../external/extension/service/context';
import { StringTools } from '../../../external/extension/tools/string';
import AlchemyLogo from '../../../external/extension/ui/design/assets/svg/AlchemyLogo';
import { Avatar } from '../../../external/extension/ui/design/components/avatar';
import { Button } from '../../../external/extension/ui/design/components/button';
import { LoadingOverlay } from '../../../external/extension/ui/design/components/loading';
import { SmoothVisibility } from '../../../external/extension/ui/design/components/smoothVisibility';
import { NOTIFICATION_TYPES, useNotification } from '../../../hooks/useNotification';
import { errorMessages } from '../../../services/errors';
import {
  useCustomPromptClickAmountUpdateMutation,
  useCustomPromptLookupsAmountUpdateMutation,
  useGetAddSharedCustomPromptQuery,
  useGetSharedCustomPromptQuery,
} from '../../../services/promptLibrary';
import { PAGES_ENUM } from '../../../utils/constants';
import { getFormattedNumber } from '../../../utils/getFormattedNumber';
import { handleError, handleSuccess } from '../../../utils/notifications';
import { PromptContentField } from '../components/PromptContentField/PromptContentField';

interface IAddToLibraryError {
  status: string;
  message: string;
}

export const SharedPromptPage = () => {
  const [skip, setSkip] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isFetching: isFetchingSharedCustomPromptQuery } = useGetSharedCustomPromptQuery(id);
  const authTokenFromStorage =
    localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');
  const { isSuccess, error } = useGetAddSharedCustomPromptQuery(id, {
    skip: skip,
  });

  const extensionService = useExtensionServiceContext();

  const user = extensionService.useExtensionUser();

  const dateToFormat = moment(data?.created_at);
  const isValidDate = dateToFormat.isValid();
  const formattedDate = isValidDate ? dateToFormat.format('MMM Do, YYYY') : '';

  const formattedUsageNumber = getFormattedNumber(data?.click_amount);
  const formattedLookupsNumber = getFormattedNumber(data?.amount_of_lookups);

  const [clickAmountUpdate] = useCustomPromptClickAmountUpdateMutation();
  const [lookupsAmountUpdate] = useCustomPromptLookupsAmountUpdateMutation();

  const windowSize = useWindowSize();

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: isSuccess,
    textSuccess: 'Successfully added to your library',
  });

  const onAddToLibrary = async () => {
    const body = { prompt_extension_pk: Number(data?.id) };

    await clickAmountUpdate(body).unwrap();
    await lookupsAmountUpdate(body).unwrap();

    if (!authTokenFromStorage) {
      navigate(PAGES_ENUM.SIGN_IN);

      return;
    }

    if (isSuccess) {
      handleSuccess('Already added to your library');
    }

    if (error && 'data' in error) {
      const errorData = error.data as IAddToLibraryError;

      if (errorData?.message === 'ADD_OWN_PROMPT_FORBIDDEN') {
        handleError(errorMessages.ADD_OWN_PROMPT_FORBIDDEN);
      }
    }

    setSkip(false);
  };

  const onUsePrompt = async () => {
    let formattedText = data?.prompt_markdown_template;

    // Strip HTML tags, process mentions and preserve newlines
    const stripHTMLAndMentions = html => {
      const doc = new DOMParser().parseFromString(html, 'text/html');

      // Replace mentions
      const mentions = doc.querySelectorAll('.mention');

      mentions.forEach(mention => {
        const mentionId = mention.getAttribute('data-id');

        mention.replaceWith(`#${mentionId}`);
      });

      // Replace block tags with newlines
      const blockTags = ['p', 'br', 'div'];

      blockTags.forEach(tag => {
        const elements = doc.querySelectorAll(tag);

        elements.forEach(el => {
          el.replaceWith('\n' + el.textContent);
        });
      });

      return doc.body.textContent || '';
    };

    // Convert formattedText from HTML to plain text, handling mentions and newlines
    formattedText = stripHTMLAndMentions(formattedText).trim();

    try {
      const body = { prompt_extension_pk: Number(data?.id) };

      try {
        await clickAmountUpdate(body).unwrap();
        await lookupsAmountUpdate(body).unwrap();
      } catch (e) {
        // skip
      }

      try {
        await window.navigator.clipboard.writeText(formattedText);
      } catch (e) {
        copy(formattedText, {
          message: 'iOS blocked automatic text copying, so it needed to be copied manually.',
        });
      }
      handleSuccess('Successfully copied to clipboard');
    } catch (error) {
      alert(JSON.stringify(errToJSON(error)));
      handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
      console.error('Use prompt failed', error);
    }
  };

  useEffect(() => {
    if (error && 'data' in error) {
      const errorData = error.data as IAddToLibraryError;

      if (errorData?.message === 'ADD_OWN_PROMPT_FORBIDDEN') {
        handleError(errorMessages.ADD_OWN_PROMPT_FORBIDDEN);
      }
    }
  }, [error]);

  return (
    <div className="shared-prompt-page">
      <header>
        <Link to="/">
          <AlchemyLogo />
        </Link>
        <div className="right-side">
          {user.profile ? (
            <div className="user-info">
              <Avatar url={user.profile.avatar} userName={user.profile.username} size="micro-20" />
              <span className="user-name">
                {user.profile.first_name
                  ? user.profile.first_name +
                    (user.profile.last_name
                      ? ' ' + StringTools.capitalizeFirstLetter(user.profile.last_name)
                      : '')
                  : user.profile.username ?? 'User'}
              </span>
            </div>
          ) : (
            <div className="action-buttons">
              <Button onClick={() => navigate(PAGES_ENUM.SIGN_IN)}>Log in</Button>
              <Button onClick={() => navigate(PAGES_ENUM.SIGN_UP)} active={true}>
                Sign up
              </Button>
            </div>
          )}
        </div>
      </header>

      <div className="sections-wrapper">
        <section className="generic-info">
          <h1>You’ve been sent a prompt.</h1>
          <div className="group">
            <h3>Here’s how to make the most of this prompt:</h3>

            <ol>
              <li>
                <b>Get Instant Insights: </b>Paste your prompt into ChatGPT or Claude. You can also
                access both directly through Alchemy for seamless interaction.
              </li>
              <li>
                <b>Personalize Your Experience (Optional): </b>Fill in the guided inputs to ensure
                your results are tailored just for you.
              </li>
              <li>
                <b>Watch the Magic Happen: </b>Sit back and let the AI do its magic.
              </li>
            </ol>
          </div>
          <div className="group">
            <div className="action-buttons">
              {windowSize.width > 1220 && (
                <Button onClick={() => onAddToLibrary()} active={true}>
                  <span>Save to Library</span>
                </Button>
              )}

              <Button onClick={() => onUsePrompt()} active={windowSize.width <= 1220}>
                <span>{windowSize.width <= 1220 ? 'Copy Prompt' : 'Copy to Clipboard'}</span>
              </Button>
            </div>
            <p className="italic">
              <i>
                Ready to unlock your potential with AI? Dive in and see where this prompt takes you!
              </i>
            </p>
          </div>
        </section>
        <section className="prompt-info">
          <div className="prompt-card">
            <div className="prompt-main-info">
              <div className="prompt-meta">
                <div className="badge-wrapper">
                  {data?.categories?.map(category => (
                    <Badge
                      key={category?.id}
                      icon={category?.icon}
                      title={category?.parent?.name ? category.parent.name : category?.name}
                      color={category?.color ?? '#0BA5EC'}
                      background={category?.background_color ?? 'rgba(11, 165, 236, 0.16)'}
                      subcategory={category?.parent && category?.name}
                    />
                  ))}
                </div>
                <div className="date">{formattedDate}</div>
              </div>

              <h2>{data?.name}</h2>
              <div className="prompt-author">
                <Avatar url={data?.user.avatar} userName={data?.user.username} size="tiny" />
                <span className="user-name">
                  {data?.user.first_name
                    ? data?.user.first_name +
                      (data?.user.last_name
                        ? ' ' + StringTools.capitalizeFirstLetter(data?.user.last_name)
                        : '')
                    : data?.user.username ?? 'User'}
                </span>
              </div>

              <div className="prompt-description">{data?.description}</div>
            </div>

            <div className="prompt-content-wrapper">
              <div className="prompt-content">
                <PromptContentField promptContent={data?.prompt_markdown_template} />
              </div>
            </div>

            <SmoothVisibility
              visible={!!data?.amount_of_lookups || !!data?.click_amount}
              className="prompt-statistics"
            >
              {formattedLookupsNumber && (
                <div className="statistic lookups-info">
                  <div className="amount">{formattedLookupsNumber}</div>
                  <div className="amount-label">Total Views</div>
                </div>
              )}

              {formattedUsageNumber && (
                <div className="statistic usage-info">
                  <div className="amount">{formattedUsageNumber}</div>
                  <div className="amount-label">Total Usage</div>
                </div>
              )}
            </SmoothVisibility>
          </div>
        </section>
      </div>

      <LoadingOverlay active={isFetchingSharedCustomPromptQuery} />
    </div>
  );
};
