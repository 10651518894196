export interface IEditIcon {
  color?: string;
}
export default function EditIcon(props: IEditIcon) {
  return (
    <svg
      stroke={props.color ?? 'var(--app-light)'}
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="1em"
      width="1em"
      className="edit-icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 20h9"></path>
      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
    </svg>
  );
}
