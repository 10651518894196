import { useEffect, useState } from 'react';

import { IMessage } from '../../../../../../../../service/base/ai/interfaces';
import { DialogRendering } from '..';
import { IDialogRenderVersionDecisionsMap } from '../interfaces';

export function renderDialog(
  messages: IMessage[],
  currentMessageId: number,
  versionDecisionsMap: IDialogRenderVersionDecisionsMap
) {
  try {
    const map = DialogRendering.generateMessagesMap(messages);
    const tree = DialogRendering.buildTree(map, -1, currentMessageId);
    const result = DialogRendering.renderTree(tree, versionDecisionsMap);

    return result;
  } catch (e) {
    console.error(e);

    return [];
  }
}

export function useDialogRender(
  messages: IMessage[],
  currentMessageId?: number,
  versionDecisionsMap?: IDialogRenderVersionDecisionsMap
) {
  const [renderedMessages, setRenderedMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    if (currentMessageId) {
      setRenderedMessages(renderDialog(messages, currentMessageId, versionDecisionsMap ?? {}));
    } else {
      setRenderedMessages(messages);
    }
  }, [messages, currentMessageId, versionDecisionsMap]);

  return renderedMessages;
}
