import cn from 'classnames';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSearchParams } from 'react-router-dom';

import styles from './SettingTab.module.scss';
import { SettingsTabs } from './types';

type Props = {
  title: string;
  isActive: boolean;
  isLoading: boolean;
  type: SettingsTabs;
};

export const SettingTab: FC<Props> = ({ title, isActive, isLoading, type }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabClick = () => {
    const params = new URLSearchParams(searchParams);

    params.set('tab', type);
    setSearchParams(params);
  };

  return (
    <button
      className={cn(styles.settingTab, { [styles.tab]: isActive })}
      onClick={handleTabClick}
      disabled={isLoading}
    >
      {isLoading ? <Skeleton className={styles.settingTabLoading} /> : title}
    </button>
  );
};
