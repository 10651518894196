export default function DocumentIcon() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="document-icon"
    >
      <g clipPath="url(#clip0_3478_58016)">
        <path
          d="M0.632812 4.28906C0.632812 2.35606 2.19982 0.789062 4.13281 0.789062H8.39359C9.32185 0.789062 10.2121 1.15781 10.8685 1.81419L14.3445 5.29025C15.0009 5.94663 15.3697 6.83687 15.3697 7.76513V15.7101C15.3697 17.6431 13.8027 19.2101 11.8697 19.2101H4.13281C2.19982 19.2101 0.632812 17.6431 0.632812 15.7101V4.28906Z"
          fill="var(--app-document-icon)"
        />
        <path
          d="M0.895313 4.28906C0.895313 2.50104 2.34479 1.05156 4.13281 1.05156H8.39359C9.25223 1.05156 10.0757 1.39266 10.6828 1.9998L14.1589 5.47587C14.7661 6.08302 15.1072 6.90649 15.1072 7.76513V15.7101C15.1072 17.4981 13.6577 18.9476 11.8697 18.9476H4.13281C2.34479 18.9476 0.895313 17.4981 0.895313 15.7101V4.28906Z"
          stroke="var(--app-light)"
          strokeOpacity="0.17"
          strokeWidth="0.525"
        />
        <g clipPath="url(#clip1_3478_58016)">
          <g filter="url(#filter0_dii_3478_58016)">
            <path
              d="M9.47832 9.72575C9.87177 9.3323 10.5097 9.3323 10.9031 9.72575C11.2966 10.1192 11.2966 10.7571 10.9031 11.1505L9.45704 12.5966C9.21028 12.8434 8.87561 12.982 8.52664 12.982H7.91002C7.76468 12.982 7.64686 12.8642 7.64686 12.7188V12.1022C7.64686 11.7533 7.78549 11.4186 8.03225 11.1718L9.47832 9.72575Z"
              fill="var(--app-light)"
              fillOpacity="0.12"
              shapeRendering="crispEdges"
            />
            <path
              d="M9.47832 9.72575C9.87177 9.3323 10.5097 9.3323 10.9031 9.72575C11.2966 10.1192 11.2966 10.7571 10.9031 11.1505L9.45704 12.5966C9.21028 12.8434 8.87561 12.982 8.52664 12.982H7.91002C7.76468 12.982 7.64686 12.8642 7.64686 12.7188V12.1022C7.64686 11.7533 7.78549 11.4186 8.03225 11.1718L9.47832 9.72575Z"
              fill="url(#paint1_linear_3478_58016)"
              fillOpacity="0.24"
              shapeRendering="crispEdges"
            />
            <path
              d="M5.80476 12.4557C5.27185 12.4557 4.83984 12.8877 4.83984 13.4206C4.83984 13.9535 5.27185 14.3855 5.80476 14.3855H9.48897C9.7312 14.3855 9.92756 14.5819 9.92756 14.8241C9.92756 15.0663 9.7312 15.2627 9.48897 15.2627H7.91002C7.76468 15.2627 7.64686 15.3805 7.64686 15.5259C7.64686 15.6712 7.76468 15.789 7.91002 15.789H9.48897C10.0219 15.789 10.4539 15.357 10.4539 14.8241C10.4539 14.2912 10.0219 13.8592 9.48897 13.8592H5.80476C5.56253 13.8592 5.36616 13.6628 5.36616 13.4206C5.36616 13.1784 5.56253 12.982 5.80476 12.982H6.68195C6.82729 12.982 6.94511 12.8642 6.94511 12.7188C6.94511 12.5735 6.82729 12.4557 6.68195 12.4557H5.80476Z"
              fill="var(--app-light)"
              fillOpacity="0.12"
              shapeRendering="crispEdges"
            />
            <path
              d="M5.80476 12.4557C5.27185 12.4557 4.83984 12.8877 4.83984 13.4206C4.83984 13.9535 5.27185 14.3855 5.80476 14.3855H9.48897C9.7312 14.3855 9.92756 14.5819 9.92756 14.8241C9.92756 15.0663 9.7312 15.2627 9.48897 15.2627H7.91002C7.76468 15.2627 7.64686 15.3805 7.64686 15.5259C7.64686 15.6712 7.76468 15.789 7.91002 15.789H9.48897C10.0219 15.789 10.4539 15.357 10.4539 14.8241C10.4539 14.2912 10.0219 13.8592 9.48897 13.8592H5.80476C5.56253 13.8592 5.36616 13.6628 5.36616 13.4206C5.36616 13.1784 5.56253 12.982 5.80476 12.982H6.68195C6.82729 12.982 6.94511 12.8642 6.94511 12.7188C6.94511 12.5735 6.82729 12.4557 6.68195 12.4557H5.80476Z"
              fill="url(#paint2_linear_3478_58016)"
              fillOpacity="0.24"
              shapeRendering="crispEdges"
            />
          </g>
        </g>
        <path
          d="M9.84375 1.12012L15.1542 6.31543H15.0391C12.1698 6.31543 9.84375 3.98941 9.84375 1.12012Z"
          fill="var(--app-document-icon-earmark)"
        />
      </g>
      <defs>
        <filter
          id="filter0_dii_3478_58016"
          x="4.33984"
          y="9.18066"
          width="7.35938"
          height="7.3584"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.25" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3478_58016" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3478_58016"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.64 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3478_58016" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.1" />
          <feGaussianBlur stdDeviation="0.375" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.84 0" />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_3478_58016"
            result="effect3_innerShadow_3478_58016"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3478_58016"
          x1="12.6065"
          y1="3.09169"
          x2="2.93545"
          y2="19.2101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#303235" />
          <stop offset="1" stopColor="#4D4F52" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3478_58016"
          x1="8.04639"
          y1="15.789"
          x2="8.04639"
          y2="9.38463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--app-light)" />
          <stop offset="1" stopColor="var(--app-light)" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3478_58016"
          x1="8.04639"
          y1="15.789"
          x2="8.04639"
          y2="9.38463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--app-light)" />
          <stop offset="1" stopColor="var(--app-light)" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_3478_58016">
          <rect
            width="14.7368"
            height="18.4211"
            fill="var(--app-light)"
            transform="translate(0.632812 0.789062)"
          />
        </clipPath>
        <clipPath id="clip1_3478_58016">
          <rect
            width="8.42105"
            height="8.42105"
            fill="var(--app-light)"
            transform="translate(3.78906 8.4209)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
