import cn from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { SearchIcon } from '../../assets/svg/SearchIcon';
import styles from './GlobalSearchBar.module.scss';

interface ISearch {
  name: string;
  placeholder: string;
  onChange: (value: string) => void;
  className?: string;
  inputClassName?: string;
  isLoading: boolean;
  searchValue?: string;
  isDisabled: boolean;
}

export const GlobalSearchBar: FC<ISearch> = ({
  name,
  placeholder,
  onChange,
  className,
  inputClassName,
  isLoading,
  isDisabled,
  searchValue = '',
}) => {
  const [value, setValue] = useState(searchValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  useEffect(() => {
    if (inputRef.current && value) {
      inputRef.current.focus();
    }
  }, [inputRef.current, isDisabled, value]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;

    setValue(search);
  };

  return (
    <div className={cn(styles.searchBar, className)}>
      {isLoading && <SearchLoading />}

      {!isLoading && (
        <>
          <input
            ref={inputRef}
            className={cn(styles.searchBarInput, inputClassName, {
              [styles.searchBarInputPreview]: isLoading,
              [styles.searchBarFetchingState]: value && isDisabled,
            })}
            name={name}
            placeholder={placeholder}
            onChange={onSearch}
            disabled={isDisabled}
            value={value}
          />
          <SearchIcon
            className={cn(styles.searchBarIcon, { [styles.searchBarIconPreview]: isLoading })}
          />
        </>
      )}
    </div>
  );
};

const SearchLoading = () => {
  return (
    <div className={cn(styles.searchBarInput, styles.searchLoading)}>
      <Skeleton className={styles.icon} />
      <Skeleton className={styles.text} />
    </div>
  );
};
