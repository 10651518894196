import './index.scss';

import { ReactNode, useEffect, useState } from 'react';

import { SmoothVisibility } from '../smoothVisibility';

export interface ITooltip {
  text?: string | ReactNode;
  position?: 'left' | 'right' | 'top' | 'bottom';
  align?: 'start' | 'center' | 'end';
  children?: ReactNode;
  as?: string;
  className?: string;
  style?: React.CSSProperties;
  tooltipStyle?: React.CSSProperties;
  onClick?: any;
  disabled?: boolean;
  child?: ReactNode;
  disableTooltip?: boolean;
}
export function Tooltip({
  text,
  position = 'top',
  align = 'center',
  ...props
}: ITooltip) {
  const [preVisible, setPreVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const isVertical = ['top', 'bottom'].includes(position);

  useEffect(() => {
    if (props.disableTooltip) {
      setVisible(false);

      return;
    }

    if (preVisible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [preVisible, props.disableTooltip]);

  const Container = `${props.as ?? 'div'}` as keyof JSX.IntrinsicElements;

  return (
    <Container
      className={
        'alchemy-tooltip-wrapper' +
        (props.className ? ' ' + props.className : '')
      }
      onMouseEnter={() => setPreVisible(!!text)}
      onMouseLeave={() => setPreVisible(false)}
      style={props.style}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
      <SmoothVisibility
        as='div'
        visible={visible}
        className='alchemy-tooltip-content'
        style={props.tooltipStyle}
      >
        <span
          className={
            'alchemy-tooltip' +
            (isVertical ? ' vertical' : ' horizontal') +
            ' position-' +
            position +
            ' align-' +
            align
          }
        >
          {text}
        </span>
      </SmoothVisibility>
    </Container>
  );
}
