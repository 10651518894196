import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const CheckboxIconChecked = (props: IIcon) => {
  return (
    <Icon width="16" height="16" {...props}>
      <circle cx="8" cy="8" r="7.5" fill="#22223F" stroke="#8067FF" />
      <path
        d="M6.66458 9.06589L10.5979 5.13255C10.7201 5.01033 10.8757 4.94922 11.0646 4.94922C11.2535 4.94922 11.409 5.01033 11.5312 5.13255C11.6535 5.25477 11.7146 5.41033 11.7146 5.59922C11.7146 5.78811 11.6535 5.94366 11.5312 6.06589L7.13125 10.4659C6.99792 10.5992 6.84236 10.6659 6.66458 10.6659C6.48681 10.6659 6.33125 10.5992 6.19792 10.4659L4.46458 8.73255C4.34236 8.61033 4.28125 8.45477 4.28125 8.26589C4.28125 8.077 4.34236 7.92144 4.46458 7.79922C4.58681 7.677 4.74236 7.61589 4.93125 7.61589C5.12014 7.61589 5.27569 7.677 5.39792 7.79922L6.66458 9.06589Z"
        fill="#6346EC"
      />
    </Icon>
  );
};
