export interface IArrowDownIcon {
  color?: string;
  className?: string;
}
export default function ArrowDownIcon(props: IArrowDownIcon) {
  return (
    <svg
      stroke={props.color ?? 'currentColor'}
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      className={'arrow-down' + (props.className ? ' ' + props.className : '')}
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
}
