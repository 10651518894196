import cn from 'classnames';
import React, { FC } from 'react';

import { MessageIcon } from '../../../../assets/svg/MessageIcon';
import { SlashIcon } from '../../../../assets/svg/SlashIcon';
import { StarIcon } from '../../../../assets/svg/StarIcon';
import { WebIcon } from '../../../../assets/svg/WebIcon';
import { Badge } from '../../../../components/Badge';
import { IPrompt } from '../../../../models/IPromptLibrary';
import { ICategory } from '../../../../models/IShared';
import { UsageAndViewBadges } from '../UsageAndViewBadges';
import styles from './Prompt.module.scss';

type Props = {
  prompt: IPrompt;
  onFavorite: () => void;
  onAddToCommand: (prompt: IPrompt, promptType: number) => void;
  onPromptSelect: (id: number) => void;
};

export const Prompt: FC<Props> = React.memo(
  ({ prompt, onFavorite, onAddToCommand, onPromptSelect }) => {
    const {
      id,
      name,
      preview_description,
      amount_of_lookups,
      click_amount,
      categories,
      is_favourite,
      is_in_command,
      need_bing,
      plugins,
    } = prompt;
    const hasCategories = categories?.length !== 0;

    const handlePromptSelection = () => {
      onPromptSelect(id);
    };

    return (
      <div className={styles.prompt} onClick={handlePromptSelection}>
        <div
          className={styles.promptHeading}
          style={{ justifyContent: hasCategories ? 'space-between' : 'flex-end' }}
        >
          <div className={styles.categoriesWrapper}>
            {(categories as ICategory[])?.map(category => {
              return (
                <Badge
                  key={category?.id}
                  icon={category?.icon}
                  title={category?.parent?.name ? category.parent.name : category?.name}
                  color={category?.color ?? '#0BA5EC'}
                  background={category?.background_color ?? 'rgba(11, 165, 236, 0.16)'}
                  subcategory={category?.parent && category?.name}
                />
              );
            })}
          </div>
          <div className={styles.promptSaveAndFavoriteButtons}>
            <button
              onClick={e => {
                e.stopPropagation();
                onFavorite();
              }}
              className={cn(styles.promptSaveBtn, {
                [styles.promptSaveBtnActive]: is_favourite,
              })}
            >
              <StarIcon />
            </button>
            <button
              className={cn(styles.promptCommandBtn, {
                [styles.promptCommandBtnActive]: is_in_command,
              })}
              onClick={e => {
                e.stopPropagation();
                onAddToCommand(prompt, 2);
              }}
            >
              <SlashIcon />
            </button>
          </div>
        </div>
        <div className={styles.promptContent}>
          <div className={styles.promptTitleAndDescriptionContainer}>
            <h3 className={styles.promptTitle}>{name}</h3>
            <p className={styles.promptDescription}>{preview_description}</p>
          </div>
          <div className={styles.promptInfo}>
            <div className={styles.promptBingAndGPT}>
              {need_bing && (
                <div className={styles.promptBing}>
                  <WebIcon />
                  Browse with Bing
                </div>
              )}
              {plugins?.map((plugin, index) => (
                <a
                  key={plugin.id || index}
                  href={plugin.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.promptGPT}
                  onClick={e => e.stopPropagation()}
                >
                  <MessageIcon />
                  <span>{plugin.name}</span>
                </a>
              ))}
            </div>
            <UsageAndViewBadges views={amount_of_lookups} usage={click_amount} />
          </div>
        </div>
      </div>
    );
  }
);
Prompt.displayName = 'Prompt';
