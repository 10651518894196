import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const DownloadExtensionGradient = (props: IIcon) => {
  return (
    <Icon width="1119" height="84" {...props}>
      <g filter="url(#filter0_f_6_7005)">
        <path
          d="M115.503 -29.5901C34.3746 -5.51886 135.855 -163.753 433.615 -201.334C527.792 -227.249 740.235 -256.438 916.451 -290.02C1136.72 -331.998 991.486 -187.374 915.138 -123.117C838.791 -58.8595 722.69 -93.2129 651.992 -82.2233C361.142 -37.0123 226.687 -62.5785 115.503 -29.5901Z"
          fill="#A98CF8"
        />
      </g>
      <g opacity="0.3" filter="url(#filter1_f_6_7005)">
        <path
          d="M11.6033 103.656C-81.7056 131.409 317.422 -22.564 380.696 -83.5001C488.928 -113.664 731.855 -152.069 933.749 -193.413C1186.12 -245.094 1014.85 -92.6001 925.303 -24.3538C835.761 43.8926 641.926 70.7429 624.199 28.0147C606.472 -14.7134 128.239 68.9638 11.6033 103.656Z"
          fill="#A98CF8"
        />
        <path
          d="M660.755 36.2719C630.863 15.2304 682.649 14.6349 669.992 -2.50557C662.334 -12.8765 660.961 -28.268 672.723 -33.5464C732.052 -60.1718 822.733 -98.5536 862.735 -107.029C921.941 -119.574 959.772 -115.861 966.509 -71.4234C973.246 -26.9857 900.741 -36.2776 890.784 -14.2449C880.827 7.78783 698.564 62.887 660.755 36.2719Z"
          fill="#8CA4F8"
        />
      </g>
      <g opacity="0.3" filter="url(#filter2_f_6_7005)">
        <path
          d="M11.6033 103.656C-81.7056 131.409 317.422 -22.564 380.696 -83.5001C488.928 -113.664 731.855 -152.069 933.749 -193.413C1186.12 -245.094 1014.85 -92.6001 925.303 -24.3538C835.761 43.8926 641.926 70.7429 624.199 28.0147C606.472 -14.7134 128.239 68.9638 11.6033 103.656Z"
          fill="#A98CF8"
        />
        <path
          d="M660.755 36.2719C630.863 15.2304 682.649 14.6349 669.992 -2.50557C662.334 -12.8765 660.961 -28.268 672.723 -33.5464C732.052 -60.1718 822.733 -98.5536 862.735 -107.029C921.941 -119.574 959.772 -115.861 966.509 -71.4234C973.246 -26.9857 900.741 -36.2776 890.784 -14.2449C880.827 7.78783 698.564 62.887 660.755 36.2719Z"
          fill="#8CA4F8"
        />
      </g>
      <g style={{ mixBlendMode: 'overlay' } as any} filter="url(#filter3_f_6_7005)">
        <path
          d="M1.23821 -55.918C-30.3139 -95.1856 7.75885 -120.814 47.8371 -139.554C178.357 -183.368 190.25 -188.532 318.649 -170.698C447.048 -152.864 279.192 -132.101 245.333 -87.7015C211.474 -43.3016 40.6783 -6.83347 1.23821 -55.918Z"
          fill="#949EFF"
        />
        <path
          d="M348.29 -137.062C400.722 -169.182 471.647 -184.125 506.704 -170.438C541.762 -156.752 540.309 -124.591 487.876 -92.4712C435.444 -60.3516 370.621 42.6755 335.564 28.9889C300.506 15.3023 295.858 -104.943 348.29 -137.062Z"
          fill="#F1EFFF"
        />
        <path
          d="M767.099 -151.208C819.532 -183.327 891.599 -197.824 928.067 -183.587C964.534 -169.35 964.335 -136.699 911.903 -104.58C859.47 -72.4602 797.187 31.5582 760.719 17.3211C724.252 3.08409 714.667 -119.088 767.099 -151.208Z"
          fill="#F1EFFF"
        />
      </g>
      <g style={{ mixBlendMode: 'overlay' } as any} filter="url(#filter4_f_6_7005)">
        <ellipse
          cx="98.1905"
          cy="222.427"
          rx="98.1905"
          ry="222.427"
          transform="matrix(0.954718 0.297514 -0.674346 0.738415 764.049 -253.636)"
          fill="url(#paint0_radial_6_7005)"
        />
      </g>
      <g style={{ mixBlendMode: 'overlay' } as any} filter="url(#filter5_f_6_7005)">
        <ellipse
          cx="98.1905"
          cy="222.427"
          rx="98.1905"
          ry="222.427"
          transform="matrix(0.954718 0.297514 -0.674346 0.738415 951.905 -345.601)"
          fill="url(#paint1_radial_6_7005)"
        />
      </g>
      <g style={{ mixBlendMode: 'overlay' } as any} filter="url(#filter6_f_6_7005)">
        <ellipse
          cx="98.1905"
          cy="222.427"
          rx="98.1905"
          ry="222.427"
          transform="matrix(0.954718 0.297514 -0.674346 0.738415 674.47 -305.836)"
          fill="url(#paint2_radial_6_7005)"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_6_7005"
          x="57.8391"
          y="-328.317"
          width="1045.59"
          height="331.986"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="15.3898" result="effect1_foregroundBlur_6_7005" />
        </filter>
        <filter
          id="filter1_f_6_7005"
          x="-220.718"
          y="-422.161"
          width="1504.67"
          height="747.386"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="109.128" result="effect1_foregroundBlur_6_7005" />
        </filter>
        <filter
          id="filter2_f_6_7005"
          x="-220.718"
          y="-422.161"
          width="1504.67"
          height="747.386"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="109.128" result="effect1_foregroundBlur_6_7005" />
        </filter>
        <filter
          id="filter3_f_6_7005"
          x="-71.2658"
          y="-271.431"
          width="1100.41"
          height="362.489"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="30.4123" result="effect1_foregroundBlur_6_7005" />
        </filter>
        <filter
          id="filter4_f_6_7005"
          x="524.9"
          y="-233.029"
          width="365.803"
          height="345.698"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_6_7005" />
        </filter>
        <filter
          id="filter5_f_6_7005"
          x="712.755"
          y="-324.994"
          width="365.803"
          height="345.698"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_6_7005" />
        </filter>
        <filter
          id="filter6_f_6_7005"
          x="435.321"
          y="-285.229"
          width="365.803"
          height="345.698"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_6_7005" />
        </filter>
        <radialGradient
          id="paint0_radial_6_7005"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(98.1905 222.427) rotate(90) scale(222.427 98.1905)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_6_7005"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(98.1905 222.427) rotate(90) scale(222.427 98.1905)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_6_7005"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(98.1905 222.427) rotate(90) scale(222.427 98.1905)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </radialGradient>
      </defs>
    </Icon>
  );
};
