export enum ModalTypes {
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  DELETE_DOCUMENT_MODAL = 'DELETE_DOCUMENT_MODAL',
  CREATE_CATEGORY_MODAL = 'CREATE_CATEGORY_MODAL',
  CREATE_DOCUMENT_MODAL = 'CREATE_DOCUMENT_MODAL',
  LOG_OUT_MODAL = 'LOG_OUT_MODAL',
  PRO_SUBSCRIBTION_MODAL = 'PRO_SUBSCRIBTION_MODAL',
  DELETE_SNIPPET_MODAL = 'DELETE_SNIPPET_MODAL',
  ACCOUNT_DOWNGRADE_WARNING_MODAL = 'ACCOUNT_DOWNGRADE_WARNING_MODAL',
}
