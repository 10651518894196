import { Area } from 'react-easy-crop/types';

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();

    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.src = url;
  });

export const getCroppedImage = async (imgSrc: string, croppedAreaPixels: Area): Promise<File> => {
  const image = await createImage(imgSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) return null;

  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;
  ctx.drawImage(
    image as CanvasImageSource,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });

      resolve(file);
    }, 'image/jpeg');
  });
};
