import * as Yup from 'yup';

export const validationService = () => {
  const validationSchema = Yup.object({
    code: Yup.string().required('Add your keyword'),
    text: Yup.string().required('Add your snippet text'),
  });

  return { validationSchema };
};
