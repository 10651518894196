import cn from 'classnames';
import { FC } from 'react';
import ReactModal from 'react-modal';

import { CloseIcon } from '../../assets/svg/CloseIcon';
import styles from './Modal.module.scss';
import { IModal } from './types/IModal';

ReactModal.setAppElement(document.getElementById('root'));

export const Modal: FC<IModal> = ({
  isOpen,
  ariaLabel,
  children,
  onRequestClose,
  contentClassName,
  ...restProps
}) => {
  return (
    <ReactModal
      id="app-modal"
      isOpen={isOpen}
      contentLabel={ariaLabel}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={onRequestClose}
      overlayClassName={{
        base: styles.modalOverlay,
        afterOpen: styles.modalOverlayVisible,
        beforeClose: styles.modalOverlayHidden,
      }}
      className={cn(styles.modalContent, contentClassName)}
      closeTimeoutMS={200}
      {...restProps}
    >
      <button className={styles.modalCloseBtn} onClick={onRequestClose} aria-label="close modal">
        <CloseIcon />
      </button>
      {children}
    </ReactModal>
  );
};
