import cn from 'classnames';
import { FieldAttributes, useField } from 'formik';

import { HashtagIcon } from '../../../../assets/svg/HashtagIcon';
import styles from './SnippetContentInput.module.scss';

export const SnippetContentInput: React.FC<FieldAttributes<any>> = ({
  className,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isShowError = meta.touched && meta.error;

  return (
    <label className={cn(styles.snippetLabel, className)} htmlFor={props.id}>
      {label}
      <div className={styles.snippetInputWrapper}>
        <input
          className={cn(styles.snippetInput, { [styles.snippetInputError]: isShowError })}
          {...field}
          {...props}
          maxLength={100}
        />
        <HashtagIcon />
      </div>
      {isShowError ? <div className={styles.snippetError}>{meta.error}</div> : null}
    </label>
  );
};
