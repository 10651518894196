import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const CheckedSubscribtionIcon = (props: IIcon) => {
  return (
    <Icon width="33" height="32" {...props}>
      <g>
        <g>
          <rect
            x="6.83325"
            y="6.3335"
            width="19.3333"
            height="19.3333"
            rx="9.66667"
            fill="#181B1E"
          />
          <rect
            x="6.83325"
            y="6.3335"
            width="19.3333"
            height="19.3333"
            rx="9.66667"
            stroke="var(--app-accent)"
            strokeWidth="2"
          />
        </g>
        <path
          d="M20.2777 12.2222C20.2777 12.2222 16.0555 15.5555 14.9444 19.7777L12.7222 16.6666"
          stroke="var(--app-accent)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2992_51309"
          x="0.5"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2992_51309" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2992_51309"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2992_51309"
          x="3.83325"
          y="4.3335"
          width="25.3333"
          height="25.3335"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0705882 0 0 0 0 0.0705882 0 0 0 0 0.0901961 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2992_51309" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2992_51309"
            result="shape"
          />
        </filter>
      </defs>
    </Icon>
  );
};
