import './index.scss';

import { createRef, useEffect, useState } from 'react';

import { IPrompt } from '../../../../../../../interfaces';
import { useOutsideClickDetector } from '../../../../../../../tools/events';
import { ArrowLeftAltIcon } from '../../../../../../design/assets/svg/icons/ArrowLeftAltIcon';
import { Button } from '../../../../../../design/components/button';
import { SmoothVisibility } from '../../../../../../design/components/smoothVisibility';
import { PromptInputField } from '../input';

export interface IFloatingSelectedCommand {
  visible: boolean;
  prompt: IPrompt | null;
  onClose: () => void;
  onClickSendPrompt: (inputValues: { [key: string]: string }) => void;
}
export function FloatingSelectedCommand(props: IFloatingSelectedCommand) {
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  const ref = createRef<HTMLDivElement>();

  useOutsideClickDetector(ref, () => {
    props.onClose();
  });

  useEffect(() => {
    if (!props.visible) setInputValues({});
  }, [props.visible]);

  return (
    <SmoothVisibility
      containerRef={ref}
      visible={props.visible}
      className="floating-selected-command"
    >
      <div className="floating-title">Commands</div>
      <div className="prompt-card">
        <div onClick={() => props.onClose()} className="prompt-card-header">
          <ArrowLeftAltIcon />
          <span className="title">{props.prompt?.name}</span>
        </div>
        <div className="prompt-inputs-list">
          {props.prompt?.inputs.map((input, index) => (
            <PromptInputField
              key={index}
              value={inputValues?.[input.variable_name] ?? ''}
              setValue={newValue => {
                setInputValues(prev => ({
                  ...prev,
                  [input.variable_name]: newValue,
                }));
              }}
              input={input}
            />
          ))}

          <div className="prompt-footer">
            <Button
              active={true}
              text="Send"
              onClick={() => props.onClickSendPrompt(inputValues)}
            />
          </div>
        </div>
      </div>
    </SmoothVisibility>
  );
}
