import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { AlchemyModel, IMessage } from '../../../../../service/base/ai/interfaces';
import { WebPlatform } from '../../../../../service/base/platform';
import { useExtensionServiceContext } from '../../../../../service/context';
import { useAsyncProcessManagerContext } from '../../../../../tools/async/context';
import { NewbornChatLogo } from '../../../../design/assets/svg/NewbornChatLogo';
import { prompt } from '../../../../design/components/modal/prompt';
import { SmoothVisibility } from '../../../../design/components/smoothVisibility';
import { Message } from '../message';
import { DocumentsManagerModal } from './modal/documents';
import { AddToSnippetModal } from './modal/snippet';
import { useDialogRender } from './tools/rendering/hooks';
import { IDialogRenderVersionDecisionsMap } from './tools/rendering/interfaces';

export interface IDialog {
  model: AlchemyModel;
  noBranding?: boolean;
  messages: IMessage[];
  currentMessageId?: number;
  className?: string;
  noEdit?: boolean;
  isPromptSending?: boolean;
  synthModeActive?: boolean;
  onEditMessage?: (message: IMessage, newText: string) => void;
  onRegenerateMessage?: (message: IMessage, customModel?: AlchemyModel) => void;
  onRenderedMessages?: (messages: IMessage[]) => void;
  onSaveMessage?: (message: IMessage) => void;
  onMoveToChat?: () => void;
  onSubmitSuggestion?: (suggestion: string) => void;
}

export function Dialog(props: IDialog) {
  const asyncProcessManager = useAsyncProcessManagerContext();
  const extensionService = useExtensionServiceContext();

  const [versionDecisionsMap, setVersionDecisionsMap] = useState<IDialogRenderVersionDecisionsMap>(
    {}
  );

  const messages = useDialogRender(props.messages, props.currentMessageId, versionDecisionsMap);

  const renderedMessages = useMemo(() => {
    const result = messages;

    if (result.length > 0 && !result[result.length - 1].model_message) {
      result.push({
        _generating: true,
        _last: true,
        id: messages[messages.length - 1].id + 1,
        model_message: true,
        message: '',
        created_at: '',
        attachments: [],
      });
    }

    return result;
  }, [messages]);

  const [modalMessage, setModalMessage] = useState<IMessage | null>(null);
  const [isShowModalSaveNewDocument, setIsShowModalSaveNewDocument] = useState<boolean>(false);
  const [isAddToSnippetModalVisible, setIsAddToSnippetModalVisible] = useState<boolean>(false);

  const onReplaceExistingDocument = useCallback(
    (bookmark: any) => {
      const id = bookmark.id;
      const name = bookmark.name;

      const text = modalMessage?.alias ?? modalMessage?.message;

      if (text) {
        const formData = new FormData();

        formData.append('file', text);
        formData.append('name', name);

        asyncProcessManager?.doProcess({
          name: 'Create document',
          action: async () => {
            setIsShowModalSaveNewDocument(false);
            const res = await extensionService.replaceExistingDocument({
              id,
              body: formData,
            });

            setModalMessage(null);
            if (res?.status >= 200 && res?.status < 400) {
              // Handle success case
            }
          },
        });
      }
    },
    [modalMessage, asyncProcessManager, extensionService]
  );

  useEffect(() => {
    props.onRenderedMessages?.(renderedMessages);
  }, [renderedMessages]);

  return (
    <>
      <div className={`sidebar-dialog ${props.className ?? ''}`}>
        <SmoothVisibility
          className={'newborn-chat-logo' + (props.isPromptSending ? ' sending' : '')}
          visible={props.messages.length === 0 && !props.noBranding}
        >
          <NewbornChatLogo theme="dark" />
        </SmoothVisibility>
        {renderedMessages.map((message, index) => {
          const preparedMessage = {
            ...message,
            error: message.error
              ? message.error
              : !message._generating && !message.message && !message.url
              ? 'There was an error generating a response'
              : undefined,
            _parentVideoURL:
              renderedMessages?.[index - 1]?.platform &&
              renderedMessages?.[index - 1]?.platform?.type === WebPlatform.YouTube
                ? renderedMessages?.[index - 1]?.platform?.link
                : undefined,
          };

          return (
            <Message
              model={props.model}
              message={preparedMessage}
              key={index}
              noEdit={props.noEdit}
              showMoveToChatButton={message.id > 0 && message._last && props.synthModeActive}
              onChangeVersion={version => {
                setVersionDecisionsMap(prev => ({
                  ...prev,
                  [message.previous_message ?? -1]: version,
                }));
              }}
              onMoveToChat={() => props.onMoveToChat?.()}
              onRetry={(_, customModel) => {
                if (props.isPromptSending) {
                  toast.error('Please wait for the generation to complete or stop it');

                  return;
                }

                const messageToRegenerate = renderedMessages.find(
                  m => m.id === preparedMessage.previous_message && !m.model_message
                );

                if (!customModel) {
                  setVersionDecisionsMap(prev => ({
                    ...prev,
                    [message.previous_message ?? -1]: (message._variants?.count ?? 0) + 1,
                  }));
                }

                props.onRegenerateMessage?.(
                  !customModel
                    ? messageToRegenerate ?? message
                    : messageToRegenerate?.platform?.userMessage ?? messageToRegenerate ?? message,
                  customModel
                );
              }}
              onSave={() => {
                if (props.onSaveMessage) {
                  props.onSaveMessage(preparedMessage);
                } else {
                  setModalMessage(preparedMessage);
                  setIsShowModalSaveNewDocument(true);
                }
              }}
              onEdit={newText => {
                setVersionDecisionsMap(prev => ({
                  ...prev,
                  [message.previous_message ?? -1]: (message._variants?.count ?? 0) + 1,
                }));
                props.onEditMessage?.(message, newText);
              }}
              onSnippet={() => {
                setModalMessage(preparedMessage);
                setIsAddToSnippetModalVisible(true);
              }}
              onSubmitSuggestion={props.onSubmitSuggestion}
            />
          );
        })}

        <DocumentsManagerModal
          visible={isShowModalSaveNewDocument}
          onCreateNewDocument={() => {
            setIsShowModalSaveNewDocument(false);

            prompt({
              title: 'Create new document',
              inputPlaceholder: 'Document name',
              confirmButtonText: 'Create',

              className: 'create-new-document-modal',

              primary: true,
              handleConfirm: async name => {
                await asyncProcessManager?.doProcess({
                  name: 'Create new document',
                  action: async () => {
                    await extensionService.createDocument({
                      name,
                      file: modalMessage?.message ?? '',
                    });

                    toast.success('Document created');

                    setModalMessage(null);

                    // ExtensionEvents.dispatch(ExtensionEventType.RefreshDocuments);
                  },
                });
              },
            });
          }}
          onReplaceExistingDocument={onReplaceExistingDocument}
          onClose={() => setIsShowModalSaveNewDocument(false)}
        />
      </div>
      <AddToSnippetModal
        visible={isAddToSnippetModalVisible}
        onClose={() => setIsAddToSnippetModalVisible(false)}
        initialText={
          isAddToSnippetModalVisible && modalMessage?.message
            ? modalMessage.message ?? undefined
            : undefined
        }
      />
    </>
  );
}
