import './index.scss';

import { StringTools } from '../../../../../../tools/string';
import DocumentIcon from '../../../../../design/assets/svg/icons/DocumentIcon';

export interface IMessageAttachment {
  filename: string;
}
export function MessageAttachment(attachment: IMessageAttachment) {
  return (
    <div className="message-attachment-file">
      <div className="attachment-file">
        <div className="attachment-icon">
          <DocumentIcon />
        </div>

        <div className="attachment-meta">
          <span className="title">{attachment.filename}</span>
          <span className="filetype">
            {StringTools.getFileTypeInfo(attachment.filename)?.title}
          </span>
        </div>
      </div>
    </div>
  );
}
