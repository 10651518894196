import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '..';

export enum ThemeOptions {
  Purple = 'purple',
  Green = 'green',
}

export interface ThemeState {
  theme: ThemeOptions;
}

const initialState: ThemeState = {
  theme: ThemeOptions.Purple,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeOptions>) => {
      state.theme = action.payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export const selectTheme = (state: RootState) => state.theme.theme;

export default themeSlice;
