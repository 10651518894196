import { ExtensionService } from '../../../../../service';
import {
  AlchemyModel,
  IConversation,
  IConversationMessages,
  IMessage,
  IMessageBase,
} from '../../../../../service/base/ai/interfaces';
import { HistoryStorageProvider } from '..';

export class BackendHistoryStorageProvider implements HistoryStorageProvider {
  private extensionService: ExtensionService;

  constructor(extensionService: ExtensionService) {
    this.extensionService = extensionService;
  }

  async getConversationsList(): Promise<IConversation[]> {
    return ((await this.extensionService.getSidebarConversations()) ?? []).sort((a, b) => {
      const aDate = new Date(a.updated_at ?? 0);
      const bDate = new Date(b.updated_at ?? 0);

      return bDate.getTime() - aDate.getTime();
    });
  }

  async getConversationMessages(conversationId: number): Promise<IConversationMessages | null> {
    return await this.extensionService.getSidebarConversationMessages(conversationId);
  }
  async createConversation(name: string, model: AlchemyModel): Promise<IConversation | null> {
    return await this.extensionService.createSidebarConversation(name, model);
  }
  async createMessage(message: IMessageBase): Promise<IMessage | null> {
    const preparedMessage = { ...message } as any;

    if (preparedMessage.previous_message === null) delete preparedMessage?.previous_message;
    if (preparedMessage.parent === null) delete preparedMessage?.parent;
    delete preparedMessage?.id;

    return await this.extensionService.createSidebarConversationMessage(preparedMessage);
  }
  async updateMessage(id: number, message: IMessageBase): Promise<IMessage | null> {
    return await this.extensionService.updateSidebarConversationMessage(id, message);
  }
  async removeConversation(conversationId: number): Promise<void> {
    return await this.extensionService.removeSidebarConversation(conversationId);
  }
  async removeAllConversations(): Promise<void> {
    return await this.extensionService.removeAllSidebarConversations();
  }

  async continueInChat(
    messages: IMessageBase[],
    model: AlchemyModel,
    title: string
  ): Promise<IConversation | null> {
    return await this.extensionService.sidebarContinueChat(messages, model, title);
  }
}
