import { Button } from '../../../../../../design/components/button';
import { AuthBlockHeader } from '../../../../components/header';

export interface IResetPasswordSuccessStep {
  size?: 'default' | 'mini';

  onFinish: () => void;
}

export function ResetPasswordSuccessStep({
  size = 'default',
  ...props
}: IResetPasswordSuccessStep) {
  return (
    <>
      <AuthBlockHeader
        size={size}
        title={'Success'}
        subTitle={
          'Your password has been reset, you can now log in to your account with the new password'
        }
      />
      <form className='auth-form'>
        <Button type='submit' active={true} onClick={() => props.onFinish()}>
          Proceed to login
        </Button>
      </form>
    </>
  );
}
