import { MainLogo } from '../../assets/svg/MainLogo';
import styles from './TermsOfServicePage.module.scss';

export const TermsOfServicePage = () => {
  return (
    <div className={styles.terms}>
      <div className={styles.termsLogoContainer}>
        <MainLogo />
      </div>
      <div className={styles.termsContent}>
        <div className={styles.termsHeader}>
          <h1 className={styles.termsTitle}>Terms of Service</h1>
          <p className={styles.termsUpdateInfo}>Last updated: January 2024</p>
          <p className={styles.termsNoticeInfo}>
            By logging into your account you agree to be bound by our Terms of Service.
          </p>
        </div>
        <div className={styles.termsInfo}>
          <h2 className={styles.termsIntroduction}>Introduction</h2>
          <p>
            Welcome to Alchemy, a leading Software as a Service (SaaS) provider. These Terms of Use
            (&apos;Terms&apos;) govern your access to and use of Alchemy’s services, websites,
            applications, and software (collectively, the &apos;Service&apos;). By accessing or
            using the Service, you agree to be bound by these Terms and our Privacy Policy. If you
            do not agree to these Terms, you must not use our Service.
          </p>
          <h3>Overview of the Service</h3>
          <p>
            <strong>Description</strong>: Alchemy offers a suite of advanced, customizable prompts
            and tools for ChatGPT, transforming it into a powerful assistant for professional
            growth, innovation, and optimized time management. Our Service is designed to provide
            specialized prompts that enhance the precision and effectiveness of ChatGPT
            interactions, a centralized prompt repository for easy access and organization, and
            features for saving and sharing AI-generated content.
          </p>
          <p>
            <strong>Intended Users</strong>: The Service is intended for use by professionals,
            content creators, and organizations for streamlining decision-making, fostering
            collaboration, enhancing storytelling, and maximizing efficiency in various fields.
          </p>
          <h3>Acceptance of Terms</h3>
          <p>
            <strong>Agreement</strong>: By using the Service, you acknowledge that you have read,
            understood, and agreed to these Terms.
          </p>
          <p>
            <strong>Updates and Amendments</strong>: We reserve the right to update or modify these
            Terms at any time. Your continued use of the Service after any such changes indicates
            your acceptance of the new Terms.
          </p>
          <h3>Legal Capacity</h3>
          <p>
            <strong>Authority</strong>: If you are using the Service on behalf of an organization,
            you affirm that you have the authority to bind that organization to these Terms.
          </p>
          <h3>
            <span>Scope and Binding Nature</span>
          </h3>
          <p>
            <strong>Scope</strong>: These Terms govern all use of the Service, including but not
            limited to the access and use of our software, website, and any associated services.
          </p>
          <p>
            <strong>Legally Binding</strong>: These Terms constitute a legally binding agreement
            between you (either an individual or a legal entity) and Alchemy.
          </p>
          <h3>Relationship to Privacy Policy</h3>
          <p>
            <strong>Incorporation of Privacy Policy</strong>: The use of personal information you
            provide to us during your use of the Service is governed by our Privacy Policy, which is
            incorporated by reference into these Terms.
          </p>
          <p>
            Importance of Review: We encourage you to carefully review our Privacy Policy to
            understand our practices regarding your personal information.
          </p>
          <h3>Contact Information</h3>
          <p>
            <strong>Queries</strong>: Should you have any questions regarding these Terms or the
            Service, please contact us at hello@get-alchemy.com.
          </p>
          <h2>Eligibility and User Registration</h2>
          <p>
            This section outlines the eligibility criteria for using Alchemy&apos;s services and the
            requirements for registering and maintaining an account with us. By registering for our
            services, you agree to provide accurate, current, and complete information and to keep
            this information updated.
          </p>
          <h3>Eligibility Criteria</h3>
          <p>
            <strong>Legal Capacity</strong>: By creating an account, you represent that you have the
            legal capacity to enter into a binding contract.
          </p>
          <p>
            <strong>Prohibited Users</strong>: You may not use the services if doing so would
            violate any applicable law or if you have been previously banned or removed from our
            services.
          </p>
          <h3>Account Registration</h3>
          <p>
            <strong>Account Creation</strong>: To access certain features of the service, you must
            create an account. When creating an account, you agree to:
          </p>
          <ul>
            <li>
              Provide true, accurate, current, and complete information about yourself as prompted
              by the registration form.
            </li>
            <li>
              Maintain and promptly update your account information to keep it true, accurate,
              current, and complete.
            </li>
          </ul>
          <p>
            <strong>Account Security</strong>: You are responsible for maintaining the
            confidentiality of your account password and for all activities that occur under your
            account. Alchemy is not liable for any loss or damage arising from your failure to
            comply with this obligation.
          </p>
          <h3>User Responsibilities</h3>
          <p>
            <strong>Lawful Use</strong>: You agree to use the service only for lawful purposes and
            in a manner that does not infringe the rights of, restrict, or inhibit anyone
            else&apos;s use and enjoyment of the service.
          </p>
          <p>
            <strong>Prohibited Conduct</strong>: You are prohibited from engaging in conduct that is
            harmful, abusive, racially or ethnically offensive, defamatory, infringing, invasive of
            personal privacy or publicity rights, harassing, humiliating to other people, libelous,
            threatening, or otherwise objectionable.
          </p>
          <h3>Account Management</h3>
          <p>
            <strong>Updating Information</strong>: You are responsible for keeping your registration
            data current and accurate. Changes can be made through your account settings.
          </p>
          <p>
            <strong>Responsibility for Activity</strong>: You are solely responsible for all
            activity occurring under your account, including all transactions and other activities
            undertaken with your account credentials.
          </p>
          <h3>Suspension and Termination</h3>
          <p>
            <strong>Rights of Alchemy</strong>: Alchemy reserves the right to suspend or terminate
            your account and access to the service if any information provided during the
            registration process or thereafter is inaccurate, not current, or incomplete.
          </p>
          <p>
            <strong>User-Initiated Termination</strong>: You may close your account at any time
            through the account settings. Upon closure, your right to use the service will
            immediately cease.
          </p>
          <h3>Communication</h3>
          <p>
            <strong>Electronic Communication Agreement: </strong>By creating an account with
            Alchemy, you consent to receive electronic communications from Alchemy (e.g., via email
            or by posting notices to the service). These communications may include notices about
            your account and are part of your relationship with Alchemy.
          </p>
          <p>&#xa0;</p>
          <p>
            By registering an account with Alchemy, you acknowledge that you meet the eligibility
            criteria and agree to these terms regarding your account creation, maintenance, and
            responsibilities.
          </p>
          <h2>Privacy Policy Reference</h2>
          <p>
            In this section, we highlight the relationship between these Terms of Use and the
            Privacy Policy of Alchemy. The Privacy Policy is an integral part of your interaction
            with our services and outlines how we handle the personal information you provide while
            using our services.
          </p>
          <h3>Incorporation of Privacy Policy</h3>
          <p>
            <strong>Complementary Document:</strong> The Privacy Policy is a separate document that
            complements these Terms of Use. It is available on our website and forms a part of the
            agreement between you and Alchemy.
          </p>
          <p>
            <strong>Content of the Privacy Policy</strong>: The Privacy Policy details the types of
            personal information we collect, how we use it, who we share it with, and the measures
            we take to protect it.
          </p>
          <h3>Importance of Reading the Privacy Policy</h3>
          <p>
            <strong>Understanding Your Rights:</strong> By using our services, you must understand
            how we collect and use your data. The Privacy Policy provides detailed information about
            your rights regarding your personal information and how to exercise them.
          </p>
          <p>
            <strong>Consent and Agreement: </strong>Your use of our services signifies your consent
            to the data practices described in the Privacy Policy. If you do not agree with the
            Privacy Policy, you should not use our services.
          </p>
          <h3>Changes to Privacy Policy</h3>
          <p>
            <strong>Updates and Revisions:</strong> Just like these Terms of Use, our Privacy Policy
            may be revised from time to time. We encourage you to review it periodically to stay
            informed of how we are protecting your information.
          </p>
          <p>
            <strong>Notification of Changes:</strong> Significant changes to the Privacy Policy will
            be communicated to you through the channels outlined in the Privacy Policy itself.
          </p>
          <h3>Compliance with Laws</h3>
          <p>
            <strong>Legal Frameworks:</strong> Our Privacy Policy is designed to be compliant with
            relevant data protection laws such as the General Data Protection Regulation (GDPR), the
            California Consumer Privacy Act (CCPA), and other applicable privacy laws.
          </p>
          <p>
            <strong>User Responsibility</strong>: As a user, it’s your responsibility to ensure that
            you comply with any laws or regulations when you provide personal information to us.
          </p>
          <h3>Contact for Privacy Concerns</h3>
          <p>
            <strong>Queries and Concerns</strong>: If you have any questions or concerns about our
            Privacy Policy, or if you need to report a privacy issue, you can contact us directly at
            hello@get-alchemy.com.
          </p>
          <h3>Data Processing and Consent</h3>
          <p>
            <strong>Understanding Data Processing</strong>: The Privacy Policy explains our data
            processing activities in detail, providing transparency about our practices.
          </p>
          <p>
            <strong>Consent to Data Processing</strong>: By agreeing to these Terms of Use, you also
            consent to the data processing practices as described in our Privacy Policy.
          </p>
          <h2>Use of the Service</h2>
          <p>
            This section outlines the guidelines and conditions for using Alchemy’s services. Your
            compliance with these guidelines is essential to maintain a secure, functional, and
            legal use of our service.
          </p>
          <h3>Permitted Uses</h3>
          <p>
            <strong>Scope of Use</strong>: You are permitted to use Alchemy’s services solely for
            [specify purpose, e.g., business, educational, or personal use] as intended and
            permitted by the features of the service.
          </p>
          <p>
            <strong>Compliance with Laws</strong>: You agree to use the service in compliance with
            all applicable laws, regulations, and guidelines.
          </p>
          <h3>User Conduct and Responsibilities</h3>
          <p>
            <strong>Responsible Use</strong>: You must use the service responsibly and with respect
            for other users. This includes not engaging in behavior that is harmful, threatening,
            abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.
          </p>
          <p>
            <strong>Prohibited Activities</strong>: You are prohibited from:
          </p>

          <ul>
            <li>
              Accessing or attempting to access the service by any means other than the interface
              provided by Alchemy.
            </li>
            <li>Engaging in any activity that interferes with or disrupts the service.</li>
            <li>
              Using the service to send unauthorized advertising, spam, or other forms of
              solicitation.
            </li>
            <li>Using the service to distribute malware or other harmful technology.</li>
            <li>
              {' '}
              Attempting to decipher, decompile, disassemble, or reverse engineer any of the
              software used to provide the service.
            </li>
          </ul>

          <h3>Content and Data</h3>
          <p>
            <strong>User Content</strong>: If the service allows you to post, upload, or otherwise
            submit content, you are responsible for ensuring that this content complies with these
            Terms and does not infringe on the rights of any third party.
          </p>
          <p>
            <strong>Data Ownership</strong>: You retain all rights to the data that you upload or
            submit to the service. However, by submitting data, you grant Alchemy a worldwide,
            non-exclusive, royalty-free license to use, reproduce, and display this data as
            necessary to provide the service.
          </p>
          <h3>Service Modifications and Availability</h3>
          <p>
            <strong>Service Changes</strong>: Alchemy reserves the right to modify or discontinue,
            temporarily or permanently, the service (or any part thereof) with or without notice.
          </p>
          <p>
            <strong>Availability</strong>: We strive to ensure that the service is available at all
            times, but you acknowledge that there may be interruptions or downtime due to
            maintenance, updates, or unforeseen circumstances.
          </p>
          <h3>Third-Party Services and Content</h3>
          <p>
            <strong>External Links</strong>: The service may contain links to third-party websites,
            advertisers, services, or other events or activities that are not owned or controlled by
            Alchemy. We do not endorse or assume any responsibility for such third-party sites,
            information, materials, products, or services.
          </p>
          <h3>Intellectual Property Protection</h3>
          <p>
            <strong>Respect for Intellectual Property</strong>: You agree not to infringe on the
            intellectual property rights of others in connection with your use of the service. This
            includes respecting copyright, trademarks, and other proprietary rights.
          </p>
          <h3>Usage Limitations</h3>
          <p>
            <strong>Fair Use</strong>: You agree not to engage in excessive usage of the service, as
            determined by Alchemy in its sole discretion. Excessive use may result in temporary or
            permanent suspension of your access to the service.
          </p>
          <h3>Reporting Misuse</h3>
          <p>
            <strong>Notification of Violations</strong>: If you become aware of any misuse of our
            services or any security breach in connection with your account, please contact us
            immediately.
          </p>
          <h2>Intellectual Property Rights</h2>
          <p>
            This section outlines the policies and terms related to intellectual property rights
            associated with Alchemy’s services. These rights are pivotal to our business and are
            protected by law.
          </p>
          <h3>Ownership of Service and Content</h3>
          <p>
            <strong>Alchemy’s Rights</strong>: All intellectual property rights in the service,
            including software, associated content (excluding user-generated content), and the
            technology used to provide the service, are and shall remain the sole property of
            Alchemy or its licensors. This includes but is not limited to user interfaces, source
            code, databases, functionality, website designs, audio, video, text, photographs, and
            graphics.
          </p>
          <p>
            <strong>Protection and Restrictions</strong>: The trademarks, service marks, and logos
            of Alchemy (“Alchemy Trademarks”) used and displayed in connection with the service are
            registered and unregistered trademarks or service marks of Alchemy. You are not granted
            any rights to use Alchemy Trademarks without our prior written consent.
          </p>
          <p>&#xa0;</p>
          <p>
            <strong>License to Users</strong>
          </p>
          <p>
            <strong>Service Access</strong>: Subject to your compliance with these Terms, Alchemy
            grants you a limited, non-exclusive, non-transferable, and revocable license to access
            and use the service for your lawful, internal, and non-commercial purposes.
          </p>
          <p>
            <strong>Restrictions</strong>: You shall not:
          </p>

          <ul>
            <li>
              Copy, modify, or create derivative works based on the service or any part thereof.
            </li>
            <li>
              Rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or
              otherwise make available the service or any features or functionality of the service,
              to any third party for any reason.
            </li>
            <li>
              Decompiling, reverse-engineering, disassembling, or otherwise attempting to discover
              the source code of the service or any part thereof, unless applicable laws
              specifically prohibit such restriction.
            </li>
          </ul>

          <h3>User-Generated Content</h3>
          <p>
            <strong>Ownership and Responsibility</strong>: Users retain all rights to text, files,
            images, photos, video, sounds, or any other materials they submit, post, or display on
            or through the service (“User Content”).
          </p>
          <p>
            <strong>License to Alchemy</strong>: By submitting User Content, you grant Alchemy a
            worldwide, non-exclusive, royalty-free, transferable license (with the right to
            sublicense) to use, reproduce, distribute, prepare derivative works of, display, and
            perform that User Content in connection with the service and Alchemy’s business.
          </p>
          <p>
            <strong>Content Standards</strong>: User Content must comply with all applicable laws,
            regulations, and these Terms. You represent and warrant that your User Content does not
            violate any third-party rights, including intellectual property rights.
          </p>
          <h3>Copyright Infringement and DMCA Policy</h3>
          <p>
            <strong>DMCA Compliance</strong>: Alchemy respects the intellectual property rights of
            others and expects users of the service to do the same. We will respond to notices of
            alleged copyright infringement in accordance with the Digital Millennium Copyright Act
            (“DMCA”).
          </p>
          <p>
            <strong>Reporting Infringements</strong>: If you believe that your copyrighted work has
            been copied and posted on the service in a way that constitutes copyright infringement,
            please provide us with the information specified in the DMCA, including evidence of your
            copyright, a description of where the infringing material is located on the service, and
            your contact details.
          </p>
          <h3>Feedback</h3>
          <p>
            <strong>Submission of Feedback</strong>: Any feedback, comments, ideas, improvements, or
            suggestions (collectively, &quot;Feedback&quot;) provided by you to Alchemy regarding
            the service shall remain the sole and exclusive property of Alchemy.
          </p>
          <p>
            <strong>Use of Feedback</strong>: Alchemy is free to use, copy, modify, publish, or
            redistribute the Feedback for any purpose and in any way without credit or compensation
            to you.
          </p>
          <p>&#xa0;</p>
          <p>
            By using Alchemy’s services, you acknowledge and agree to these terms regarding
            intellectual property rights and undertake to respect them in all interactions with the
            service.
          </p>
          <h2>User-Generated Content</h2>
          <p>
            Alchemy’s services may allow you to create, upload, post, send, receive, and store
            content, including messages, text, photos, videos, software, and other materials
            (collectively, &quot;User-Generated Content&quot;). When you provide User-Generated
            Content via the Service, you agree to the following terms:
          </p>
          <h3>Ownership and Responsibility</h3>
          <p>
            <strong>User Ownership</strong>: You retain any rights you hold in the User-Generated
            Content you post to the Service.
          </p>
          <p>
            <strong>Responsibility</strong>: You are solely responsible for any User-Generated
            Content you contribute, and you agree to evaluate and bear all risks associated with
            your content.
          </p>
          <h3>License Grant to Alchemy</h3>
          <p>
            <strong>License to Use</strong>: By submitting, posting, or displaying User-Generated
            Content on or through the Service, you grant Alchemy a worldwide, non-exclusive,
            royalty-free license (with the right to sublicense) to use, copy, reproduce, process,
            adapt, modify, publish, transmit, display, and distribute such User-Generated Content in
            any media or distribution methods (now known or later developed). This license includes
            the right for Alchemy to make your User-Generated Content available to others for the
            publication, distribution, syndication, or broadcast of such content on other media and
            services.
          </p>
          <p>
            <strong>Duration of License</strong>: This license continues even if you stop using our
            Service.
          </p>
          <h3>User Representations and Warranties</h3>
          <p>
            <strong>Lawful Content</strong>: You represent and warrant that your User-Generated
            Content, and the use of such content, complies with these Terms and all applicable laws,
            rules, and regulations.
          </p>
          <p>
            <strong>No Infringement</strong>: You represent and warrant that your User-Generated
            Content does not infringe upon the intellectual property rights, privacy rights,
            publicity rights, or other legal rights of any third party.
          </p>
          <h3>Content Review and Removal</h3>
          <p>
            <strong>Monitoring Content</strong>: Alchemy does not undertake to monitor the
            submission or publication of User-Generated Content. However, we may, at our discretion,
            review, edit, or delete User-Generated Content that we deem to be illegal, offensive, or
            otherwise in violation of these Terms.
          </p>
          <p>
            <strong>Removal of Content</strong>: We reserve the right to remove any User-Generated
            Content from the Service at any time, without prior notice and at our sole discretion,
            if we believe that it violates these Terms or any applicable law.
          </p>
          <h3>Prohibited Content</h3>
          <p>
            <strong>Restrictions</strong>: You agree not to post, upload, transmit, or make
            available any User-Generated Content that is:
          </p>
          <p>
            &#xa0;&#xa0; - Illegal, harmful, threatening, abusive, harassing, tortious, defamatory,
            vulgar, obscene, libelous, invasive of another&apos;s privacy, hateful, or racially,
            ethnically, or otherwise objectionable.
          </p>
          <p>
            &#xa0;&#xa0; - In violation of any third-party rights, including intellectual property
            rights.
          </p>
          <p>
            <strong>Enforcement</strong>: Violation of these content standards may result in the
            termination or suspension of your account and your right to use the Service.
          </p>
          <h3>User Disputes</h3>
          <p>
            <strong>Responsibility</strong>: You are solely responsible for your interactions with
            other users of the Service and any disputes that arise from those interactions with
            regard to your User-Generated Content.
          </p>
          <h3>Indemnification</h3>
          <p>
            <strong>Indemnity by User</strong>: You agree to indemnify and hold harmless Alchemy and
            its officers, directors, employees, and agents from any claims, liabilities, damages,
            losses, and expenses, including reasonable attorneys&apos; fees, made by any third party
            due to or arising out of your User-Generated Content, your use of the Service, your
            violation of these Terms, or your violation of any rights of another.
          </p>
          <p>&#xa0;</p>
          <p>
            By providing User-Generated Content to the Service, you acknowledge and agree to these
            terms, representing that you have the right and authority to post such content and to
            grant the necessary rights and licenses as described above.
          </p>
          <p>&#xa0;</p>
          <h2>Subscription and Payment Terms</h2>
          <p>
            Alchemy offers a variety of subscription plans for its services, governed by the terms
            outlined in this section. By subscribing to our services, you acknowledge and agree to
            these terms.
          </p>
          <h3>Subscription Plans and Fees</h3>
          <p>
            <strong>Available Plans</strong>: We offer different subscription plans, the details of
            which are available on our website or through our sales team.
          </p>
          <p>
            <strong>Fees</strong>: The fees for each subscription plan are specified on our website
            and may vary based on the plan’s features and your usage.
          </p>
          <h3>Billing and Payment</h3>
          <p>
            <strong>Billing Cycle</strong>: Subscription fees are billed in advance on a recurring
            basis (e.g., monthly or annually), depending on the plan you select.
          </p>
          <p>
            <strong>Payment Processing with Stripe</strong>: We use Stripe, a third-party payment
            processor, to handle payments. Stripe accepts various payment methods, including credit
            cards and other electronic payments. Your chosen payment method will be charged at the
            beginning of your billing cycle through Stripe.
          </p>
          <p>
            <strong>Updating Payment Information</strong>: Keep your payment information accurate
            and up-to-date. Failure to update payment information, especially if using Stripe, may
            result in the interruption of your service. Updates can be made through the Stripe
            interface or as directed by Alchemy.
          </p>
          <h3>Renewals and Cancellations</h3>
          <p>
            <strong>Automatic Renewal</strong>: Subscriptions automatically renew at the end of each
            billing cycle unless you cancel before the renewal date.
          </p>
          <p>
            <strong>Cancellation Policy</strong>: Cancel your subscription anytime through your
            account settings or by contacting customer support. Access to the service continues
            until the end of your current billing cycle.
          </p>
          <p>
            <strong>Refunds</strong>: Generally, our fees are non-refundable, except as required by
            law or at our sole discretion.
          </p>
          <h3>Price Changes</h3>
          <p>
            <strong>Notification of Price Changes</strong>: We reserve the right to adjust pricing
            for our services at any time. You will be notified before any price change becomes
            effective.
          </p>
          <p>
            <strong>Acceptance of New Prices</strong>: Continued use of the service after a price
            change signifies your agreement to the new subscription fee amount.
          </p>
          <h3>Free Trials and Promotions</h3>
          <p>
            <strong>Trial Periods</strong>: Certain subscription plans may offer free trial periods.
            Terms will be provided at the start of the trial.
          </p>
          <p>
            <strong>Trial Conclusion</strong>: Unless you cancel before the end of the trial period,
            your subscription will automatically start, and billing will commence through Stripe.
          </p>
          <h3>
            <span>Overdue Payments</span>
          </h3>
          <p>
            <strong>Handling Overdue Payments</strong>: If a payment is not successfully settled due
            to issues like expiration or insufficient funds, we may suspend your access to the
            service. Stripe may reattempt charging the payment method or request updated payment
            information.
          </p>
          <p>
            <strong>Responsibility for Payment</strong>: Ensure timely payment to avoid service
            interruptions.
          </p>
          <h3>Usage Limits</h3>
          <p>
            <strong>Service Usage Limits</strong>: Some plans have usage limits, detailed in the
            plan specifics. Exceeding these limits may result in additional charges or necessitate a
            plan upgrade.
          </p>
          <p>&#xa0;</p>
          <p>
            By subscribing to Alchemy’s services, you agree to these subscription and payment terms
            and accept responsibility for all charges incurred under your account.
          </p>
          <h2>Termination and Account Cancellation</h2>
          <p>
            This section outlines the conditions under which your account and access to Alchemy’s
            services may be terminated, either by you or by Alchemy. It also describes the
            consequences of termination.
          </p>
          <h3>User-Initiated Termination</h3>
          <p>
            <strong>Voluntary Cancellation</strong>: You may cancel your account and terminate these
            Terms of Use at any time via the account settings or by contacting our support team.
            Upon cancellation, your right to use the service will cease immediately.
          </p>
          <p>
            <strong>Effect of Cancellation</strong>: After you cancel your account, you will no
            longer have access to any parts of the service that require an account. However, some
            information may persist in backup copies for a reasonable period.
          </p>
          <h3>Termination by Alchemy</h3>
          <p>
            <strong>Right to Terminate</strong>: Alchemy reserves the right to suspend or terminate
            your account, and your access to the service, at our sole discretion, without notice, if
            we believe that you have breached these Terms of Use, if your use of the service poses a
            risk to Alchemy, other users, or if required by law.
          </p>
          <p>
            <strong>Notice of Termination</strong>: Whenever feasible, Alchemy will provide you with
            notice of our intention to terminate your account, along with an explanation of the
            reasons for such termination.
          </p>
          <h3>Effects of Termination</h3>
          <p>
            <strong>Access and Data</strong>: Upon termination, all rights granted to you under
            these Terms of Use will cease immediately, and you will no longer have access to your
            account or any data or content you have stored on the service.
          </p>
          <p>
            <strong>Data Retention and Deletion</strong>: Alchemy may retain or delete your data in
            accordance with our Privacy Policy and applicable law. In some cases, we may retain
            certain information to comply with legal obligations.
          </p>
          <h3>Survival of Certain Provisions</h3>
          <p>
            <strong>Continued Applicability</strong>: Certain provisions of these Terms of Use will
            survive termination, including, but not limited to, intellectual property rights,
            warranty disclaimers, indemnity, and limitations of liability.
          </p>
          <h3>Suspension of Service</h3>
          <p>
            <strong>Temporary Suspension</strong>: In certain circumstances, Alchemy may need to
            suspend your access to the service (e.g., for maintenance, security reasons, or legal
            requirements). We aim to minimize any disruption to the service and will endeavor to
            provide advance notice when possible.
          </p>
          <h3>Reinstatement</h3>
          <p>
            <strong>Conditions for Reinstatement</strong>: In some cases, we may offer reinstatement
            of a suspended or terminated account. This decision is at Alchemy’s sole discretion and
            may depend on the circumstances of the suspension or termination.
          </p>
          <h3>No Liability for Termination</h3>
          <p>
            <strong>Disclaimer</strong>: Alchemy shall not be liable for any loss or damage
            resulting from the suspension, termination, or cancellation of your account or access to
            the service.
          </p>
          <p>&#xa0;</p>
          <p>
            By using Alchemy’s services, you acknowledge and agree to these terms regarding the
            termination and cancellation of your account and understand the consequences associated
            with such actions.
          </p>
          <p>&#xa0;</p>
          <p>
            This section of the Terms of Use specifies the legal jurisdiction that governs the
            agreement between you and Alchemy. It is important for defining how the Terms should be
            interpreted and enforced.
          </p>
          <h3>Choice of Law</h3>
          <p>
            <strong>Jurisdiction</strong>: These Terms of Use and your use of the Alchemy service
            will be governed by and construed in accordance with the laws of [specify jurisdiction,
            e.g., the State of Delaware, United States], without regard to its conflict of law
            principles.
          </p>
          <p>
            <strong>Exclusion of UN Convention</strong>: The application of the United Nations
            Convention on Contracts for the International Sale of Goods is expressly excluded from
            these Terms of Use.
          </p>
          <h3>Jurisdictional Compliance</h3>
          <p>
            <strong>User Compliance</strong>: You agree to comply with all local, state, national,
            and international laws and regulations applicable to your use of the Alchemy service.
          </p>
          <p>
            <strong>International Use</strong>: If you access the Service from a location outside
            [specify jurisdiction], you are responsible for compliance with all local laws.
          </p>
          <h3>Jurisdiction for Legal Disputes</h3>
          <p>
            <strong>Legal Actions</strong>: Any legal action or proceeding arising under these Terms
            of Use will be brought exclusively in the federal or state courts located in [specify
            location] and you hereby irrevocably consent to the personal jurisdiction and venue
            there.
          </p>
          <p>
            <strong>Exceptions</strong>: This clause does not affect any statutory rights that may
            not be contractually altered or waived.
          </p>
          <h3>Application of Governing Law</h3>
          <p>
            <strong>Consistency with Law</strong>: These Terms of Use are intended to be consistent
            with and shall be subject to all applicable laws and regulations.
          </p>
          <p>
            <strong>Severability</strong>: If any provision of these Terms of Use is held by a court
            or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for
            any reason, such provision shall be eliminated or limited to the minimum extent such
            that the remaining provisions of the Terms of Use will continue in full force and
            effect.
          </p>
          <h3>Legal Effect</h3>
          <p>
            <strong>Binding Agreement</strong>: By using the Alchemy service, you acknowledge that
            you understand and agree to be bound by the governing law as outlined in this section.
          </p>
          <p>
            <strong>Interpretation</strong>: This Governing Law section shall be interpreted in a
            manner that gives effect to its purpose of defining the legal framework under which
            these Terms of Use are to be governed.
          </p>
          <p>&#xa0;</p>
          <p>
            By continuing to access or use Alchemy’s services, you signify your acceptance of this
            Governing Law provision and your agreement to be legally bound by it.
          </p>
          <h2>Feedback and Reporting Issues</h2>
          <h3>Submission of Feedback</h3>
          <p>
            <strong>Encouragement of Feedback</strong>: Alchemy encourages users to submit feedback,
            comments, and suggestions for improvements to the Service (“Feedback”).
          </p>
          <p>
            <strong>Method of Submission</strong>: Feedback can be submitted via the communication
            channels provided on the Service or directly through user interfaces.
          </p>
          <p>
            <strong>Use of Feedback</strong>: Alchemy may use any feedback you provide without
            obligation or compensation to you. Feedback is considered non-confidential and
            non-proprietary.
          </p>
          <h3>Reporting Issues</h3>
          <p>
            <strong>Reporting Mechanism</strong>: Users can report technical issues, service
            interruptions, or violations of these Terms of Use through our designated reporting
            channels.
          </p>
          <p>
            <strong>User Cooperation</strong>: In the case of reporting violations, users are
            expected to provide detailed information to assist in investigating the issue.
          </p>
          <h3>Response and Resolution</h3>
          <p>
            <strong>Commitment to Response</strong>: Alchemy commits to addressing user feedback and
            reported issues in a timely and effective manner.
          </p>
          <p>
            <strong>No Obligation</strong>: While we strive to address all feedback and issues, we
            are under no obligation to act on any feedback or resolve any reported issue in a manner
            not deemed appropriate by Alchemy.
          </p>
          <p>&#xa0;</p>
          <h2>General Provisions</h2>
          <h3>Entire Agreement</h3>
          <p>
            <strong>Inclusivity of Terms</strong>: These Terms of Use constitute the entire
            agreement between you and Alchemy regarding the use of the Service, superseding any
            prior agreements between you and Alchemy.
          </p>
          <h3>
            <span>Waiver and Severability</span>
          </h3>
          <p>
            <strong>Waiver</strong>: The failure of Alchemy to enforce any right or provision of
            these Terms will not be deemed a waiver of such right or provision.
          </p>
          <p>
            <strong>Severability</strong>: If any provision of these Terms is held to be invalid or
            unenforceable, then that provision will be limited or eliminated to the minimum extent
            necessary, and the remaining provisions of these Terms will remain in full force and
            effect.
          </p>
          <h3>Assignment</h3>
          <p>
            <strong>Restrictions on Transfer</strong>: These Terms, and any rights and licenses
            granted hereunder, may not be transferred or assigned by you but may be assigned by
            Alchemy without restriction.
          </p>
          <h2>Contact Information</h2>
          <p>&#xa0;</p>
          <p>
            <strong>Contact Details</strong>: For any questions about these Terms of Use or the
            Service, please contact Alchemy at [provide contact details, e.g., email, phone number,
            mailing address].
          </p>
          <p>
            <strong>Effective Communication</strong>: We aim to provide clear and accessible means
            of communication and encourage users to reach out for any clarification or inquiries
            related to the Service or these Terms.
          </p>
          <p>&#xa0;</p>
          <p>
            By using Alchemy’s Service, you acknowledge that you have read, understood, and agreed
            to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms,
            please do not use the Service.
          </p>
        </div>
      </div>
    </div>
  );
};
