import { createApi, fetchBaseQuery, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import * as I from '../models/IAuth';

export const AUTH_API_REDUCER_KEY = 'authApi';

export const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  tagTypes: ['Auth'],
  endpoints: builder => ({
    login: builder.mutation<I.IAuthResponseWithStatus, I.IAuthLoginBody>({
      query: body => ({
        url: '/user/login/',
        method: 'POST',
        body,
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as I.IAuthResponseWithStatus;
      },
    }),

    register: builder.mutation<I.IAuthResponseWithStatus, I.IAuthRegisterBody>({
      query: body => ({
        url: '/user/register/',
        method: 'POST',
        body,
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as I.IAuthResponseWithStatus;
      },
    }),

    googleLogin: builder.mutation<I.IAuthResponseWithStatus, I.IAuthGoogleRegisterBody>({
      query: body => ({
        url: '/user/google-callback-login/',
        method: 'POST',
        body,
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as I.IAuthResponseWithStatus;
      },
    }),

    logout: builder.query<unknown, void>({
      query: () => ({
        url: '/user/logout/',
        headers: {
          authorization: `token ${
            localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token')
          }`,
        },
      }),
    }),

    googleRegister: builder.mutation<I.IAuthResponseWithStatus, I.IAuthGoogleRegisterBody>({
      query: body => ({
        url: '/user/google-callback-signup/',
        method: 'POST',
        body,
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as I.IAuthResponseWithStatus;
      },
    }),

    resetCode: builder.mutation<I.IResetCodeResponse, I.IEmailBody>({
      query: body => ({
        url: '/user/reset-code-send/',
        method: 'POST',
        body,
      }),
    }),

    resetCodeCheck: builder.mutation<I.IResetCodeCheckResponse, I.IResetCodeCheckBody>({
      query: body => ({
        url: '/user/reset-code-check/',
        method: 'POST',
        body,
      }),
    }),

    resetPassword: builder.mutation<I.IResetPasswordResponse, I.IResetPasswordBody>({
      query: body => ({
        url: '/user/reset-password/',
        method: 'POST',
        body,
      }),
    }),

    // Check if username and email are unique
    checkUsernameAndEmail: builder.mutation<
      I.ICheckUsernameAndEmailResponseWithStatus,
      I.ICheckUsernameAndEmailBody
    >({
      query: body => ({
        url: '/user/check-reg/',
        method: 'POST',
        body,
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return {
          ...data,
          status: meta?.response?.status,
        } as I.ICheckUsernameAndEmailResponseWithStatus;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useGoogleRegisterMutation,
  useLazyLogoutQuery,
  useGoogleLoginMutation,
  useResetCodeMutation,
  useResetCodeCheckMutation,
  useResetPasswordMutation,
  useCheckUsernameAndEmailMutation,
} = authApi;
