export const LightningIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      style={{ fill: 'transparent' }}
    >
      <path
        d='M5.32533 11.4891L11.5387 3.29004L10.7623 9.20637L14.6749 9.17773M14.674 9.18592L7.80702 16.6979L8.60822 11.4888L5.32422 11.4891'
        stroke='var(--color-titanium-200)'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
