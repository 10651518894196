import React from 'react';

export const AlchemyLogoWithoutText = () => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.51293 1.38477C4.63231 1.38477 2.67881 2.85102 2.15557 4.65532L0.0979305 11.7506C-0.294893 13.1052 0.529188 14.2006 1.94105 14.2006H4.70601L7.64296 9.99478L5.94336 5.11091L10.1902 8.0613L15.0868 6.38294L15.8348 3.84624C16.2356 2.48718 15.4116 1.38477 13.9951 1.38477H6.51293Z'
        fill='white'
      />
      <path
        d='M6.85018 7.71623C6.56769 8.05592 6.35183 8.44163 6.23331 8.85034L3.94256 16.7495C3.54987 18.1036 4.37443 19.1986 5.78677 19.1986H13.3437C15.1995 19.1986 17.1316 17.7708 17.6831 15.9916L19.888 8.87914C20.3134 7.50674 19.4895 6.38282 18.0579 6.38282L15.0869 6.38282L12.1238 10.6084L13.8234 15.4923L9.57655 12.5419L4.70612 14.2005L7.64307 9.99464L6.85018 7.71623Z'
        fill='white'
        fillOpacity='0.6'
      />
      <path
        d='M15.0869 6.38282L9.52249 6.38281C9.07743 6.38281 8.627 6.49151 8.20612 6.68268L10.1904 8.06117L15.0869 6.38282Z'
        fill='white'
        fillOpacity='0.6'
      />
    </svg>
  );
};
