import cn from 'classnames';
import { useFormikContext } from 'formik';
import { FC, useState } from 'react';

import { ChainIcon } from '../../../../../assets/svg/ChainIcon';
import { PlusIcon } from '../../../../../assets/svg/PlusIcon';
import { TrashIcon } from '../../../../../assets/svg/TrashIcon';
import { CustomPromptTypes, ICreateCustomPromptStep } from '../../../../../models/IPromptLibrary';
import sharedStyles from '../CreateCustomPrompt.module.scss';
import { CreatePromptField } from '../CreatePromptField';
import styles from './PromptChainList.module.scss';

interface IHashtag {
  id: number;
  variable_name: string;
}

interface IFormValues {
  title: string;
  description: string;
  prompt: string;
  category: number[];
  prompt_type: CustomPromptTypes;
  steps: ICreateCustomPromptStep[];
}

type Props = {
  name: 'steps';
  hashtags: IHashtag[];
  disabled: boolean;
  hashtagItems: React.MutableRefObject<string[]>;
  onSetNewChainHashtagItems: (newHashItems: string[], promptTemplates: string) => void;
  chainPromptId: number;
};

export const PromptChainList: FC<Props> = ({
  name,
  hashtags = [],
  disabled,
  hashtagItems,
  onSetNewChainHashtagItems,
  chainPromptId,
}) => {
  const { values, setFieldValue } = useFormikContext<IFormValues>();
  const promptChainSteps = values.steps;
  const [selectedChainPromptStep, setSelectedChainPromptStep] = useState<number | null>(
    values.steps[0]?.step_number
  );
  const promptMarkdownTemplate = promptChainSteps?.find(
    ps => ps.step_number === selectedChainPromptStep
  )?.prompt_markdown_template;

  const handleDeleteChainPrompt = (promptStep: number) => {
    if (values.steps?.length === 1) return;

    const filteredSteps = values.steps?.filter(step => step.step_number !== promptStep);
    const updatedSteps = filteredSteps.map((step, index) => ({
      ...step,
      step_number: index + 1,
    }));

    setSelectedChainPromptStep(updatedSteps[updatedSteps.length - 1].step_number);
    setFieldValue('steps', updatedSteps);
  };

  const handleAddNewChainPrompt = (step: number) => {
    const currentSteps = values.steps;
    const newStep = {
      step_number: step,
      prompt_template: '',
      prompt_markdown_template: '',
      user_prompt: chainPromptId ? chainPromptId : null,
    };
    const updatedSteps = [...currentSteps, newStep];

    setFieldValue('steps', updatedSteps);
    setSelectedChainPromptStep(step);
  };

  const onChangeSingleStep = (promptTemplate: string, promptMarkdownTemplate: string) => {
    if (!selectedChainPromptStep) return;

    const currentSteps = [...values.steps];

    const updatedSteps = currentSteps.map(cs => {
      if (cs.step_number === selectedChainPromptStep) {
        return {
          ...cs,
          prompt_template: promptTemplate,
          prompt_markdown_template: promptMarkdownTemplate,
        };
      }

      return cs;
    });

    setFieldValue('steps', updatedSteps);
  };

  const onSetNewHashtagItems = (newHashItems: string[]) => {
    onSetNewChainHashtagItems(
      newHashItems,
      values.steps?.map(sp => sp.prompt_template.trim()).join('')
    );
  };

  return (
    <>
      <div className={styles.promptChainWrapper}>
        <div className={styles.promptChainAndBtnWrapper}>
          <div className={styles.promptChainList}>
            {promptChainSteps?.map((promptChainStep, index) => (
              <button
                key={`${promptChainStep.step_number} ${
                  promptChainStep.prompt_template?.slice(0, 2) || index
                }`}
                className={cn(styles.promptChainBtn, {
                  [styles.selectedPrompt]: selectedChainPromptStep === promptChainStep.step_number,
                })}
                type="button"
                onClick={() => setSelectedChainPromptStep(promptChainStep.step_number)}
              >
                <ChainIcon />
                {`Prompt ${index + 1}`}
              </button>
            ))}
          </div>
          <button
            className={styles.promptChainAddBtn}
            type="button"
            onClick={() => handleAddNewChainPrompt(promptChainSteps?.length + 1)}
          >
            <PlusIcon />
            Add new chain
          </button>
        </div>
        {promptChainSteps.length > 0 && (
          <button
            className={styles.promptChainDeleteBtn}
            type="button"
            onClick={() => handleDeleteChainPrompt(selectedChainPromptStep)}
          >
            <TrashIcon />
          </button>
        )}
      </div>
      {promptChainSteps.length > 0 && (
        <>
          <div className={sharedStyles.createCustomPromptEditorWrapper}>
            <CreatePromptField
              hashtags={hashtags}
              name={name}
              disabled={disabled}
              hashtagItems={hashtagItems}
              chainHtmlContent={promptMarkdownTemplate}
              onSetNewHashtagItems={onSetNewHashtagItems}
              onChainChange={onChangeSingleStep}
              key={selectedChainPromptStep}
            />
          </div>
        </>
      )}
    </>
  );
};
