import { useNavigate } from 'react-router-dom';

import { IAuthRegisterBody, IAuthResponseWithStatus } from '../../models/IAuth';
import { useRegisterMutation } from '../../services/auth';
import { errorMessages } from '../../services/errors';
import { PAGES_ENUM } from '../../utils/constants';
import { handleError } from '../../utils/notifications';

export const useSignUp = () => {
  const navigate = useNavigate();
  const [register] = useRegisterMutation();
  const inviteRefCodeFromLocalStorage = localStorage.getItem('invite_ref_code');

  const handleSignUp = async (values: IAuthRegisterBody) => {
    const { username, email, password } = values;
    const newUser = {
      username,
      email,
      password,
      code: inviteRefCodeFromLocalStorage ? inviteRefCodeFromLocalStorage : '',
    };

    try {
      const data = (await register(newUser).unwrap()) as IAuthResponseWithStatus;

      if (data?.status >= 200 && data?.status < 400) {
        const token = data.auth_token;
        const userData = data.user;

        if (token && userData) {
          localStorage.setItem('auth_token', token);
          localStorage.setItem('ref_code', userData.referral_code);
          localStorage.setItem('show_download_extension', String(userData.show_again));
          localStorage.setItem('onboarding_passed', String(userData.onboarding_passed));
          navigate(PAGES_ENUM.ONBOARDING_FIRST_STEP);
        }
      }
    } catch (error) {
      if (error?.data?.info === 'USER_ALREADY_EXISTS') {
        handleError(errorMessages.USER_ALREADY_EXISTS);
      } else {
        handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
        console.error('Registration failed:', error);
      }
    }
  };

  return { handleSignUp };
};
