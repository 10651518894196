import cn from 'classnames';
import { useState } from 'react';

import { MainLogo } from '../../../assets/svg/MainLogo';
import { MaxWidthContainer } from '../../../components/Layouts/MaxWidthContainer';
import { useStripeRedirectToStripePage } from '../../../hooks/useStripeRedirectToStripePage';
import { SUBSCRIPTION_TIER, SUBSCRIPTION_TYPE } from '../../../models/ISubscription';
import { useGetSubscriptionLevelQuery } from '../../../services/subscribtion';
import { pricingPlan } from '../../../utils/mocks/Onboarding';
import { IBillingCard } from '../../SettingsPage/components/BillingAndPlansTabContent/BillingCard/IBillingCard';
import { BillingCard } from '../components/BillingCard';
import { UserInfoAndLogout } from '../components/UserInfoAndLogout';
import styles from './Billing.module.scss';

export const Billing = () => {
  const { data: subscriptionData } = useGetSubscriptionLevelQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const subscriptionLevel = subscriptionData?.subscription_level;
  const [currentSubscriptionType, setCurrentSubscriptionType] = useState<SUBSCRIPTION_TYPE>(
    SUBSCRIPTION_TYPE.MONTH
  );

  const getIsActiveBtn = (tier: SUBSCRIPTION_TIER) => subscriptionLevel === tier;

  const { onStripeRedirectToStripePage, isLoading } = useStripeRedirectToStripePage(true);

  const handleRedirect = (card: IBillingCard) => {
    if (getIsActiveBtn(card.tier)) return;
    onStripeRedirectToStripePage({ tier: card.tier, type: currentSubscriptionType });
  };

  return (
    <div className={styles.billing}>
      <MaxWidthContainer>
        <div className={styles.billingContent}>
          <div className={styles.billingHeader}>
            <MainLogo className={styles.logo} />
            <UserInfoAndLogout />
          </div>
          <div className={styles.billingSubscriptionTypes}>
            <button
              className={cn(styles.billingSubscriptionTypeBtn, {
                [styles.billingSubscriptionTypeBtnSelected]:
                  currentSubscriptionType === SUBSCRIPTION_TYPE.MONTH,
              })}
              onClick={() => setCurrentSubscriptionType(SUBSCRIPTION_TYPE.MONTH)}
            >
              Monthly
            </button>
            <button
              className={cn(styles.billingSubscriptionTypeBtn, {
                [styles.billingSubscriptionTypeBtnSelected]:
                  currentSubscriptionType === SUBSCRIPTION_TYPE.YEAR,
              })}
              onClick={() => setCurrentSubscriptionType(SUBSCRIPTION_TYPE.YEAR)}
            >
              Yearly
            </button>
          </div>
          <div className={styles.billingCardsWrapper}>
            {pricingPlan?.[currentSubscriptionType].map(card => (
              <BillingCard
                key={card.id}
                {...card}
                onClick={() => handleRedirect(card)}
                isLoading={isLoading}
              />
            ))}
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
