import React, { createRef, Dispatch, RefObject, SetStateAction, useState } from 'react';

import CheckboxIcon from '../../../../design/assets/svg/icons/CheckboxIcon';
import PasswordEyeIcon from '../../../../design/assets/svg/icons/PasswordEyeIcon';
import PasswordEyeIconCrossedOut from '../../../../design/assets/svg/icons/PasswordEyeIconCrossedOut';
import { Button } from '../../../../design/components/button';
import { AuthBlockFooter } from '../../components/footer';
import { AuthBlockHeader } from '../../components/header';
import { IValidationFieldErrors } from '../../interfaces';

export interface ISignUpBlock {
  size?: 'default' | 'mini';
  isLoading?: boolean;
  isBlob?: boolean;
  isDashboard?: boolean;

  onGoogle: () => void;
  onSignUp: (
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: ISignUpFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    hasErrors: boolean
  ) => void;

  onNavigateToForgotPassword: () => void;
  onNavigateToLogin: () => void;
}

export interface ISignUpFormState {
  username: string;
  email: string;
  password: string;
  collectData: boolean;
}

const validateField = (inputElement: HTMLInputElement, fieldName: string): string | null => {
  if (inputElement.validity.valueMissing || inputElement.value.trim() === '') {
    return 'Please fill out the field';
  } else if (inputElement.validity.typeMismatch) {
    return 'Please enter a valid email address';
  } else if (fieldName === 'password' && inputElement.validity.tooShort) {
    return 'Please use at least 8 characters';
  }

  return null;
};

const validateForm = (formRef: RefObject<HTMLFormElement | null>): IValidationFieldErrors => {
  const form = formRef.current;
  const errors: IValidationFieldErrors = {
    username: null,
    email: null,
    password: null,
  };

  if (form) {
    const elements = form.elements as HTMLFormControlsCollection;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLInputElement;

      if (element.name) {
        const error = validateField(element, element.name);

        if (error) {
          errors[element.name as keyof IValidationFieldErrors] = error;
        }
      }
    }
  }

  return errors;
};

export function SignUpBlock({ size = 'default', ...props }: ISignUpBlock) {
  const formRef = createRef<HTMLFormElement>();
  const [formState, setFormState] = useState<ISignUpFormState>({
    username: '',
    email: '',
    password: '',
    collectData: true,
  });
  const [errors, setErrors] = useState<IValidationFieldErrors>({
    username: null,
    email: null,
    password: null,
  });
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState(prev => ({ ...prev, [name]: value }));

    const error = validateField(e.target, name);

    setErrors(prev => ({ ...prev, [name]: error }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const validationErrors = validateForm(formRef);

    setErrors(validationErrors);

    const hasErrors = Object.values(validationErrors).some(error => error !== null);

    props.onSignUp(e, formRef, formState, setErrors, hasErrors);
  };

  return (
    <div className="auth-block">
      <AuthBlockHeader
        isBlob={props.isBlob}
        isDashboard={props.isDashboard}
        size={size}
        title={
          props.isBlob
            ? 'Welcome to Blob by Alchemy'
            : props.isDashboard
            ? 'Welcome to Alchemy'
            : 'Get started'
        }
        subTitle={
          props.isBlob || props.isDashboard
            ? 'By signing up, you are creating an Alchemy account from which you can access our suite of products.'
            : 'Create a new account'
        }
        googleButton={{
          title: 'Sign Up with Google',
          onClick: props.onGoogle,
        }}
      />
      <form ref={formRef} className="auth-form">
        <div className="input">
          <label className="input-label" htmlFor="username">
            Username
          </label>
          <input
            name="username"
            type="text"
            autoComplete="off"
            placeholder="Enter your username here"
            id="username"
            required
            value={formState.username}
            pattern=".*\S+.*"
            onChange={handleInputChange}
          />
          {errors.username && <div className="auth-error-message">{errors.username}</div>}
        </div>
        <div className="input">
          <label className="input-label" htmlFor="email">
            Email
          </label>
          <input
            name="email"
            type="email"
            placeholder="Enter email here"
            id="email"
            required
            value={formState.email}
            onChange={handleInputChange}
          />
          {errors.email && <div className="auth-error-message">{errors.email}</div>}
        </div>
        <div className="input password">
          <div className="label-button-container">
            <label className="input-label" htmlFor="password">
              Password
            </label>
            {!props.isBlob && (
              <button
                onClick={props.onNavigateToForgotPassword}
                type="button"
                className="forgot-password"
              >
                Forgot Password?
              </button>
            )}
          </div>
          <div
            className="password-eye-icon-wrapper"
            onClick={() => setIsShowPassword(prev => !prev)}
          >
            {isShowPassword ? <PasswordEyeIcon /> : <PasswordEyeIconCrossedOut />}
          </div>
          <input
            name="password"
            id="password"
            type={isShowPassword ? 'text' : 'password'}
            placeholder="Enter password here"
            required
            value={formState.password}
            onChange={handleInputChange}
            minLength={8}
          />
          {errors.password && <div className="auth-error-message">{errors.password}</div>}
        </div>
        <div
          onClick={() =>
            setFormState(prev => ({
              ...prev,
              collectData: !prev.collectData,
            }))
          }
          className="email-promotion-wrapper"
        >
          <CheckboxIcon radius={100} checked={formState.collectData} minHeight={14} minWidth={14} />
          <span>
            {props.isBlob
              ? 'Agree to receive periodic emails with updates'
              : 'I want to receive the latest AI-powered productivity insights and tips from Alchemy.'}
          </span>
        </div>
        <Button type="submit" isLoading={props.isLoading} active={true} onClick={handleSubmit}>
          Sign Up
        </Button>
        <p className="go-to-login">
          Already a member? <span onClick={props.onNavigateToLogin}>Log In</span>
        </p>
      </form>
      <AuthBlockFooter isBlob={props.isBlob} />
    </div>
  );
}
