import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const WebIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" {...props}>
      <path
        d="M7.99984 12.8337C10.6692 12.8337 12.8332 10.6697 12.8332 8.00033C12.8332 5.33095 10.6692 3.16699 7.99984 3.16699C5.33046 3.16699 3.1665 5.33095 3.1665 8.00033C3.1665 10.6697 5.33046 12.8337 7.99984 12.8337Z"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1668 8.00033C10.1668 11.0003 8.82856 12.8337 8.00016 12.8337C7.17176 12.8337 5.8335 11.0003 5.8335 8.00033C5.8335 5.00033 7.17176 3.16699 8.00016 3.16699C8.82856 3.16699 10.1668 5.00033 10.1668 8.00033Z"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3335 8H8.00016H12.6668"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
