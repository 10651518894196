import { createRef, RefObject } from 'react';

import { Button } from '../../../../../../design/components/button';
import { AuthBlockHeader } from '../../../../components/header';
import { IValidationFieldErrors } from '../../../../interfaces';
import { IResetPasswordFormState } from '../..';

export interface IResetPasswordInitialStep {
  size?: 'default' | 'mini';
  isLoading?: boolean;

  formState: IResetPasswordFormState;
  errors: IValidationFieldErrors;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    formRef: RefObject<HTMLFormElement>
  ) => void;

  onSubmit: (
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>
  ) => void;
  onCancel: () => void;
}

export function ResetPasswordInitialStep({
  size = 'default',
  ...props
}: IResetPasswordInitialStep) {
  const formRef = createRef<HTMLFormElement>();

  return (
    <>
      <AuthBlockHeader
        size={size}
        title={'Find your Alchemy account'}
        subTitle={
          'Enter the email address associated with your account to change your password'
        }
      />
      <form ref={formRef} className='auth-form'>
        <div className='input'>
          <label className='input-label' htmlFor='email'>
            Email Address
          </label>
          <input
            name='email'
            type='email'
            placeholder='Enter your email'
            id='email'
            required
            value={props.formState.email}
            onChange={(e) => props.handleInputChange(e, formRef)}
          />
          {props.errors.email && (
            <div className='auth-error-message'>{props.errors.email}</div>
          )}
        </div>
        <Button
          type='submit'
          active={true}
          className='enforce-blob-max-width'
          isLoading={props.isLoading}
          onClick={(e) => props.onSubmit(e, formRef)}
        >
          Next
        </Button>
        <Button
          className='enforce-blob-max-width'
          type='submit'
          onClick={() => props.onCancel()}
        >
          Cancel
        </Button>
      </form>
    </>
  );
}
