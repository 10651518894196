import { Outlet } from 'react-router-dom';

import { Sidebar } from '../../Sidebar';
import { MaxWidthContainer } from '../MaxWidthContainer';
import styles from './DashboardLayout.module.scss';

export const DashboardLayout = () => {
  return (
    <MaxWidthContainer>
      <div className={styles.dashboardLayoutContent}>
        <Sidebar />
        <div className={styles.dashboardLayoutMainContent}>
          <Outlet />
        </div>
      </div>
    </MaxWidthContainer>
  );
};
