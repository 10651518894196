import { ICreatedSnippet } from '../../models/ISnippet';

export enum SNIPPET_MODE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export interface ISnippetContent {
  mode: SNIPPET_MODE;
  selectedSnippet: ICreatedSnippet;
  onResetModeAndSelectedSnippetId: () => void;
}

export interface ISnippetCard extends ICreatedSnippet {
  onUpdateSnippet: (id: number) => void;
}
