import { ArticleData } from '@extractus/article-extractor';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { IAlchemyProfile, IAlchemySession, IPrompt } from '../interfaces';
import { RequestTools } from '../tools/request';
import { StringTools } from '../tools/string';
import { BaseExtensionService } from './base';
import { AIService } from './base/ai';
import {
  AlchemyModel,
  ConversationType,
  IConversation,
  IConversationMessages,
  IMessage,
  IMessageBase,
  ISearchResult,
} from './base/ai/interfaces';
import {
  IClip,
  IClipBase,
  IClipFolder,
  IClipFolderBase,
  IClipsScreenData,
  ISnippet,
  ITokensStatus,
} from './interfaces';

export class ExtensionService extends BaseExtensionService {
  protected static host = 'web.alchemy-app.com';

  protected static baseURL = `https://${this.host}/api`;
  protected axiosInstance: AxiosInstance = axios.create({
    baseURL: ExtensionService.baseURL,
    withCredentials: true,
  });

  constructor(initialSession: IAlchemySession, disableRequests?: boolean) {
    super(initialSession, disableRequests ?? false);
  }

  async setup(initialSession: IAlchemySession) {
    await super.setup(initialSession);
  }

  onSessionExpired(): void {
    /* pass */
  }

  async clearStorageData(): Promise<void> {
    /* pass */
  }

  async getSubscriptionTier(): Promise<number | null> {
    if (
      !this.axiosInstance.defaults.headers['Authorization'] ||
      this.axiosInstance.defaults.headers['Authorization'] === 'none'
    )
      return null;

    const response: AxiosResponse = await this.axiosInstance.get(`/user/subscription-level/`);
    const tier = response.data?.subscription_level ?? null;

    if (this.session) {
      this.session.tier = tier;
      await this.writeSessionToStorage(this.session);
    }

    return tier;
  }

  async getProfileFromBackend(): Promise<IAlchemyProfile | null> {
    const response = await this.axiosInstance.get('/user/profile/');

    return (response?.data as IAlchemyProfile) ?? null;
  }

  async getProfileFromStorage(): Promise<IAlchemyProfile | null> {
    return null;
  }

  async getSessionFromStorage(): Promise<IAlchemySession | null> {
    return ExtensionService.getSessionFromGlobalExtensionStorage();
  }

  async writeSessionToStorage(session: IAlchemySession | null): Promise<void> {
    /* pass */
  }

  async writeProfileToStorage(profile: IAlchemyProfile | null): Promise<void> {
    /* pass */
  }

  async searchWeb(query: string): Promise<ISearchResult[] | null> {
    try {
      const response = await this.axiosInstance.get(AIService.host + '/api/sidebar/search-api/', {
        params: {
          query,
        },
      });

      return response.data?.data?.results ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async getSidebarConversations(): Promise<IConversation[] | null> {
    try {
      const response = await this.axiosInstance.get('/sidebar/get-chats/');

      return response.data?.results ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async getSidebarConversationMessages(
    conversationId: number
  ): Promise<IConversationMessages | null> {
    try {
      const response = await this.axiosInstance.get('/sidebar/get-messages/', {
        params: {
          chat: conversationId,
        },
      });

      return {
        data: response.data?.data ? response.data.data.reverse() : null,
        current_message_id: response.data?.current_message_id,
      };
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async createSidebarConversation(
    name: string,
    model: AlchemyModel
  ): Promise<IConversation | null> {
    try {
      const response = await this.axiosInstance.post('/sidebar/create-chat/', {
        model,
        chat_title: name,
      });

      return response.data ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async makeSidebarConversationVisible(id: number): Promise<void> {
    try {
      await this.axiosInstance.put(`/sidebar/update-chat/${id}`, {
        visible: true,
      });
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async changeConversationType(id: number, type: ConversationType): Promise<void> {
    try {
      await this.axiosInstance.put(`/sidebar/update-chat/${id}`, {
        chat_type: type,
      });
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async sidebarContinueChat(
    messages: IMessageBase[],
    model: AlchemyModel,
    title: string
  ): Promise<IConversation | null> {
    try {
      const response = await this.axiosInstance.post(
        'https://sidebar.alchemy-app.com/api/sidebar/continue-chat/',
        {
          messages: messages,
          model,
          chat_title: title,
        }
      );

      return response.data ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async createSidebarConversationMessage(message: IMessageBase): Promise<IMessage | null> {
    try {
      const response = await this.axiosInstance.post('/sidebar/create-message/', message);

      return response.data ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async updateSidebarConversationMessage(
    id: number,
    message: IMessageBase
  ): Promise<IMessage | null> {
    try {
      const response = await this.axiosInstance.put(`/sidebar/update-message/${id}`, message);

      return response.data ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);
    }

    return null;
  }

  async removeSidebarConversation(conversationId: number): Promise<void> {
    try {
      await this.axiosInstance.delete(`/sidebar/delete-chat/${conversationId}`);
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async removeAllSidebarConversations(): Promise<void> {
    try {
      await this.axiosInstance.delete(`/sidebar/delete-chats/`);
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async getSnippets(): Promise<ISnippet[] | null> {
    if (!this.axiosInstance.defaults.headers['Authorization']) this.initializeAxios();

    try {
      const res = (await this.axiosInstance.get(`/sidebar/get-snippets/`)).data;

      return res.results as ISnippet[];
    } catch (err) {
      console.error('🛑 Error detected', err);
      throw err;
    }
  }

  async getClips(): Promise<IClip[] | null> {
    if (!this.axiosInstance.defaults.headers['Authorization']) this.initializeAxios();

    try {
      const res = (await this.axiosInstance.get(`/sidebar/get-clips/`)).data;

      return res.results as IClip[];
    } catch (err) {
      console.error('🛑 Error detected', err);
      throw err;
    }
  }

  async getClipsAndFolders(): Promise<IClipsScreenData | null> {
    if (!this.axiosInstance.defaults.headers['Authorization']) this.initializeAxios();

    try {
      const res = (await this.axiosInstance.get(`/sidebar/get-clips-folders/`)).data;

      return res as IClipsScreenData;
    } catch (err) {
      console.error('🛑 Error detected', err);
      throw err;
    }
  }

  async getTokensStatus(): Promise<ITokensStatus | null> {
    if (
      !this.isAuthorized() ||
      !this.session?.token ||
      !this.axiosInstance.defaults.headers['Authorization'] ||
      (this.session.tier ?? 0) >= 2
    )
      return null;

    try {
      const res = (await this.axiosInstance.get(`/user/token-status/`)).data;

      if (res?.status === false) {
        return { tokens: 1, total_tokens: 1 } as ITokensStatus;
      } else {
        return res as ITokensStatus;
      }
    } catch (err) {
      console.error('🛑 Error detected', err);
      throw err;
    }
  }

  async createSnippet(code: string, text: string): Promise<void> {
    try {
      await this.axiosInstance.post(`/sidebar/create-snippet/`, {
        code,
        text,
      });
    } catch (err) {
      const code = RequestTools.getApiErrorCode(err);

      if (code === 403) {
        const message = RequestTools.getApiErrorMessage(err);

        toast.error(message);
      } else {
        console.error('🛑 Error detected', err);
      }
    }
  }

  async createClip(data: IClipBase): Promise<void> {
    try {
      await this.axiosInstance.post(`/sidebar/create-clip/`, {
        ...data,
        preview: data.preview ? data.preview : 'none',
        folder: data.folder ?? undefined,
      });
    } catch (err) {
      const code = RequestTools.getApiErrorCode(err);

      if (code === 403) {
        const message = RequestTools.getApiErrorMessage(err);

        toast.error(message);
      } else {
        console.error('🛑 Error detected', err);
      }
    }
  }

  async createClipFolder(data: IClipFolderBase): Promise<IClipFolder | null> {
    try {
      const response = await this.axiosInstance.post(`/sidebar/create-clip-folder/`, data);

      return (response.data as IClipFolder) ?? null;
    } catch (err) {
      const code = RequestTools.getApiErrorCode(err);

      if (code === 403) {
        const message = RequestTools.getApiErrorMessage(err);

        toast.error(message);
      } else {
        console.error('🛑 Error detected', err);
      }
    }

    return null;
  }

  async updateSnippet(snippet: ISnippet): Promise<void> {
    try {
      await this.axiosInstance.put(`/sidebar/update-snippet/${snippet.id}`, {
        code: snippet.code,
        text: snippet.text,
      });
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async updateClip(id: number, data: IClipBase): Promise<void> {
    try {
      await this.axiosInstance.put(`/sidebar/update-clip/${id}`, data);
    } catch (err) {
      const code = RequestTools.getApiErrorCode(err);

      if (code === 403) {
        const message = RequestTools.getApiErrorMessage(err);

        toast.error(message);
      } else {
        console.error('🛑 Error detected', err);
      }
    }
  }

  async updateClipFolder(id: number, data: IClipFolderBase): Promise<void> {
    try {
      await this.axiosInstance.put(`/sidebar/update-clip-folder/${id}`, data);
    } catch (err) {
      const code = RequestTools.getApiErrorCode(err);

      if (code === 403) {
        const message = RequestTools.getApiErrorMessage(err);

        toast.error(message);
      } else {
        console.error('🛑 Error detected', err);
      }
    }
  }

  async deleteSnippet(snippet: ISnippet): Promise<void> {
    try {
      await this.axiosInstance.delete(`/sidebar/delete-snippet/${snippet.id}`);
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async deleteClip(id: number): Promise<void> {
    try {
      await this.axiosInstance.delete(`/sidebar/delete-clip/${id}`);
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async deleteClipFolder(id: number): Promise<void> {
    try {
      await this.axiosInstance.delete(`/sidebar/delete-clip-folder/${id}`);
    } catch (err) {
      console.error('🛑 Error detected', err);
    }
  }

  async sharePrompt(id: number): Promise<IPrompt | null> {
    try {
      const response = await this.axiosInstance.get(`/shop/share-custom/${id}`);

      return (response.data as IPrompt) ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);

      return null;
    }
  }

  async getSharedPrompt(id: number): Promise<IPrompt | null> {
    try {
      const response = await this.axiosInstance.get(`/shop/shared-custom/${id}`);

      return (response.data as IPrompt) ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);

      return null;
    }
  }

  async addSharedPrompt(id: number): Promise<IPrompt | null> {
    try {
      const response = await this.axiosInstance.get(`/shop/add-shared-custom/${id}`);

      return (response.data as IPrompt) ?? null;
    } catch (err) {
      console.error('🛑 Error detected', err);

      return null;
    }
  }

  async getWebsiteInfo(url: string): Promise<ArticleData | null> {
    if (StringTools.isValidURL(url)) {
      try {
        let article: ArticleData = {};

        const response = await axios.get('https://synthesize.alchemy-app.com/', {
          params: {
            url,
          },
        });

        if (response.data && !response.data.error) {
          article = {
            url,
            image: response.data?.preview,
            title: response.data?.title,
            description: response.data?.description ?? new URL(url).hostname,
            content: response.data?.content,
          };
        }

        return article;
      } catch (_e) {
        return null;
      }
    } else {
      throw new Error('Invalid URL');
    }
  }
}
