import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';

import {
  ICreatePremiumSubscriptionRequest,
  ICreatePremiumSubscriptionResponse,
  ISubscriptionLevel,
} from '../models/ISubscription';
import { baseQuery } from '../utils/baseQuery';

export const subscribtionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery,
  refetchOnFocus: true,
  tagTypes: ['Subscribtion'],
  endpoints: builder => ({
    getSubscriptionLevel: builder.query<ISubscriptionLevel & { status: number }, void>({
      query: () => ({ url: '/user/subscription-level/' }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as ISubscriptionLevel & {
          status: number;
        };
      },
      providesTags: () => ['Subscribtion'],
      keepUnusedDataFor: 0,
    }),

    unsubscribeUser: builder.mutation<ISubscriptionLevel & { status: number }, void>({
      query: () => ({ url: '/user/unsubscribe-premium/', method: 'DELETE' }),
      invalidatesTags: () => ['Subscribtion'],
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as ISubscriptionLevel & {
          status: number;
        };
      },
    }),

    createPremiumSubscription: builder.mutation<
      ICreatePremiumSubscriptionResponse,
      FormData | ICreatePremiumSubscriptionRequest
    >({
      query: body => ({ url: '/user/create-premium-subscription/', body, method: 'POST' }),
      invalidatesTags: () => ['Subscribtion'],
    }),
  }),
});

export const {
  useGetSubscriptionLevelQuery,
  useLazyGetSubscriptionLevelQuery,
  useUnsubscribeUserMutation,
  useCreatePremiumSubscriptionMutation,
} = subscribtionApi;
