import { useNavigate } from 'react-router-dom';

import { LogOutIcon } from '../../assets/svg/LogOutIcon';
import { PuzzleIcon } from '../../assets/svg/PuzzleIcon';
import { useExtensionServiceContext } from '../../external/extension/service/context';
import { ExtensionEvents } from '../../external/extension/service/events';
import { ExtensionEventType } from '../../external/extension/service/events/types';
import { HelpIcon } from '../../external/extension/ui/design/assets/svg/icons/HelpIcon';
import SettingsIconAlt from '../../external/extension/ui/design/assets/svg/icons/SettingsIconAlt';
import { Avatar } from '../../external/extension/ui/design/components/avatar';
import { Dropdown } from '../../external/extension/ui/design/components/dropdown';
import { confirm } from '../../external/extension/ui/design/components/modal/confirmation';
import { useLogOut } from '../../hooks/useLogOut';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { SUBSCRIPTION_TIER } from '../../models/ISubscription';
import { selectLoading } from '../../redux/Global';
import { useGetSettingsQuery } from '../../services/settings';
import { getIsUserAuthorizedWithGoogle } from '../../services/storageService';
import { useGetSubscriptionLevelQuery } from '../../services/subscribtion';
import { PAGES_ENUM } from '../../utils/constants';
import styles from './Sidebar.module.scss';
import { SidebarNavigation } from './SidebarNavigation';

export const Sidebar = () => {
  const { data: userData } = useGetSettingsQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data: subscriptionData, isFetching: isFetchingSubscriptionLevel } =
    useGetSubscriptionLevelQuery(undefined, { refetchOnMountOrArgChange: true });
  const subscriptionLevel = subscriptionData?.subscription_level;
  const isUpgradeNeeded = Number(subscriptionLevel) === SUBSCRIPTION_TIER.ZERO;
  const { avatar } = userData || {};
  const isLoading = useTypedSelector(selectLoading) || isFetchingSubscriptionLevel;

  const extensionService = useExtensionServiceContext();
  const extensionUser = extensionService.useExtensionUser();

  const firstAndLastName =
    (extensionUser.profile?.first_name ?? 'User') +
    (extensionUser.profile?.last_name ? ' ' + extensionUser.profile.last_name : '');
  const fullName =
    firstAndLastName.trim().length !== 0
      ? firstAndLastName
      : extensionUser.profile?.username ?? 'User';

  const isGoogleAuthenticate = getIsUserAuthorizedWithGoogle();
  const { onLogout, onGoogleLogout } = useLogOut();
  const navigate = useNavigate();

  const handleLogout = () => {
    confirm({
      title: 'Log out',
      theme: 'blob',
      text: 'Are you sure you want to sign out?',
      primary: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, sign out',
      handleConfirm: async () => {
        if (isGoogleAuthenticate) {
          onGoogleLogout();
        } else {
          onLogout();
        }

        ExtensionEvents.dispatch(ExtensionEventType.BlobExtensionInstantLogout);
      },
    });
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarNavContainer}>
        <SidebarNavigation
          isLoading={isLoading}
          isUpgradeNeeded={isUpgradeNeeded}
          isSubscribtionFetching={isFetchingSubscriptionLevel}
        />
      </div>

      <div className={styles.userInfoAndMenuWrapper}>
        <Dropdown
          verticalPosition="top"
          items={[
            {
              kind: 'item',
              icon: <PuzzleIcon />,
              text: 'Get Extension',
              onClick: () =>
                window.open(
                  'https://chromewebstore.google.com/detail/alchemyai/kdadfibngmakkjckcaidihibnkkchjnh?hl=en&authuser=0',
                  '_blank'
                ),
            },
            {
              kind: 'separator',
            },
            {
              kind: 'item',
              icon: <HelpIcon />,
              text: 'Support',
              onClick: () =>
                window.open(
                  'https://alchemy-app.notion.site/Knowledge-Base-11b92476a6604251951e738bb8dcbcfe',
                  '_blank'
                ),
            },
            {
              kind: 'item',
              icon: <SettingsIconAlt size={20} />,
              text: 'Settings',
              onClick: () => navigate(PAGES_ENUM.SETTINGS),
            },
            {
              kind: 'item',
              icon: <LogOutIcon />,
              text: 'Logout',
              onClick: handleLogout,
            },
          ]}
          disabled={isLoading}
        >
          <div className={styles.userInfo}>
            <Avatar size="little" url={avatar} userName={fullName} />
            <div className={styles.userNameAndEmail}>
              {fullName && <span className={styles.userName}>{fullName}</span>}
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
