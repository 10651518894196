import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const HeadingIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M4.79199 4.79199H6.04199M6.04199 4.79199H6.87533M6.04199 4.79199V9.79199M6.04199 15.2087H4.79199M6.04199 15.2087H6.87533M6.04199 15.2087V9.79199M6.04199 9.79199H13.9587M13.9587 9.79199V4.79199M13.9587 9.79199V15.2087M15.2087 4.79199H13.9587M13.9587 4.79199H13.1253M13.9587 15.2087H15.2087M13.9587 15.2087H13.1253"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
