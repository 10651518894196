import './index.scss';

import { useState } from 'react';

import { IClip, IClipFolder } from '../../../../../../service/interfaces';
import { Button } from '../../../../../design/components/button';
import { LoadingOverlay } from '../../../../../design/components/loading';
import { Modal } from '../../../../../design/components/modal';
import { ClipFolder } from '..';

export interface IAddToFolderModal {
  visible: boolean;
  folders: IClipFolder[];
  clips: IClip[];
  onRefresh: () => void;
  onClose: () => void;
  onAddToFolder: (folder: IClipFolder) => Promise<void>;
  onMoveToGeneral: () => Promise<void>;
  onCreateFolder: () => Promise<void>;
}

export function AddToFolderModal(props: IAddToFolderModal) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAddOnFolder = async (folder: IClipFolder) => {
    setIsLoading(true);
    await props.onAddToFolder(folder);
    setIsLoading(false);
  };

  return (
    <Modal
      title={'Please select folder'}
      theme="blob"
      visible={props.visible}
      onClose={props.onClose}
      className="add-to-folder-modal"
      hasCloseButton={true}
    >
      <div className="folders">
        <ClipFolder
          folder={{
            id: -1,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            title: 'Unsorted',
            color: 'default',
          }}
          searchQuery=""
          hideQuickActions={true}
          clips={props.clips.filter(clip => !clip.folder)}
          onClick={() => props.onMoveToGeneral()}
        />
        {props.folders.map((folder, index) => (
          <ClipFolder
            key={index}
            folder={folder}
            searchQuery=""
            hideQuickActions={true}
            clips={props.clips.filter(clip => clip.folder === folder.id)}
            onClick={() => handleAddOnFolder(folder)}
          />
        ))}

        <Button
          className="create-new-folder"
          size="full"
          text="Create new folder"
          active={false}
          onClick={() => {
            props.onCreateFolder();
          }}
        />
      </div>
      <LoadingOverlay active={isLoading} />
    </Modal>
  );
}
