/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IFillerIcon {}
export function FillerIcon(props: IFillerIcon) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  );
}
