import { createRef, MouseEvent, MouseEventHandler, ReactNode } from 'react';

import CheckboxIcon from '../../../../assets/svg/icons/CheckboxIcon';
import CheckIconAlt from '../../../../assets/svg/icons/CheckIconAlt';

export interface ISwitchItem {
  value: number | string;
  title?: string | ReactNode;
  icon?: ReactNode;
  error?: boolean;
  screenReaderInfo?: string;
  beta?: boolean;
  description?: string;
  disabled?: boolean;
  hide?: boolean;
  onClick?: () => void;
}

export interface SwitchItemProps {
  id: string;
  checked?: boolean;
  isTabList?: boolean;
  roveIndex?: number;
  showCheckbox?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onHover?: () => void;
  type?: string;
}

export function SwitchItem(props: SwitchItemProps & ISwitchItem) {
  const ref = createRef<HTMLButtonElement>();

  function handleOnClick(e: MouseEvent<HTMLButtonElement>) {
    if (props.onClick) props.onClick(e);
  }

  if (props?.type === 'model-selection') {
    return (
      <button
        type='button'
        className={
          'switch-item with-description' +
          (props.checked ? ' checked' : '') +
          (props.error ? ' error' : '')
        }
        role={props.isTabList ? 'tab' : undefined}
        aria-selected={props.isTabList ? props.checked : undefined}
        onClick={handleOnClick}
        ref={ref}
        onMouseEnter={props.onHover}
      >
        <div className='wrapper-content'>
          {props.icon}
          <div className='wrapper-title-and-description'>
            {props.title && <span className='title'>{props.title}</span>}
            {props?.description && (
              <span className='description'>{props?.description}</span>
            )}
          </div>

          {props.beta && <span className='beta'>Beta</span>}
        </div>
        {props.showCheckbox && <CheckIconAlt />}
      </button>
    );
  }

  return (
    <button
      type='button'
      className={
        'switch-item' +
        (props.checked ? ' checked' : '') +
        (props.error ? ' error' : '')
      }
      role={props.isTabList ? 'tab' : undefined}
      aria-selected={props.isTabList ? props.checked : undefined}
      onClick={handleOnClick}
      ref={ref}
      onMouseEnter={props.onHover}
    >
      {props.icon}
      {props.title && <span className='title'>{props.title}</span>}
      {props?.description && (
        <span className='description'>{props?.description}</span>
      )}
      {props.beta && <span className='beta'>Beta</span>}
      {props.showCheckbox && <CheckboxIcon />}
    </button>
  );
}
