import { errorMessages } from '../../services/errors';
import { useRenameDocumentMutation } from '../../services/myContent';
import { handleError } from '../../utils/notifications';

export const useUpdateDocumentName = () => {
  const [renameDocument] = useRenameDocumentMutation();

  const handleUpdateDocumentName = async (id: number, name: string) => {
    try {
      await renameDocument({ id, body: { name } }).unwrap();
    } catch (error) {
      handleError(errorMessages.DOCUMENT_NAME_NOT_UPDATED);
      console.error('error', error);
    }
  };

  return { handleUpdateDocumentName };
};
