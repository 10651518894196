import React, { createRef, Dispatch, RefObject, SetStateAction, useState } from 'react';

import PasswordEyeIcon from '../../../../design/assets/svg/icons/PasswordEyeIcon';
import PasswordEyeIconCrossedOut from '../../../../design/assets/svg/icons/PasswordEyeIconCrossedOut';
import { Button } from '../../../../design/components/button';
import { AuthBlockFooter } from '../../components/footer';
import { AuthBlockHeader } from '../../components/header';
import { IValidationFieldErrors } from '../../interfaces';

export interface ILoginBlock {
  size?: 'default' | 'mini';
  isLoading?: boolean;
  isBlob?: boolean;
  isDashboard?: boolean;

  onGoogle: () => void;
  onLogin: (
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: ILoginFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    hasErrors: boolean
  ) => void;

  onNavigateToForgotPassword: () => void;
  onNavigateToSignUp: () => void;
}

export interface ILoginFormState {
  email: string;
  password: string;
  remember: boolean;
}

const validateField = (inputElement: HTMLInputElement, fieldName: string): string | null => {
  if (inputElement.validity.valueMissing || inputElement.value.trim() === '') {
    return 'Please fill out the field';
  } else if (inputElement.validity.typeMismatch) {
    return 'Please enter a valid email address';
  } else if (fieldName === 'password' && inputElement.validity.tooShort) {
    return 'Please use at least 8 characters';
  }

  return null;
};

const validateForm = (formRef: RefObject<HTMLFormElement | null>): IValidationFieldErrors => {
  const form = formRef.current;
  const errors: IValidationFieldErrors = {
    email: null,
    password: null,
  };

  if (form) {
    const elements = form.elements as HTMLFormControlsCollection;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLInputElement;

      if (element.name) {
        const error = validateField(element, element.name);

        if (error) {
          errors[element.name as keyof IValidationFieldErrors] = error;
        }
      }
    }
  }

  return errors;
};

export function LoginBlock({ size = 'default', ...props }: ILoginBlock) {
  const formRef = createRef<HTMLFormElement>();
  const [formState, setFormState] = useState<ILoginFormState>({
    email: '',
    password: '',
    remember: true,
  });
  const [errors, setErrors] = useState<IValidationFieldErrors>({
    email: null,
    password: null,
  });
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState(prev => ({ ...prev, [name]: value }));

    const error = validateField(e.target, name);

    setErrors(prev => ({ ...prev, [name]: error }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const validationErrors = validateForm(formRef);

    setErrors(validationErrors);

    const hasErrors = Object.values(validationErrors).some(error => error !== null);

    props.onLogin(e, formRef, formState, setErrors, hasErrors);
  };

  return (
    <div className="auth-block">
      <AuthBlockHeader
        isDashboard={props.isDashboard}
        isBlob={props.isBlob}
        size={size}
        title={
          props.isBlob
            ? 'Welcome to Blob by Alchemy'
            : props.isDashboard
            ? 'Welcome to Alchemy'
            : 'Welcome back'
        }
        subTitle={
          props.isBlob
            ? 'Please log in with your Alchemy account credentials.'
            : 'Log in to your account'
        }
        googleButton={{
          title: 'Continue with Google',
          onClick: props.onGoogle,
        }}
      />
      <form ref={formRef} className="auth-form">
        <div className="input">
          <label className="input-label" htmlFor="email">
            Email
          </label>
          <input
            name="email"
            type="email"
            placeholder="Enter email here"
            id="email"
            required
            value={formState.email}
            onChange={handleInputChange}
          />
          {errors.email && <div className="auth-error-message">{errors.email}</div>}
        </div>
        <div className="input password">
          <div className="label-button-container">
            <label className="input-label" htmlFor="password">
              Password
            </label>
            <button
              onClick={props.onNavigateToForgotPassword}
              type="button"
              className="forgot-password"
            >
              {props.isBlob ? 'Forgot?' : 'Forgot Password?'}
            </button>
          </div>
          <div
            className="password-eye-icon-wrapper"
            onClick={() => setIsShowPassword(prev => !prev)}
          >
            {isShowPassword ? <PasswordEyeIcon /> : <PasswordEyeIconCrossedOut />}
          </div>
          <input
            name="password"
            id="password"
            type={isShowPassword ? 'text' : 'password'}
            placeholder="Enter password here"
            required
            value={formState.password}
            onChange={handleInputChange}
          />
          {errors.password && <div className="auth-error-message">{errors.password}</div>}
        </div>
        <Button type="submit" active={true} onClick={handleSubmit} isLoading={props.isLoading}>
          Log In
        </Button>
        <p className="go-to-sign-up">
          {props.isBlob ? 'New member?' : "Don't have an account?"}{' '}
          <span onClick={props.onNavigateToSignUp}>Sign Up</span> for free.
        </p>
      </form>
      {!props.isBlob && <AuthBlockFooter />}
    </div>
  );
}
