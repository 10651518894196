import './index.scss';

import { NavLink, To } from 'react-router-dom';

import { LoadingSpinner } from '../loading';

export interface IButton {
  danger?: boolean;
  active?: boolean;
  disabled?: boolean;
  size?: 'mini' | 'full';
  onClick?: (e: React.MouseEvent<any>) => void;
  className?: string;
  text?: string;
  children?: React.ReactElement | string | (React.ReactElement | string)[];
  isLink?: boolean;
  linkTo?: To;
  style?: React.CSSProperties | undefined;
  type?: 'submit' | 'reset' | 'button';
  isLoading?: boolean;
  tabIndex?: number;
  onMouseEnter?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  onDoubleClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  onContextMenu?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
}

export function Button({ size = 'full', ...props }: IButton) {
  const buttonClass =
    'alchemy-button' +
    ' size-' +
    size +
    (props.active && !props.isLoading ? ' active' : '') +
    (props.danger ? ' danger' : '') +
    (props.className ? ' ' + props.className : '');

  return (
    <>
      {props.isLink ? (
        <NavLink
          end
          className={buttonClass}
          onClick={props.onClick}
          to={props.linkTo ?? '#'}
          style={props.style}
          tabIndex={props?.tabIndex}
          onMouseEnter={props?.onMouseEnter}
          onMouseLeave={props?.onMouseLeave}
          onMouseDown={props?.onMouseDown}
          onMouseMove={props?.onMouseMove}
          onMouseUp={props?.onMouseUp}
          onDoubleClick={props?.onDoubleClick}
          onContextMenu={props?.onContextMenu}
        >
          {props.text ?? props.children}
        </NavLink>
      ) : (
        <button
          className={buttonClass}
          onClick={props.onClick}
          disabled={props.disabled || props.isLoading}
          style={props.style}
          type={props.type}
          tabIndex={props?.tabIndex}
          onMouseEnter={props?.onMouseEnter}
          onMouseLeave={props?.onMouseLeave}
          onMouseDown={props?.onMouseDown}
          onMouseMove={props?.onMouseMove}
          onMouseUp={props?.onMouseUp}
          onDoubleClick={props?.onDoubleClick}
          onContextMenu={props?.onContextMenu}
        >
          {props.isLoading ? (
            <LoadingSpinner color="var(--app-text)" width={14} />
          ) : (
            <>{props.text ?? props.children}</>
          )}
        </button>
      )}
    </>
  );
}
