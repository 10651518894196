import { IMessage, IMessagePlatform } from '../../../../../../../service/base/ai/interfaces';
import { WebPlatform } from '../../../../../../../service/base/platform';
import {
  IDialogRenderVersionDecisionsMap,
  IDialogTreeItem,
  IDialogTreeRenderResult,
} from './interfaces';

export class DialogRendering {
  static generateMessagesMap(messages: IMessage[]): Map<number, IDialogTreeItem> {
    const messageMap = new Map<number, IDialogTreeItem>();

    // Заполняем map для быстрого доступа к сообщениям по ID
    messages.forEach(message => {
      messageMap.set(message.id, {
        message,
        isActive: false,
        children_ids: messages.filter(m => m.previous_message === message.id).map(m => m.id),
        type: 'default',
      });
    });

    return messageMap;
  }

  static buildTree(
    map: Map<number, IDialogTreeItem>,
    nodeId: number,
    activeNodeId: number,
    _level = 0
  ): IDialogTreeItem {
    // Clone the current node to prevent mutation of the original data
    const node =
      nodeId !== -1
        ? ({ ...map.get(nodeId) } as IDialogTreeItem)
        : ({
            type: 'root',
            children_ids:
              _level === 0
                ? Array.from(map.values())
                    .filter(
                      item =>
                        item.type === 'default' &&
                        item.message &&
                        !item.message.previous_message &&
                        item.message.id !== -1
                    )
                    .map(m => m.message?.id)
                : [],
          } as IDialogTreeItem);

    // If this node is the active node, mark it
    if (nodeId === activeNodeId) {
      node.isActive = true;
    }

    // If the node has children, recursively build the children's trees
    if (node.children_ids && node.children_ids.length) {
      node.children = node.children_ids.map(child =>
        this.buildTree(map, child, activeNodeId, _level + 1)
      );
    }

    // If this node or any of its children is marked as active, mark it as well
    if (node.children && node.children.some((child: IDialogTreeItem) => child.isActive)) {
      node.isActive = true;
    }

    return node;
  }

  static proccessMessagesList(list: IMessage[]): IMessage[] {
    let currentPlatform: IMessagePlatform | null = null;
    let currentUserMessage: IMessage | null = null;

    return list.reduce<IMessage[]>((acc, item) => {
      // Проверяем, содержит ли текущий элемент platform и его значение
      if (
        item.platform &&
        item.platform.type === WebPlatform.Compare &&
        item.platform.llm &&
        !item.model_message
      ) {
        // Не добавляем этот элемент в результат, но обновляем текущий платформ
        currentPlatform = item.platform;
        currentUserMessage = item;
      } else {
        // Если есть сохраненный платформ, передаем его текущему элементу
        if (currentPlatform) {
          item.platform = {
            ...currentPlatform,
            suggestions: item?.platform?.suggestions,
            userMessage: currentUserMessage,
          };
          currentPlatform = null; // Сбрасываем после передачи
          currentUserMessage = null;
        }
        // Добавляем элемент в аккумулятор
        acc.push(item);
      }

      return acc;
    }, []);
  }

  static renderItem(item: IDialogTreeItem): IDialogTreeRenderResult {
    const children = item.children;

    const currentChildIndex = item?.currentChildItemIndex ?? 0;
    const currentChildItem = children?.[currentChildIndex];

    const nextChildItemIndex = Math.max(
      currentChildItem?.children?.findIndex(c => c.isActive) ?? 0,
      0
    );

    const currentVersion = (item.parentChildItemIndex ?? 0) + 1;
    const versionsCount = item.parentChildrenCount ?? 0;

    const message: IMessage | undefined = item.message
      ? {
          ...item.message,

          _variants: {
            active: currentVersion,
            count: versionsCount,
          },
          _last: false,
        }
      : undefined;

    return {
      currentItem: message,
      nextItem: currentChildItem
        ? {
            ...currentChildItem,

            currentChildItemIndex: nextChildItemIndex,

            parentChildItemIndex: item.currentChildItemIndex ?? undefined,
            parentChildrenCount: item.children?.length ?? undefined,
          }
        : undefined,
    };
  }

  static renderTree(
    firstItem: IDialogTreeItem,
    versionDecisionsMap: IDialogRenderVersionDecisionsMap
  ): IMessage[] {
    const stack = [firstItem];
    const result: IMessage[] = [];

    while (stack.length > 0) {
      const node = stack.pop();

      // Process the node here (similar to what you do in your recursive function)

      if (node) {
        const decision =
          versionDecisionsMap?.[node.message?.id ?? -1] ??
          Math.max(node.children?.findIndex(c => c.isActive) ?? 0, 0) + 1 ??
          null;

        if (decision) {
          node.currentChildItemIndex = decision - 1;
        }

        const renderedNode = this.renderItem(node);

        if (renderedNode.currentItem) result.push(renderedNode.currentItem);

        if (renderedNode.nextItem) {
          stack.push(renderedNode.nextItem);
        }
      }
    }

    const processedResult = this.proccessMessagesList(result);

    if (processedResult.length > 0) {
      processedResult[processedResult.length - 1]._last = true;
    }

    return processedResult;
  }
}
