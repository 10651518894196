import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const LogOutIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M10.4999 3.71875H5.49992C4.57944 3.71875 3.83325 4.46494 3.83325 5.38542V14.1354C3.83325 15.0559 4.57944 15.8021 5.49992 15.8021H10.4999M8.41659 9.55208H17.1666M17.1666 9.55208L14.6666 7.05208M17.1666 9.55208L14.6666 12.0521"
        stroke="#F3F4F5"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
