import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { errorMessages } from '../../services/errors';
import { useDeleteAccountMutation } from '../../services/settings';
import { PAGES_ENUM } from '../../utils/constants';
import { handleError, handleSuccess } from '../../utils/notifications';

export const DestroyAccount = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');

  if (token) {
    localStorage.setItem('auth_token', token);
  }

  const [deleteAccount] = useDeleteAccountMutation();

  useEffect(() => {
    if (token) {
      deleteAccount()
        .unwrap()
        .then(() => {
          localStorage.clear();
          sessionStorage.clear();
          navigate(PAGES_ENUM.SIGN_UP, { replace: true });
          handleSuccess('Account successfully deleted');
        })
        .catch(error => {
          searchParams.delete('token');
          setSearchParams(searchParams);
          if (error?.status === 401) {
            handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
          } else {
            handleError(errorMessages.ACCOUNT_DELETION_ERROR);
            console.error(error);
          }
          navigate(PAGES_ENUM.SIGN_IN, { replace: true });
        });
    }
  }, []);

  return null;
};
