export interface ISettingsIconAlt {
  size?: number;
  color?: string;
}
export default function SettingsIconAlt(props: ISettingsIconAlt) {
  return (
    <svg
      width={props.size ?? 16}
      height={props.size ?? 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m7.19 3-.28.48c-.48.8-1.34 1.3-2.28 1.31h-.56c-.55 0-1.08.93-.8 1.41l.27.49c.46.81.46 1.8 0 2.62l-.28.49c-.27.48.26 1.4.81 1.4l.56.01c.94 0 1.8.5 2.28 1.31l.28.48c.28.48 1.34.48 1.62 0l.29-.48c.47-.8 1.34-1.3 2.27-1.31h.56c.24 0 .45-.13.57-.34.37-.64.33-.92-.03-1.56a2.67 2.67 0 0 1 0-2.62l.27-.49c.28-.48-.26-1.4-.81-1.4l-.56-.01c-.93 0-1.8-.5-2.27-1.31L8.8 3c-.28-.48-1.34-.48-1.62 0Zm-1.15-.68c.79-1.34 3.13-1.34 3.92 0l.28.48c.24.4.67.65 1.14.66h.56c1.56.01 2.73 2.04 1.96 3.4l-.27.48c-.23.41-.23.91 0 1.32l.27.48c.77 1.36-.4 3.39-1.96 3.4h-.56c-.47 0-.9.26-1.14.66l-.28.48c-.79 1.34-3.13 1.34-3.92 0l-.28-.48c-.24-.4-.67-.65-1.14-.66h-.56c-1.56-.01-2.73-2.04-1.96-3.4l.28-.48c.23-.41.23-.91 0-1.32l-.28-.48c-.77-1.36.4-3.39 1.96-3.4h.56c.47 0 .9-.26 1.14-.66l.28-.48ZM8 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM5.67 8a2.33 2.33 0 1 1 4.66 0 2.33 2.33 0 0 1-4.66 0Z"
        fill={props.color ?? 'currentColor'}
      ></path>
    </svg>
  );
}
