import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const HashtagIcon = (props: IIcon) => {
  return (
    <Icon width="24" height="24" {...props}>
      <path
        d="M10.25 4.75L7.75 19.25"
        stroke="#989898"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 4.75L13.75 19.25"
        stroke="#989898"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 8.75H5.75"
        stroke="#989898"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.25 15.25H4.75"
        stroke="#989898"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
