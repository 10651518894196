import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const BooksIcon = (props: IIcon) => {
  return (
    <Icon width="24" height="24" {...props}>
      <path
        d="M14.9239 16.0022L15.4059 18.4337C15.5124 18.9709 16.0882 19.3295 16.6921 19.2348L18.3323 18.9776C18.9362 18.8829 19.3394 18.3706 19.2329 17.8335L18.7509 15.4019M14.9239 16.0022L13.7671 10.1665C13.6606 9.62937 14.0638 9.11713 14.6677 9.02242L16.3079 8.76517C16.9118 8.67045 17.4876 9.02912 17.5941 9.56628L18.7509 15.4019M14.9239 16.0022L18.7509 15.4019M8.75 15.75V18.25C8.75 18.8023 9.19772 19.25 9.75 19.25H11.25C11.8023 19.25 12.25 18.8023 12.25 18.25V15.75M8.75 15.75V7.75C8.75 7.19772 9.19772 6.75 9.75 6.75H11.25C11.8023 6.75 12.25 7.19772 12.25 7.75V15.75M8.75 15.75H12.25M4.75 15.75V18.25C4.75 18.8023 5.19772 19.25 5.75 19.25H7.25C7.80228 19.25 8.25 18.8023 8.25 18.25V15.75M4.75 15.75V5.75C4.75 5.19772 5.19772 4.75 5.75 4.75H7.25C7.80228 4.75 8.25 5.19772 8.25 5.75V15.75M4.75 15.75H8.25"
        stroke="#898F93"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
