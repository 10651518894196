import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';

import * as I from '../models/ISettings';
import { baseQuery } from '../utils/baseQuery';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery,
  refetchOnFocus: true,
  tagTypes: ['Settings'],
  endpoints: builder => ({
    getSettings: builder.query<I.IUser, void>({
      query: () => ({ url: '/user/profile/' }),
      providesTags: () => ['Settings'],
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as I.IUser & { status: number };
      },
    }),

    setSettings: builder.mutation<unknown, unknown>({
      query: body => ({ url: '/user/partial-update/', body, method: 'PUT' }),
      invalidatesTags: () => ['Settings'],
    }),
    changePassword: builder.mutation<unknown, I.IChangePasswordRequest>({
      query: body => ({ url: '/user/change-password/', body, method: 'POST' }),
    }),

    sendDestroyLink: builder.mutation<I.ISendDestroyLinkRes, I.ISendDestroyLinkBody>({
      query: body => ({ url: '/user/send-destroy-link/', body, method: 'POST' }),
    }),

    deleteAccount: builder.mutation<I.IDeleteAccountRes, void>({
      query: () => ({ url: '/user/delete-account/', method: 'POST' }),
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
  useSetSettingsMutation,
  useChangePasswordMutation,
  useSendDestroyLinkMutation,
  useDeleteAccountMutation,
} = settingsApi;
