import { useEffect, useMemo, useState } from 'react';

import { FilePlusIcon } from '../../assets/svg/FilePlusIcon';
import { DocumentCard } from '../../components/DocumentCard';
import { EmptyPageAlert } from '../../components/EmptyPageAlert';
import { MainContentLayout } from '../../components/Layouts/MainContentLayout';
import { MyContentLayout } from '../../components/Layouts/MyContentLayout';
import { setModal } from '../../components/Modal/ModalSlice';
import { ModalTypes } from '../../components/Modal/types/enums/ModalTypes';
import useEmitter from '../../hooks/useEmitter';
import { useTypedDispatch } from '../../hooks/useTypedDispatch';
import { useGetDocumentsQuery } from '../../services/myContent';
import { EMITTER_EVENTS } from '../../utils/eventEmitter';
import { searchDocuments } from '../../utils/helpers';
import { DocumentContent } from './DocumentContent';
import styles from './MyContentPage.module.scss';

export const MyContentPage = () => {
  const dispatch = useTypedDispatch();
  const { data, isLoading: isLoadingDocuments } = useGetDocumentsQuery();
  const [query, setQuery] = useState('');
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const documentsData = data?.documents;
  const lastModifiedDocument = documentsData?.[0];
  const lastModifiedDocumentId = lastModifiedDocument?.id;

  const selectedDocument = useMemo(
    () => documentsData?.find(document => document.id === selectedDocumentId),
    [selectedDocumentId, documentsData]
  );

  const documentsFilteredByQuery = useMemo(() => {
    return searchDocuments(documentsData, query);
  }, [documentsData, query]);

  useEffect(() => {
    if (documentsFilteredByQuery?.length > 0 && query) {
      setSelectedDocumentId(documentsFilteredByQuery[0].id);
    }
  }, [documentsFilteredByQuery, query]);

  const onChange = (query: string) => {
    setQuery(query?.trim());
  };

  const cardList = documentsFilteredByQuery?.map(card => {
    const { id } = card || {};

    return (
      <DocumentCard
        key={id}
        {...card}
        isSelected={
          id === selectedDocumentId || (id === lastModifiedDocumentId && !selectedDocumentId)
        }
        onClick={() => setSelectedDocumentId(id)}
        query={query}
      />
    );
  });

  const cardDetail =
    selectedDocument || lastModifiedDocument ? (
      <DocumentContent
        key={selectedDocumentId || lastModifiedDocumentId}
        documentContent={selectedDocument || lastModifiedDocument}
        query={query}
      />
    ) : null;

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> CREATE DOCUMENT START <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const onShowModalCreateDocument = () => {
    dispatch(
      setModal({
        modalType: ModalTypes.CREATE_DOCUMENT_MODAL,
        modalProps: { emmitType: EMITTER_EVENTS.MODAL_CREATE_DOCUMENT },
      })
    );
  };

  useEmitter({
    emitterEventsName: EMITTER_EVENTS.MODAL_CREATE_DOCUMENT,
    func: () => {
      setSelectedDocumentId(null);
    },
  });

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> CREATE DOCUMENT END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> DELETE DOCUMENT START <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  useEmitter({
    emitterEventsName: EMITTER_EVENTS.MODAL_DELETE_DOCUMENT,
    func: () => {
      setSelectedDocumentId(null);
    },
  });

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> DELETE DOCUMENT END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  return (
    <MainContentLayout
      title="My Content"
      searchBarName="my-content"
      searchBarPlaceholder="Search content"
      extraHeaderContent={
        <button
          className={styles.myContentAddDocumentBtn}
          onClick={onShowModalCreateDocument}
          disabled={isLoadingDocuments}
          aria-label="Add document"
        >
          <FilePlusIcon />
        </button>
      }
      isSearchDisabled={isLoadingDocuments}
      onChange={onChange}
    >
      {documentsFilteredByQuery?.length > 0 || isLoadingDocuments ? (
        <MyContentLayout
          leftContent={cardList}
          rightContent={cardDetail}
          isLoading={isLoadingDocuments}
        />
      ) : (
        <EmptyPageAlert
          title="No Saved Content Available"
          description="Start a conversation and save the responses provided by the AI for later use."
          className={styles.alert}
        />
      )}
    </MainContentLayout>
  );
};
