/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import CryptoJS from 'crypto-js';
import { createRoot } from 'react-dom/client';

import { ImageModal } from './imageModal';

type ColorInput = string;

export enum ColorType {
  RGB = 'RGB',
  RGBA = 'RGBA',
  HSL = 'HSL',
  HSLA = 'HSLA',
  HEX = 'HEX',
  Unknown = 'Unknown',
}

export interface IRectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface INumberRange {
  min: number;
  max: number;
}

export class GraphicTools {
  static convertWebPtoPNG(webpURL: string, callback: (image: string) => void) {
    // Step 1: Load the WebP image into an Image element
    const img = new Image();

    img.crossOrigin = 'anonymous';
    img.src = webpURL;

    img.onload = function () {
      // Step 2: Draw the image on a canvas
      const canvas = document.createElement('canvas');

      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');

      ctx?.drawImage(img, 0, 0);

      // Step 3: Export the canvas content to PNG format
      const pngURL = canvas.toDataURL('image/png');

      callback(pngURL); // Return the PNG data URL
    };
  }

  static replaceBlackWithAlpha(color: ColorInput): string {
    let r: number, g: number, b: number;

    if (color.startsWith('rgb')) {
      // Extract the RGB values
      const rgbMatch = color.match(/rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/);

      if (!rgbMatch) throw new Error('Invalid RGB format');
      [r, g, b] = rgbMatch.slice(1).map(Number);
    } else if (color.startsWith('hex') || color.startsWith('#')) {
      // Convert HEX to RGB
      const hex = color.replace(/^hex|#/, '');
      const bigint = parseInt(hex, 16);

      r = (bigint >> 16) & 255;
      g = (bigint >> 8) & 255;
      b = bigint & 255;
    } else if (color.startsWith('hsl')) {
      // Convert HSL to RGB
      const hslMatch = color.match(/hsl\s*\(\s*(\d+)\s*,\s*(\d+)%\s*,\s*(\d+)%\s*\)/);

      if (!hslMatch) throw new Error('Invalid HSL format');
      const h = parseInt(hslMatch[1], 10);
      const s = parseInt(hslMatch[2], 10) / 100;
      const l = parseInt(hslMatch[3], 10) / 100;

      [r, g, b] = this.hslToRgb(h, s, l);
    } else {
      throw new Error('Unsupported color format');
    }

    // Convert RGB to RGBA with modified alpha based on brightness
    const alpha = this.calculateAlpha(r, g, b);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  static chooseTextColorFor(backgroundHEX: string, light: string, dark: string): string {
    const hsla = this.hexToHsla(backgroundHEX);

    return hsla.l > 45 && (hsla.h < 224 || hsla.h > 280 || hsla.l > 60) && hsla.a > 0.5
      ? dark
      : light;
  }

  static hslToRgb(h: number, s: number, l: number): [number, number, number] {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  static hslaToRgba(h: number, s: number, l: number, a: number): string {
    const [r, g, b] = this.hslToRgb(h / 360, s / 100, l / 100); // Ensure HSL values are in fraction

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  // Converts RGB to RGBA by adding an alpha value
  static rgbToRgba(r: number, g: number, b: number, a = 1.0): string {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  // Converts RGBA to RGB by ignoring the alpha value
  static rgbaToRgb(r: number, g: number, b: number): string {
    return `rgb(${r}, ${g}, ${b})`;
  }

  // Converts RGB to HEX
  static rgbToHex(r: number, g: number, b: number): string {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  }

  // Converts RGBA to HEX (including alpha)
  static rgbaToHex(r: number, g: number, b: number, a: number): string {
    let alpha = Math.round(a * 255).toString(16);

    if (alpha.length === 1) alpha = '0' + alpha;

    alpha = alpha.toUpperCase();

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}${
      alpha !== 'FF' ? alpha : ''
    }`;
  }

  static hexToRgbaObject(hex: string): {
    r: number;
    g: number;
    b: number;
    a: number;
  } {
    let r = 0,
      g = 0,
      b = 0,
      a = 1;

    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7 || hex.length === 9) {
      r = parseInt(hex.substring(1, 3), 16);
      g = parseInt(hex.substring(3, 5), 16);
      b = parseInt(hex.substring(5, 7), 16);
    }
    if (hex.length === 9) {
      a = parseInt(hex.substring(7, 9), 16) / 255;
    }

    return { r, g, b, a };
  }

  // Converts HEX (with or without alpha) to RGBA
  static hexToRgba(hex: string): string {
    let r = 0,
      g = 0,
      b = 0,
      a = 1;

    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7 || hex.length === 9) {
      r = parseInt(hex.substring(1, 3), 16);
      g = parseInt(hex.substring(3, 5), 16);
      b = parseInt(hex.substring(5, 7), 16);
    }
    if (hex.length === 9) {
      a = parseInt(hex.substring(7, 9), 16) / 255;
    }

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  static hexToHsla(hex: string): {
    h: number;
    s: number;
    l: number;
    a: number;
  } {
    // Ensure the hex is in the proper format
    hex = hex.replace('#', '');
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map(function (hex) {
          return hex + hex;
        })
        .join('');
    }

    // Extract the RGB components
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    const a = hex.length === 8 ? parseInt(hex.substring(6, 8), 16) / 255 : 1;

    // Convert RGB to HSL
    // eslint-disable-next-line
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h: number | undefined = undefined,
      s: number;
    const l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;

      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h = h ?? 0;

      h /= 6;
    }

    return {
      h: Math.round(h * 360),
      s: Math.round(s * 100),
      l: Math.round(l * 100),
      a,
    };
  }

  static detectColorType(color: string): ColorType {
    // RGB Pattern (spaces after commas are optional)
    const rgbPattern = /^rgb\(\d{1,3},\s?\d{1,3},\s?\d{1,3}\)$/;

    if (rgbPattern.test(color)) return ColorType.RGB;

    // RGBA Pattern (allowing .5 and 0.5)
    const rgbaPattern = /^rgba\(\d{1,3},\s?\d{1,3},\s?\d{1,3},\s?(0|1|0?\.\d+)\)$/;

    if (rgbaPattern.test(color)) return ColorType.RGBA;

    // HSL Pattern (spaces after commas are optional)
    const hslPattern = /^hsl\(\d{1,3},\s?\d{1,3}%,\s?\d{1,3}%\)$/;

    if (hslPattern.test(color)) return ColorType.HSL;

    // HSLA Pattern (allowing .5 and 0.5)
    const hslaPattern = /^hsla\(\d{1,3},\s?\d{1,3}%,\s?\d{1,3}%,\s?(0|1|0?\.\d+)\)$/;

    if (hslaPattern.test(color)) return ColorType.HSLA;

    // HEX Pattern
    const hexPattern = /^#([0-9a-fA-F]{3,4}|[0-9a-fA-F]{6,8})$/;

    if (hexPattern.test(color)) return ColorType.HEX;

    return ColorType.Unknown;
  }

  static hslToHex(h: number, s: number, l: number): string {
    const [r, g, b] = this.hslToRgb(h, s, l);

    return this.rgbToHex(r, g, b);
  }

  static hslaToHex(h: number, s: number, l: number, a: number): string {
    const [r, g, b] = this.hslToRgb(h, s, l);

    return this.rgbaToHex(r, g, b, a);
  }

  static everythingToHex(color: string): string | null {
    // Detect the color type
    switch (GraphicTools.detectColorType(color)) {
      case ColorType.RGB:
        const rgb = (color.match(/\d+/g) ?? []).map(Number);

        return this.rgbToHex(rgb[0], rgb[1], rgb[2]);
      case ColorType.RGBA:
        const rgba = (color.match(/\d+\.?\d*/g) ?? []).map(Number);

        return this.rgbaToHex(rgba[0], rgba[1], rgba[2], rgba[3]);
      case ColorType.HSL:
        const hsl = (color.match(/\d+/g) ?? []).map(Number);

        return this.hslToHex(hsl[0], hsl[1], hsl[2]);
      case ColorType.HSLA:
        const hsla = (color.match(/\d+\.?\d*/g) ?? []).map(Number);

        return this.hslaToHex(hsla[0], hsla[1], hsla[2], hsla[3]);
      case ColorType.HEX:
        return color.toUpperCase();
      default:
        return null;
    }
  }

  static isValidHexColor(hex: string): boolean {
    // Regex to match the hex color pattern (with or without alpha)
    const regex = /^#([0-9A-F]{3,4}){1,2}$/i;

    // Test the hex string against the regex pattern
    return regex.test(hex);
  }

  static calculateAlpha(r: number, g: number, b: number): number {
    // Calculate the "distance" from black, adjust as needed
    const distanceFromBlack = Math.sqrt(r * r + g * g + b * b) / Math.sqrt(255 * 255 * 3);

    return Math.round(distanceFromBlack * 100) / 100; // round to 2 decimal places
  }

  static binaryStringToUint8Array(binaryString: string): Uint8Array {
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  }

  static async urlToDataURL(url: string) {
    try {
      const blob = await fetch(url).then(res => res.blob());

      return URL.createObjectURL(blob);
    } catch (e) {
      return url;
    }
  }

  static async downloadImage(dataURL: string, filename?: string) {
    if (!filename) {
      // Remove the data URL prefix to only get the base64 encoded string
      const base64Content = dataURL.split(',')[1] ?? dataURL.split(',')[0];

      filename = `${CryptoJS.MD5(base64Content)}.png`;
    }

    const a = document.createElement('a');

    a.href = dataURL.startsWith('http') ? await this.urlToDataURL(dataURL) : dataURL;
    a.target = '_blank';
    a.download = filename;
    document.body.appendChild(a); // This line is needed for Firefox
    a.click();
    document.body.removeChild(a); // Clean up afterward
  }

  static getSelectionCoordinates(): { x: number; y: number } | null {
    const selection = window.getSelection();

    // Check if there is a selection
    if (!selection?.rangeCount) return null;

    // Get the first range of the selection
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    // Return the x and y coordinates
    return { x: rect.left, y: rect.top };
  }

  static viewImage(url: any, callback?: () => void): Promise<void> {
    return new Promise(resolve => {
      const rootNode =
        document.querySelector('.alchemy-root') ?? document.querySelector('#blob-root');

      const imageModalPortal = document.createElement('div');

      imageModalPortal.className = 'alchemy-image-view-portal';

      if (rootNode !== null) {
        const root = createRoot(imageModalPortal);

        root.render(
          <ImageModal
            handleDownload={() => GraphicTools.downloadImage(url)}
            handleDestroy={() => {
              root.unmount();
              if (callback) {
                callback();
              }
              resolve();
            }}
            url={url}
          />
        );

        rootNode.append(imageModalPortal);
      }
    });
  }

  static cropImage(dataUrl: string, rect: IRectangle, adjustScale = false): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const scaleX = adjustScale ? window.innerWidth / img.width : 1;
        const scaleY = adjustScale ? window.innerHeight / img.height : 1;

        const cropRect = {
          x: rect.x / scaleX,
          y: rect.y / scaleY,
          width: rect.width / scaleX,
          height: rect.height / scaleY,
        };

        const canvas = document.createElement('canvas');

        canvas.width = cropRect.width;
        canvas.height = cropRect.height;
        const ctx = canvas.getContext('2d');

        ctx?.drawImage(
          img,
          cropRect.x,
          cropRect.y,
          cropRect.width,
          cropRect.height,
          0,
          0,
          cropRect.width,
          cropRect.height
        );
        resolve(canvas.toDataURL());
      };
      img.onerror = reject;
      img.src = dataUrl;
    });
  }

  static async fileToDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  static dataURLtoBlob(dataURL: string): Blob {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURL.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  static dataURLtoFile(dataURL: string, fileName: string): File {
    const blob: Blob = this.dataURLtoBlob(dataURL);

    return new File([blob], fileName, {
      type: blob.type,
    });
  }

  static bringNumberToRange(value: number, range: INumberRange) {
    return Math.min(Math.max(value, range.min), range.max);
  }

  static getPageMetaTagContent(
    document: Document,
    attributeName: string,
    attributeValue: string
  ): string | null {
    const metaTags = document.getElementsByTagName('meta');

    for (let i = 0; i < metaTags.length; i++) {
      if (metaTags[i].getAttribute(attributeName) === attributeValue) {
        return metaTags[i].getAttribute('content');
      }
    }

    return null;
  }

  static getPagePreviewImageURL(document: Document): string | null {
    const ogImage = this.getPageMetaTagContent(document, 'property', 'og:image');
    const twitterImage = this.getPageMetaTagContent(document, 'name', 'twitter:image');
    const previewImage = ogImage || twitterImage || null;

    return previewImage;
  }

  static getPageTitle(document: Document): string | null {
    return this.getPageMetaTagContent(document, 'property', 'og:site_name');
  }

  static getPageDescription(document: Document): string | null {
    return this.getPageMetaTagContent(document, 'property', 'og:description');
  }

  static getElementSelector(element: HTMLElement) {
    // Check if the element has an ID
    if (element.id) {
      return `#${element.id}`;
    }

    // Construct a selector based on tag name and class list
    let selector = element.tagName.toLowerCase(); // Start with the tag name

    if (element.className) {
      const classes = element.className.trim().split(/\s+/);

      selector += '.' + classes.join('.');
    }

    // Check if the element is uniquely identified by the generated selector
    if (document.querySelectorAll(selector).length === 1) {
      return selector;
    }

    // If not unique, use the nth-of-type pseudo-class for uniqueness
    const parent = element.parentNode;
    const children = Array.from(parent?.children ?? []);
    const index = children.indexOf(element) + 1; // CSS is 1-indexed

    return `${selector}:nth-of-type(${index})`;
  }

  static selectionRangeIntersectsNode(range: Range, node: any) {
    const nodeRange = document.createRange();

    nodeRange.selectNodeContents(node);

    return (
      range.compareBoundaryPoints(Range.END_TO_START, nodeRange) < 0 &&
      range.compareBoundaryPoints(Range.START_TO_END, nodeRange) > 0
    );
  }

  static calculateTextHeight(
    text: string,
    fontSize: number,
    fontWeight: number,
    maxWidth: number,
    fontFamily: string
  ): number {
    /**
     * Calculate the height of the text in pixels based on the font size, font weight, constrained width, and font family.
     *
     * Parameters:
     * text (string): The text to be measured.
     * fontSize (number): The size of the font in pixels.
     * fontWeight (number): The weight of the font (100 to 900).
     * maxWidth (number): The maximum width of the text container in pixels.
     * fontFamily (string): The font family to be used.
     *
     * Returns:
     * number: The height of the text in pixels.
     */

    // Create a temporary canvas for text measurement
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;

    // Set the font on the canvas context for measurement
    context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;

    // Split the text into words
    const words = text.split(' ');
    let line = '';
    const lines: string[] = [];

    // Calculate the lines needed to fit the text within the maxWidth
    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' ';
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth && i > 0) {
        lines.push(line);
        line = words[i] + ' ';
      } else {
        line = testLine;
      }
    }
    lines.push(line);

    // Calculate the height based on the number of lines
    const numberOfLines = lines.length;
    const lineHeight = fontSize * 1.2; // Assuming a line height of 1.2 times the font size
    let textHeight = numberOfLines * lineHeight;

    // Adjust height slightly based on font weight
    let adjustmentFactor: number;

    if (fontWeight > 400) {
      adjustmentFactor = 1.05 + (fontWeight - 400) / 5000;
    } else {
      adjustmentFactor = 1.0;
    }

    textHeight *= adjustmentFactor;

    return textHeight;
  }
}
