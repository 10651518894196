import './index.scss';

import { ReactNode } from 'react';
import Highlighter from 'react-highlight-words';

export interface IDocumentsItem {
  icon: ReactNode;
  title: string;
  searchQuery?: string;
  onClick: () => void;
}

export function DocumentsItem(props: IDocumentsItem) {
  return (
    <div onClick={props.onClick} className="documents-item">
      <div className="icon-wrapper">{props.icon}</div>
      <div className="title">
        <Highlighter
          highlightClassName="alchemy-highlight"
          searchWords={[props.searchQuery ?? '']}
          autoEscape={true}
          textToHighlight={props.title}
        />
      </div>
    </div>
  );
}
