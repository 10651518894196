import { ArticleData, extract } from '@extractus/article-extractor';

import { RequestTools } from '../../../tools/request';
import { StringTools } from '../../../tools/string';

export enum WebPlatform {
  AISearch = 'ai-search',
  YouTube = 'youtube',
  Other = 'other',
  None = 'none',
  Compare = 'compare'
}
export class WebPlatformDetectionService {
  private static readonly youtubeDomains = ['www.youtube.com', 'youtube.com', 'youtu.be'];

  static getActive(url?: string): WebPlatform {
    if (this.youtubeDomains.some(domain => (url ?? window.location.hostname).includes(domain))) {
      return WebPlatform.YouTube;
    }

    return WebPlatform.Other;
  }

  static async getCurrentPageArticle(url): Promise<ArticleData | 'Unavailable'> {
    const html =
      (await RequestTools.fetchHtmlContent(
        'https://corsproxy.io/?' + encodeURIComponent(StringTools.ensureHttps(url))
      )) ?? '';

    const urlDocument = RequestTools.htmlStringToDocument(html);

    const result =
      (await extract(html)) ?? {
        title: document.title,
        content:
          document.location.href === 'https://www.google.com/'
            ? 'This page is Google startup page'
            : StringTools.truncate(urlDocument.body.innerText, 2000),
      } ??
      'Unavailable';

    console.warn(result);

    return result;
  }
}
