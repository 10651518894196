import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const LogoIcon = (props: IIcon) => {
  return (
    <Icon width="28" height="29" {...props}>
      <path
        d="M9.1181 1.86816C6.48523 1.86816 3.75034 3.92092 3.01779 6.44694L0.137103 16.3804C-0.412851 18.2767 0.740863 19.8103 2.71747 19.8103H6.58841L10.7001 13.9222L8.3207 7.08476L14.2663 11.2153L21.1216 8.86561L22.1688 5.31423C22.7298 3.41155 21.5763 1.86816 19.5931 1.86816H9.1181Z"
        fill="white"
      />
      <path
        d="M9.59045 10.7324C9.19496 11.2079 8.89276 11.7479 8.72682 12.3201L5.51978 23.3789C4.97001 25.2747 6.1244 26.8078 8.10167 26.8078H18.6813C21.2795 26.8078 23.9844 24.8087 24.7565 22.3179L27.8434 12.3605C28.439 10.4391 27.2855 8.86561 25.2813 8.86561L21.1219 8.86561L16.9736 14.7815L19.353 21.6189L13.4074 17.4884L6.58877 19.8103L10.7005 13.9222L9.59045 10.7324Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M21.1219 8.86561L13.3317 8.8656C12.7086 8.8656 12.078 9.01777 11.4888 9.28542L14.2667 11.2153L21.1219 8.86561Z"
        fill="white"
        fillOpacity="0.6"
      />
    </Icon>
  );
};
