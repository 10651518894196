import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const RocketIcon = (props: IIcon) => {
  return (
    <Icon width="24" height="25" {...props}>
      <path
        d="M13.4554 7.18323C14.9311 5.8293 16.861 5.07812 18.8637 5.07812L19.2499 5.07813V5.46426C19.2499 7.46699 18.4987 9.39688 17.1448 10.8726L10.9946 17.576L6.75195 13.3334L13.4554 7.18323Z"
        stroke="#C6B6FC"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 17.0781L4.75 19.5781"
        stroke="#C6B6FC"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 19.0781L8.75 19.5781"
        stroke="#C6B6FC"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 15.0781L4.75 15.5781"
        stroke="#C6B6FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 19.5781L14.24 14.3281L11 17.5781L13 19.5781Z"
        stroke="#C6B6FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 13.3281L10 10.0781L4.75 11.0781L6.75 13.3281Z"
        stroke="#C6B6FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
