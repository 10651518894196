import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

export interface IAuthState {
  isRedirectTo404: boolean;
  isRedirectToLogin: boolean;
  confirmationCode: string | null;
  accountAssociatedEmail: string | null;
}

const initialState: IAuthState = {
  isRedirectTo404: false,
  isRedirectToLogin: false,
  confirmationCode: null,
  accountAssociatedEmail: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRedirectTo404(state, action: PayloadAction<boolean>) {
      state.isRedirectTo404 = action.payload;
    },

    setRedirectToLogin(state, action: PayloadAction<boolean>) {
      state.isRedirectToLogin = action.payload;
    },

    clearStore(state) {
      state.isRedirectTo404 = false;
      state.isRedirectToLogin = false;
    },

    setConfirmationCode(state, action: PayloadAction<string>) {
      state.confirmationCode = action.payload;
    },

    setAccountAssociatedEmail(state, action: PayloadAction<string>) {
      state.accountAssociatedEmail = action.payload;
    },
  },
});

export const selectIsRedirectTo404 = (state: RootState) => state.auth.isRedirectTo404;
export const selectIsRedirectToLogin = (state: RootState) => state.auth.isRedirectToLogin;
export const selectConfirmationCode = (state: RootState) => state.auth.confirmationCode;
export const selectAccountAssociatedEmail = (state: RootState) => state.auth.accountAssociatedEmail;

export const authReducer = authSlice.reducer;

export const {
  setRedirectTo404,
  setRedirectToLogin,
  clearStore,
  setConfirmationCode,
  setAccountAssociatedEmail,
} = authSlice.actions;
