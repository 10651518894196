import './index.scss';

import { createRef } from 'react';

import { IDocument } from '../../../../../interfaces';
import { useOutsideClickDetector } from '../../../../../tools/events';
import { DocumentsManager } from '../../../../components/documents';
import { SmoothVisibility } from '../../../../design/components/smoothVisibility';

export interface IFloatingDocuments {
  visible: boolean;
  onReplaceExistingDocument: (document: IDocument) => void;
  onCreateNewDocument: () => void;
  onCancel: () => void;
}

export function FloatingDocuments(props: IFloatingDocuments) {
  const wrapperRef = createRef<HTMLDivElement>();

  useOutsideClickDetector(wrapperRef, () => {
    props.onCancel();
  });

  return (
    <SmoothVisibility
      visible={props.visible}
      className="alchemy-floating-documents-wrapper"
      containerRef={wrapperRef}
    >
      <DocumentsManager {...props} />
    </SmoothVisibility>
  );
}
