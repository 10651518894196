(window as any).alchemyAppMemory = new Map<string, any>();

export class TempAppMemory {
  static read(id: string): any | null {
    return (window as any)?.alchemyAppMemory?.get(id) ?? null;
  }

  static readAll(): Map<string, any> {
    return (window as any)?.alchemyAppMemory ?? new Map<string, any>();
  }

  static write(id: string, data: any): void {
    (window as any)?.alchemyAppMemory?.set(id, data);
  }
}

export enum TempAppMemoryKey {
  PromptToSend = 'prompt-to-send',
}
