export const DocumentAltIcon = () => {
  return (
    <svg
      width="14px"
      height="16px"
      viewBox="0 0 14 16"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
    >
      <g>
        <path
          d="M0.92,3.674C0.92,1.866 2.386,0.4 4.194,0.4L7.164,0.4C8.032,0.4 8.865,0.745 9.479,1.359L12.121,4.002C12.735,4.616 13.08,5.448 13.08,6.317L13.08,12.327C13.08,14.135 11.614,15.601 9.806,15.601L4.194,15.601C2.386,15.601 0.92,14.135 0.92,12.327L0.92,3.674Z"
          // fill='url(#_Linear1)'
          fill="var(--app-document-icon)"
          fillRule="nonzero"
        />

        <g>
          <g>
            <path
              d="M6.998,7.567C7.896,7.567 8.656,7.8 9.196,8.253C9.74,8.708 10.038,9.369 10.038,10.173C10.038,10.977 9.74,11.638 9.196,12.094C8.656,12.546 7.896,12.779 6.998,12.779C6.508,12.779 5.964,12.732 5.483,12.512C5.476,12.516 5.47,12.52 5.463,12.524C5.382,12.57 5.267,12.628 5.129,12.676C4.856,12.771 4.468,12.835 4.082,12.652C4.023,12.623 3.98,12.57 3.964,12.507C3.949,12.444 3.963,12.377 4.002,12.325C4.42,11.771 4.295,11.433 4.29,11.419L4.283,11.401C4.261,11.348 4.181,11.152 4.107,10.919C4.035,10.692 3.958,10.403 3.958,10.173C3.958,9.369 4.256,8.708 4.8,8.253C5.34,7.8 6.1,7.567 6.998,7.567ZM5.406,10.173C5.406,10.333 5.535,10.463 5.695,10.463C5.855,10.463 5.985,10.333 5.985,10.173C5.985,10.013 5.855,9.884 5.695,9.884C5.535,9.884 5.406,10.013 5.406,10.173ZM6.709,10.173C6.709,10.333 6.838,10.463 6.998,10.463C7.158,10.463 7.288,10.333 7.288,10.173C7.288,10.013 7.158,9.884 6.998,9.884C6.838,9.884 6.709,10.013 6.709,10.173ZM8.301,10.463C8.141,10.463 8.011,10.333 8.011,10.173C8.011,10.013 8.141,9.884 8.301,9.884C8.461,9.884 8.59,10.013 8.59,10.173C8.59,10.333 8.461,10.463 8.301,10.463Z"
              fill="white"
              fillOpacity={0.12}
            />
            <path
              d="M6.998,7.567C7.896,7.567 8.656,7.8 9.196,8.253C9.74,8.708 10.038,9.369 10.038,10.173C10.038,10.977 9.74,11.638 9.196,12.094C8.656,12.546 7.896,12.779 6.998,12.779C6.508,12.779 5.964,12.732 5.483,12.512C5.476,12.516 5.47,12.52 5.463,12.524C5.382,12.57 5.267,12.628 5.129,12.676C4.856,12.771 4.468,12.835 4.082,12.652C4.023,12.623 3.98,12.57 3.964,12.507C3.949,12.444 3.963,12.377 4.002,12.325C4.42,11.771 4.295,11.433 4.29,11.419L4.283,11.401C4.261,11.348 4.181,11.152 4.107,10.919C4.035,10.692 3.958,10.403 3.958,10.173C3.958,9.369 4.256,8.708 4.8,8.253C5.34,7.8 6.1,7.567 6.998,7.567ZM5.406,10.173C5.406,10.333 5.535,10.463 5.695,10.463C5.855,10.463 5.985,10.333 5.985,10.173C5.985,10.013 5.855,9.884 5.695,9.884C5.535,9.884 5.406,10.013 5.406,10.173ZM6.709,10.173C6.709,10.333 6.838,10.463 6.998,10.463C7.158,10.463 7.288,10.333 7.288,10.173C7.288,10.013 7.158,9.884 6.998,9.884C6.838,9.884 6.709,10.013 6.709,10.173ZM8.301,10.463C8.141,10.463 8.011,10.333 8.011,10.173C8.011,10.013 8.141,9.884 8.301,9.884C8.461,9.884 8.59,10.013 8.59,10.173C8.59,10.333 8.461,10.463 8.301,10.463Z"
              fill="url(#_Linear2)"
            />
          </g>
        </g>
        <path
          d="M12.789,4.967C12.979,5.387 13.08,5.847 13.08,6.317L13.08,12.327C13.08,14.135 11.614,15.601 9.806,15.601L4.194,15.601C2.386,15.601 0.92,14.135 0.92,12.327L0.92,3.674C0.92,1.866 2.386,0.4 4.194,0.4L7.164,0.4C7.66,0.4 8.144,0.513 8.582,0.723C8.912,0.882 9.215,1.096 9.479,1.359L12.121,4.002C12.403,4.283 12.628,4.611 12.789,4.967ZM8.607,1.189C8.172,0.937 7.675,0.8 7.164,0.8L4.194,0.8C2.607,0.8 1.32,2.087 1.32,3.674L1.32,12.327C1.32,13.914 2.607,15.201 4.194,15.201L9.806,15.201C11.393,15.201 12.68,13.914 12.68,12.327L12.68,6.317C12.68,5.835 12.559,5.367 12.335,4.951C10.381,4.775 8.818,3.191 8.607,1.189Z"
          fill="white"
          fillOpacity={0.17}
        />
      </g>
      <defs>
        <linearGradient
          id="_Linear1"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-7.98005,13.3002,-13.3002,-7.98005,10.8,2.30041)"
        >
          <stop offset="0" stopColor="white" stopOpacity={0} />
          <stop offset="1" stopColor="white" stopOpacity={0.2} />
        </linearGradient>
        <linearGradient
          id="_Linear2"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(3.21424e-16,-5.24925,5.24925,3.21424e-16,7.02422,12.7789)"
        >
          <stop offset="0" stopColor="white" stopOpacity={0.24} />
          <stop offset="1" stopColor="white" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
};
