import './CheckboxIcon.scss';

export interface ICheckboxIcon {
  checked?: boolean;
  radius?: number;
  onClick?: () => void;
  minWidth?: number;
  minHeight?: number;
}
export default function CheckboxIcon(props: ICheckboxIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      width="20"
      height="20"
      className={'checkbox-icon' + (props.checked ? ' checked' : '')}
      onClick={props.onClick}
      style={{ minWidth: props.minWidth ?? 0, minHeight: props.minHeight ?? 0 }}
    >
      <rect width="20" height="20" rx={props.radius ?? 5}></rect>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.065 5.942a.913.913 0 0 0-1.516-.093l-4.848 6.363-2.344-2.344-.01-.01a.914.914 0 0 0-1.292 1.292l3.093 3.093a.912.912 0 0 0 1.374-.092l5.481-7.194a.913.913 0 0 0 .062-1.015Zm-1.198.15Zm-5.125 6.726-2.668-2.667 2.668 2.667Zm6.031-6.284a.51.51 0 0 1-.003.012l.003-.012Z"
        fill="#fff"
      ></path>
    </svg>
  );
}
