export const PromptLibraryIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3.56384'
        y='5.9585'
        width='15.5833'
        height='10.0833'
        rx='1.83333'
        stroke='var(--color-titanium-200)'
        strokeWidth='1.65'
      />
      <path
        d='M7.06519 12.5142H15.643'
        stroke='var(--color-titanium-200)'
        strokeWidth='1.65'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.06519 9.48486L11.1902 9.48486'
        stroke='var(--color-titanium-200)'
        strokeWidth='1.65'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
