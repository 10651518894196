export interface IYouTubeIcon {
  color?: string;
}
export default function YouTubeIcon(props: IYouTubeIcon) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.4562 7.21686C18.3505 6.1965 18.1231 5.06853 17.2861 4.47575C16.6379 4.01607 15.7858 3.99909 14.9903 4.00003C13.3087 4.00098 11.6262 4.00286 9.9447 4.0038C8.32731 4.00569 6.70993 4.00664 5.09255 4.00852C4.41691 4.00947 3.76014 3.9566 3.13263 4.24921C2.59382 4.50029 2.17201 4.97792 1.91818 5.50934C1.5662 6.24842 1.49261 7.08566 1.45014 7.90309C1.37182 9.39163 1.38031 10.8839 1.47373 12.3715C1.54262 13.457 1.71719 14.6567 2.55607 15.3486C3.29965 15.9612 4.34897 15.9914 5.31335 15.9924C8.37449 15.9952 11.4366 15.998 14.4987 15.9999C14.8912 16.0009 15.3007 15.9933 15.7008 15.9499C16.4878 15.8649 17.238 15.6394 17.7438 15.056C18.2543 14.468 18.3855 13.6496 18.4628 12.8746C18.6516 10.9944 18.6497 9.09618 18.4562 7.21686ZM8.17445 12.6358V7.36411L12.7378 9.9995L8.17445 12.6358Z'
        fill={props.color ?? 'rgba(152, 152, 152, 1)'}
      />
    </svg>
  );
}
