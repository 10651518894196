import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

export interface ISubscriptionState {
  isRedirectToUpgradeSubscriptionPage: boolean;
}

const initialState: ISubscriptionState = {
  isRedirectToUpgradeSubscriptionPage: false,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setIsRedirectToUpgradeSubscriptionPage(state, action: PayloadAction<boolean>) {
      state.isRedirectToUpgradeSubscriptionPage = action.payload;
    },
  },
});

export const selectIsRedirectToUpgradeSubscriptionPage = (state: RootState) =>
  state.subscription.isRedirectToUpgradeSubscriptionPage;

export const { setIsRedirectToUpgradeSubscriptionPage } = subscriptionSlice.actions;
