import './index.scss';

import { createRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { ExtensionEvents } from '../../../../service/events';
import { ExtensionEventType } from '../../../../service/events/types';
import { useOutsideClickDetector } from '../../../../tools/events';
import { CloseIcon } from '../../assets/svg/icons/CloseIcon';
import { DropdownIcon } from '../../assets/svg/icons/DropdownIcon';
import { Dropdown, IDropdownGenericItem } from '../dropdown';
import { ItemsSwitch } from '../itemsSwitch';
import { ISwitchItem } from '../itemsSwitch/components/switchItem';
import { SmoothVisibility } from '../smoothVisibility';

export interface IModal {
  visible?: boolean;
  children?: ReactNode;
  className?: string;
  title?: string | ReactNode;
  hasDropdown?: boolean;
  dropdownItems?: IDropdownGenericItem[] | undefined;
  onClose?: () => void;
  hasCloseButton?: boolean;
  onTabChange?: (tab?: ISwitchItem['value']) => void;
  tab?: ISwitchItem['value'];
  tabs?: ISwitchItem[];
  disableClickOutside?: boolean;
  theme?: 'default' | 'blob';
}
export function Modal({ hasCloseButton = false, ...props }: IModal) {
  const modalContentRef = createRef<HTMLDivElement>();

  useOutsideClickDetector(modalContentRef, e => {
    // closest('.tippy-box') for compatability with prompt creation
    if (props.disableClickOutside) return;
    if (
      !e.target.closest('.modal') &&
      !e.target.closest('.tippy-box') &&
      !e.target.closest('.select-category-dropdown')
    ) {
      onClose();
    }
  });

  const onClose = () => {
    ExtensionEvents.dispatch(ExtensionEventType.StopAllVoiceInputs);
    props.onClose?.();
  };

  useHotkeys(
    'esc',
    () => {
      if (props.visible) {
        onClose();
      }
    },
    [props.visible]
  );

  const isTextTitle = typeof props.title === 'string';

  return createPortal(
    <SmoothVisibility
      className={
        'alchemy-modal-wrapper' +
        (props.theme === 'blob' ? ' blob-theme' : '') +
        (props.className ? ' ' + props.className : '')
      }
      visible={props.visible ?? false}
    >
      <div className="alchemy-modal" ref={modalContentRef}>
        <header>
          {isTextTitle && <h2>{props.title}</h2>}
          {!isTextTitle && <>{props.title}</>}
          {props.tabs && (
            <ItemsSwitch
              value={props?.tab}
              onChange={value => {
                props.onTabChange?.(value);
              }}
              items={[...props.tabs]}
            />
          )}
          {hasCloseButton && (
            <button
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </button>
          )}
        </header>
        {props.children}
        {props.hasDropdown && (
          <div className="dropdown-button">
            <Dropdown
              disabled={false}
              items={props.dropdownItems}
              verticalPosition="bottom"
              horizontalPosition="right"
            >
              <DropdownIcon />
            </Dropdown>
          </div>
        )}
      </div>
    </SmoothVisibility>,
    document?.querySelector('#root')
  );
}
