import { useEffect } from 'react';

import Emitter, { EMITTER_EVENTS } from '../utils/eventEmitter';

interface IUseEmitter {
  emitterEventsName: EMITTER_EVENTS;
  func?: (obj: { [key: string]: any }) => void;
}

const useEmitter = ({ emitterEventsName, func }: IUseEmitter) => {
  useEffect(() => {
    Emitter.on(emitterEventsName, value => {
      func && func(value);
    });

    return () => {
      Emitter.off(emitterEventsName);
    };
  }, []);
};

export default useEmitter;
