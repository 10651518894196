export interface IArrowRightSmoothIcon {
  color?: string;
}
export default function ArrowRightSmoothIcon(props: IArrowRightSmoothIcon) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 17 17'
      fill='none'
    >
      <path
        d='M6.83203 6.50065L9.16536 8.66732L6.83203 10.834'
        stroke={props.color ?? 'currentColor'}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
