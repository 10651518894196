import './PromptContentField.scss';

import Mention from '@tiptap/extension-mention';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { FC } from 'react';

import { ICustomInput } from '../../../../models/IPromptLibrary';

type Props = {
  promptContent: string;
};

export const PromptContentField: FC<Props> = ({ promptContent }) => {
  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Mention.configure({
          HTMLAttributes: {
            class: 'hashtag',
          },
          suggestion: {
            char: '#',
          },
        }),
      ],
      content: promptContent ? promptContent : '',
      editable: false,
    },
    [promptContent]
  );

  if (!editor) {
    return null;
  }

  return <EditorContent editor={editor} />;
};

export const getIsWordMatchesHashtag = (hashtags: ICustomInput[], word: string) => {
  return hashtags?.some(hashtag => hashtag.variable_name === word);
};
