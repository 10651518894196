import { IIcon } from './interfaces';

export const Icon = (props: IIcon) => {
  const {
    ariaLabel,
    className,
    width,
    height,
    viewBox,
    children,
    fill = 'none',
    ...rest
  } = props;
  const viewBoxResult = viewBox || `0 0 ${width} ${height}`;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox={viewBoxResult}
      className={className}
      aria-label={ariaLabel}
      fill={fill}
      {...rest}
    >
      {children}
    </svg>
  );
};
