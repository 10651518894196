import './index.scss';

import { createRef, useEffect, useState } from 'react';

import DownloadIconAlt from '../../../ui/design/assets/svg/icons/DownloadIconAlt';
import { Modal } from '../../../ui/design/components/modal';
import { useOutsideClickDetector } from '../../events';

export interface IImageModal {
  url: any;
  handleDestroy: () => void;
  handleDownload: () => void;
}
export function ImageModal(props: IImageModal) {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(true);
  }, [props.url]);

  const galleryImageRef = createRef<HTMLImageElement>();

  useOutsideClickDetector(galleryImageRef, () => {
    setVisible(false);

    setTimeout(() => {
      props.handleDestroy();
    }, 500);
  });

  return (
    <Modal
      hasCloseButton={true}
      className={'alchemy-image-modal'}
      visible={visible}
      onClose={() => {
        setVisible(false);

        setTimeout(() => {
          props.handleDestroy();
        }, 500);
      }}
      title={
        <div className="header-actions">
          <button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              props.handleDownload();
            }}
          >
            <DownloadIconAlt />
          </button>
        </div>
      }
    >
      <div className="gallery-wrapper">
        <div className="gallery">
          <div className="active-item">
            <img ref={galleryImageRef} src={props.url} alt="gallery" />
          </div>
        </div>
      </div>
    </Modal>
  );
}
