export default function ScreenshotIcon() {
  return (
    <svg
      width='22'
      height='23'
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='alchemy-screenshot-icon'
    >
      <mask
        id='mask0_3819_33580'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='22'
        height='23'
      >
        <rect y='0.5' width='22' height='22' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_3819_33580)'>
        <path
          d='M15.584 20.6673V17.9173H12.834V16.084H15.584V13.334H17.4173V16.084H20.1673V17.9173H17.4173V20.6673H15.584ZM4.58398 17.9173V13.334H6.41732V16.084H9.16732V17.9173H4.58398ZM4.58398 9.66732V5.08398H9.16732V6.91732H6.41732V9.66732H4.58398ZM15.584 9.66732V6.91732H12.834V5.08398H17.4173V9.66732H15.584Z'
          fill='#989898'
        />
      </g>
    </svg>
  );
}
