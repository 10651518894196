export const usePairedInputsData = isGoogleAuthenticate => {
  return [
    {
      id: 1,
      title: 'Personal Information',
      firstInput: {
        label: 'First Name',
        name: 'first_name',
        type: 'text',
        placeholder: 'Name',
      },
      secondInput: {
        label: 'Last Name',
        name: 'last_name',
        type: 'text',
        placeholder: 'Last name',
      },
    },
    {
      id: 2,
      title: 'E-mail Information',
      firstInput: {
        label: 'Current email address',
        name: 'email',
        type: 'email',
        placeholder: 'Email',
        autoComplete: 'off',
        isDisabled: true,
      },
      secondInput: {
        label: 'New email address',
        name: 'newEmail',
        type: 'email',
        autoComplete: 'off',
        isVisible: !isGoogleAuthenticate,
      },
    },
    {
      id: 3,
      title: 'Password Information',
      firstInput: {
        label: 'New Password',
        name: 'new_password',
        type: 'password',
        placeholder: 'Password',
        autoComplete: 'new-password',
        isVisible: !isGoogleAuthenticate,
      },
      secondInput: {
        label: 'Confirm Password',
        name: 'confirmPassword',
        type: 'password',
        autoComplete: 'new-password',
        isVisible: !isGoogleAuthenticate,
      },
    },
  ];
};
