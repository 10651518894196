import { ICategory } from './IShared';

interface ICreator {
  pk: number;
  avatar: string | null;
  username: string;
}

export interface IPlugin {
  id: number;
  name: string;
  link: string;
}

export interface IPromptInput {
  variable_name: string;
  placeholder: string;
  is_textarea: boolean;
}

export interface IPromptStep {
  step_number: number;
  prompt_template: string;
  prompt_markdown_template: string;
}

export interface IPrompt {
  amount_of_lookups: number;
  categories: ICategory[];
  click_amount: number;
  created_at: string;
  creator: ICreator;
  creator_id: number;
  description: string;
  preview_description: string;
  id: number;
  inputs: IPromptInput[];
  is_favourite: boolean;
  is_liked: boolean;
  like_amount: number;
  name: string;
  prompt_template: string;
  editable?: boolean;
  is_in_command: boolean;
  has_plugins?: boolean;
  need_bing?: boolean;
  plugins?: IPlugin[];
  chain_prompt: boolean;
  is_playground: boolean;
  is_sub_category: number;
  category_priority: number;
  is_zero_priority: number;
  steps: IPromptStep[];
}

export interface ISetFavoriteRequest {
  prompt_id: number;
  favourite: boolean;
}

export interface ITag {
  id: number;
  variable_name: string;
}

export interface ICreateCustomPromptStep {
  step_number: number,
  prompt_template: string;
  prompt_markdown_template: string;
  user_prompt?: number;
}

export interface ICreateCustomPromptRequest {
  categories: number[];
  user: number;
  name: string;
  description: string;
  prompt_template?: string;
  prompt_markdown_template?: string;
  chain_prompt?: boolean;
}

export interface ICreateCustomPromptRes {
  id: number;
  user: number;
  categories: number[];
  name: string;
  description: string;
  prompt_template: string;
  prompt_markdown_template: string;
  is_public: boolean;
  created_at: string;
  click_amount: number;
  amount_of_lookups: number;
  chain_prompt: boolean;
  access_users: [];
}

export interface ICreateCustomPromptInputField {
  variable_name: string;
  placeholder: string;
  user_prompt?: number;
}

export interface IUpdateCustomPromptRequest {
  categories: number[];
  user: number;
  name: string;
  description: string;
  prompt_template?: string;
  prompt_markdown_template?: string;
  steps?: number[];
  input_ids: number[];
  chain_prompt?: boolean;
}

export interface IUpdateCustomPrompt {
  id: number;
  body: IUpdateCustomPromptRequest;
}

export interface ICustomInput {
  id: number;
  variable_name: string;
  placeholder: string;
  is_textarea: boolean;
}

export interface ICustomPrompt {
  id: number;
  user: {
    pk: number;
    avatar: string;
    username: string;
    first_name: string;
    last_name: string;
  };
  categories: ICategory[];
  inputs: ICustomInput[];
  editable: boolean;
  name: string;
  description: string;
  prompt_template: string;
  prompt_markdown_template: string;
  is_public: boolean;
  created_at: string;
  click_amount: number;
  amount_of_lookups: number;
  is_in_command: boolean;
  steps: ICreateCustomPromptStep[];
  chain_prompt: boolean;
}

export enum CustomPromptTypes {
  single = 'single',
  chain = 'chain',
}

export interface IUpdateCustomPromptClicksAndLookups {
  prompt_extension_pk: number;
}

export interface IResponseUpdateCustomPromptClicksAndLookups {
  status: string;
}

export interface IAddToCommand {
  prompt_pk: number;
  prompt_type: number;
  command: string;
  is_add: boolean;
}
