export interface IFolderIcon {
  color?: string;
}

export default function FolderIcon(props: IFolderIcon) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0416 14.3749V8.12492C16.0416 7.20444 15.2955 6.45825 14.375 6.45825H3.95831V14.3749C3.95831 15.2954 4.7045 16.0416 5.62498 16.0416H14.375C15.2955 16.0416 16.0416 15.2954 16.0416 14.3749Z'
        stroke={props.color ?? 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 6.24992L10.4737 4.82684C10.1817 4.29139 9.62048 3.95825 9.01056 3.95825H5.62498C4.7045 3.95825 3.95831 4.70444 3.95831 5.62492V9.16659'
        stroke={props.color ?? 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
