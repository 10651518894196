export default function PasswordEyeIconCrossedOut() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ fill: 'none' }}
    >
      <path
        d='M15.5207 8.33334C15.8873 9.08217 16.0418 9.72876 16.0418 10C16.0418 10.8333 14.5835 15.2083 10.0002 15.2083C9.39066 15.2083 8.83641 15.131 8.3335 14.9948M5.8335 13.5572C4.46857 12.2244 3.9585 10.4928 3.9585 10C3.9585 9.16667 5.41683 4.79167 10.0002 4.79167C11.4957 4.79167 12.6586 5.25752 13.5467 5.91416'
        stroke='#62696F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.0418 3.95833L3.9585 16.0417'
        stroke='#62696F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.67417 11.3258C7.94194 10.5936 7.94194 9.40642 8.67417 8.67417C9.40642 7.94192 10.5936 7.94192 11.3258 8.67417'
        stroke='#62696F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
