interface ICloseIconAlt {
  color?: string;
  size?: number;
}

export const CloseIconAlt = (props: ICloseIconAlt) => {
  return (
    <svg
      width={props.size ?? 21}
      height={props.size ?? 21}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.7422 6.84961L6.74219 14.8496M6.74219 6.84961L14.7422 14.8496'
        stroke={props.color ?? 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
