import React, { FC } from 'react';

import { EditIcon } from '../../../assets/svg/EditIcon';
import { TrashIcon } from '../../../assets/svg/TrashIcon';
import { setModal } from '../../../components/Modal/ModalSlice';
import { ModalTypes } from '../../../components/Modal/types/enums/ModalTypes';
import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import { ISnippetCard } from '../types';
import styles from './SnippetCard.module.scss';

export const SnippetCard: FC<ISnippetCard> = React.memo(({ id, text, code, onUpdateSnippet }) => {
  const dispatch = useTypedDispatch();

  return (
    <div className={styles.snippetCard}>
      <div className={styles.snippetCardHashnameAndBtns}>
        <div className={styles.snippetCardHashname}>#{code}</div>
        <div className={styles.snippetCardBtns}>
          <button onClick={() => onUpdateSnippet(id)}>
            <EditIcon />
          </button>
          <button
            onClick={() =>
              dispatch(setModal({ modalType: ModalTypes.DELETE_SNIPPET_MODAL, modalProps: { id } }))
            }
          >
            <TrashIcon />
          </button>
        </div>
      </div>
      <div className={styles.snippetCardText}>{text}</div>
    </div>
  );
});

SnippetCard.displayName = 'SnippetCard';
