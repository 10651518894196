import { useEffect } from 'react';

import { setLoading } from '../redux/Global';
import { useTypedDispatch } from './useTypedDispatch';

export const useLoading = (isLoading: boolean) => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading]);
};
