import Skeleton from 'react-loading-skeleton';

import styles from './SnippetCard.module.scss';

export const SnippetSkeleton = () => {
  return (
    <>
      {[1, 2, 3].map(item => (
        <div className={styles.skeletonWrapper} key={item}>
          <Skeleton className={styles.skeletonKey} />
          <Skeleton className={styles.skeletonText} />
          <Skeleton className={styles.skeletonShortText} />
        </div>
      ))}
    </>
  );
};
