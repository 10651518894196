import * as Yup from 'yup';

export const validationSchema = Yup.object({
  title: Yup.string().required('Enter the title'),
  description: Yup.string().required('Enter the description'),
  prompt: Yup.string().when('prompt_type', {
    is: 'single',
    then: schema => schema.required('Enter the prompt'),
    otherwise: schema => schema.notRequired(),
  }),
  category: Yup.array().length(1, 'You must select one category').required('Enter the category'),
  prompt_type: Yup.string().required('Required'),
});
