export interface IBlobSidebarSnippetsIcon {
  style?: React.CSSProperties;
  size?: number;
}

export default function BlobSidebarSnippetsIcon({
  size = 24,
  ...props
}: IBlobSidebarSnippetsIcon) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={props.style}
    >
      <path
        d='M10.25 4.75L7.75 19.25'
        stroke='currentColor'
        strokeWidth='1.63636'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.25 4.75L13.75 19.25'
        stroke='currentColor'
        strokeWidth='1.63636'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.25 8.75H5.75'
        stroke='currentColor'
        strokeWidth='1.63636'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.25 15.25H4.75'
        stroke='currentColor'
        strokeWidth='1.63636'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
