import { createContext, useContext } from 'react';

import { ExtensionService } from '..';

export const ExtensionServiceContext = createContext<ExtensionService>(
  new ExtensionService({ token: null, tier: null })
);

export const useExtensionServiceContext = () =>
  useContext<ExtensionService>(ExtensionServiceContext);
