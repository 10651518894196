import { googleLogout } from '@react-oauth/google';

import { clearStore } from '../redux/Auth';
import { useLazyLogoutQuery } from '../services/auth';
import { errorMessages } from '../services/errors';
import { PAGES_ENUM } from '../utils/constants';
import { handleError } from '../utils/notifications';
import { useTypedDispatch } from './useTypedDispatch';

export const useLogOut = () => {
  const dispatch = useTypedDispatch();
  const [logout] = useLazyLogoutQuery();

  const onLogout = async (isGoogleLogout?: boolean, noQuery = false) => {
    try {
      if (!noQuery) await logout().unwrap();

      if (isGoogleLogout) {
        googleLogout();
      }
      localStorage.clear();
      sessionStorage.clear();
      dispatch(clearStore());
      window.location.href = PAGES_ENUM.SIGN_IN;
    } catch (error) {
      handleError(errorMessages.LOG_OUT_ERROR);
      console.error('Logout failed', error);
    }
  };

  const onGoogleLogout = async () => {
    await onLogout(true);
  };

  return { onLogout, onGoogleLogout };
};
