import './index.scss';

export interface ICircleLoader {
  progress: number;
}
export function CircleLoader(props: ICircleLoader) {
  return (
    <svg
      className={'alchemy-circle-loader'}
      width='20'
      height='20'
      viewBox='0 0 160 160'
    >
      <g transform='rotate(0 0 0)'>
        <circle
          className='alchemy-progress-bg'
          r='70'
          cx='80'
          cy='80'
          fill='transparent'
          stroke='rgba(102, 51, 238,.3)'
          strokeWidth='20px'
        ></circle>
        <circle
          className='alchemy-progress'
          r='70'
          cx='80'
          cy='80'
          stroke='white'
          strokeLinecap='round'
          fill='transparent'
          strokeWidth='20px'
          strokeDasharray='439.6px'
          strokeDashoffset={439.6 * ((100 - props.progress) / 100) + 'px'}
        ></circle>
      </g>
    </svg>
  );
}
