export default function GoogleIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Social icon" clipPath="url(#clip0_9036_46301)">
        <path
          id="Vector"
          d="M23.7682 12.2763C23.7682 11.4605 23.7021 10.6404 23.561 9.83789H12.2422V14.4589H18.7239C18.455 15.9492 17.5907 17.2676 16.3252 18.1054V21.1037H20.1922C22.463 19.0137 23.7682 15.9272 23.7682 12.2763Z"
          fill="#4285F4"
        />
        <path
          id="Vector_2"
          d="M12.2391 24.0013C15.4756 24.0013 18.205 22.9387 20.1936 21.1044L16.3266 18.106C15.2507 18.838 13.8618 19.2525 12.2435 19.2525C9.11291 19.2525 6.45849 17.1404 5.50607 14.3008H1.51562V17.3917C3.55274 21.4439 7.70192 24.0013 12.2391 24.0013Z"
          fill="#34A853"
        />
        <path
          id="Vector_3"
          d="M5.50473 14.3007C5.00206 12.8103 5.00206 11.1965 5.50473 9.70618V6.61523H1.51869C-0.183313 10.006 -0.183313 14.0009 1.51869 17.3916L5.50473 14.3007Z"
          fill="#FBBC04"
        />
        <path
          id="Vector_4"
          d="M12.2391 4.74966C13.9499 4.7232 15.6034 5.36697 16.8425 6.54867L20.2685 3.12262C18.0991 1.0855 15.2198 -0.034466 12.2391 0.000808666C7.70192 0.000808666 3.55274 2.55822 1.51562 6.61481L5.50166 9.70575C6.44967 6.86173 9.1085 4.74966 12.2391 4.74966Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_9036_46301">
          <rect width="24" height="24" fill="var(--app-light)" />
        </clipPath>
      </defs>
    </svg>
  );
}
