import { CSSProperties } from 'react';

export interface IRefreshIcon {
  rotate?: boolean;
  color?: string;
  size?: number;
  style?: CSSProperties;
}
export default function RefreshIcon({ size = 24, ...props }: IRefreshIcon) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      style={props.style}
      xmlns='http://www.w3.org/2000/svg'
      className={`refresh-icon${props.rotate ? ' rotate' : ''}`}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 2.5C5.05228 2.5 5.5 2.94772 5.5 3.5V5.07196C7.19872 3.47759 9.48483 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C7.1307 21.5 3.11828 17.8375 2.565 13.1164C2.50071 12.5679 2.89327 12.0711 3.4418 12.0068C3.99033 11.9425 4.48712 12.3351 4.5514 12.8836C4.98798 16.6089 8.15708 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C9.7796 4.5 7.7836 5.46469 6.40954 7H9C9.55228 7 10 7.44772 10 8C10 8.55228 9.55228 9 9 9H4.5C3.96064 9 3.52101 8.57299 3.50073 8.03859C3.49983 8.01771 3.49958 7.99677 3.5 7.9758V3.5C3.5 2.94772 3.94771 2.5 4.5 2.5Z'
        fill='currentColor'
      ></path>
    </svg>
  );
}
