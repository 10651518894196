import './index.scss';

import { useEffect, useState } from 'react';

import { IDocument } from '../../../interfaces';
import { useExtensionServiceContext } from '../../../service/context';
import { useAsyncProcessManagerContext } from '../../../tools/async/context';
import { useDebounce } from '../../../tools/events';
import { DocumentAltIcon } from '../../design/assets/svg/icons/DocumentAltIcon';
import DocumentPlusIcon from '../../design/assets/svg/icons/DocumentPlusIcon';
import { SmoothVisibility } from '../../design/components/smoothVisibility';
import { DocumentsItem } from './components/item';
import { DocumentsSearch } from './components/search';

export interface IDocumentsManager {
  visible: boolean;
  onReplaceExistingDocument: (document: IDocument) => void;
  onCreateNewDocument: () => void;
}

export function DocumentsManager(props: IDocumentsManager) {
  const asyncProcessManager = useAsyncProcessManagerContext();
  const extensionService = useExtensionServiceContext();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const [documents, setDocuments] = useState<IDocument[] | null>(null);

  const refreshDocuments = () => {
    asyncProcessManager?.doProcess({
      name: 'Fetch documents',
      action: async () => {
        const newDocuments =
          (await extensionService.getRecentDocuments({
            search: searchQuery,
          })) ?? null;

        setDocuments(newDocuments);
      },
    });
  };

  useEffect(() => {
    if (!props.visible) return;
    refreshDocuments();
  }, [props.visible]);

  useDebounce(() => {
    refreshDocuments();
  }, [searchQuery]);

  return (
    <div className="alchemy-documents-manager">
      <DocumentsSearch query={searchQuery} setQuery={setSearchQuery} />
      <DocumentsItem
        onClick={props.onCreateNewDocument}
        icon={<DocumentPlusIcon />}
        title="Create new document"
      />
      <SmoothVisibility
        visible={!!documents && documents.length > 0}
        as="span"
        className="text-label"
      >
        Recent documents
      </SmoothVisibility>

      <div className="documents">
        {documents?.map(document => (
          <DocumentsItem
            onClick={() => props.onReplaceExistingDocument(document)}
            icon={<DocumentAltIcon />}
            title={document.name ?? ''}
            key={document.id}
            searchQuery={searchQuery}
          />
        ))}
      </div>
    </div>
  );
}
