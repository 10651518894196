export default function PasswordEyeIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0416 10C16.0416 10.8333 14.5833 15.2083 9.99992 15.2083C5.41659 15.2083 3.95825 10.8333 3.95825 10C3.95825 9.16667 5.41659 4.79167 9.99992 4.79167C14.5833 4.79167 16.0416 9.16667 16.0416 10Z'
        stroke='#62696F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 11.875C11.0355 11.875 11.875 11.0355 11.875 10C11.875 8.96447 11.0355 8.125 10 8.125C8.96447 8.125 8.125 8.96447 8.125 10C8.125 11.0355 8.96447 11.875 10 11.875Z'
        stroke='#62696F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
