import AlchemyLogo from '../../../../design/assets/svg/AlchemyLogo';
import { AlchemyLogoWithoutText } from '../../../../design/assets/svg/icons/AlchemyLogoWithoutText';
import GoogleIcon from '../../../../design/assets/svg/icons/GoogleIcon';

export interface IAuthBlockHeader {
  title: string;
  subTitle: string;
  googleButton?: {
    title: string;
    onClick: () => void;
  };
  isDashboard?: boolean;
  isBlob?: boolean;
  size?: 'default' | 'mini';
}
export function AuthBlockHeader({ size = 'default', ...props }: IAuthBlockHeader) {
  return (
    <div className="auth-block-header">
      {size !== 'mini' && (
        <>
          {!props.isDashboard && (
            <div className="wrapper-logo-and-button">
              <div className="auth-logo">
                {!props.isBlob ? <AlchemyLogo /> : <AlchemyLogoWithoutText />}
              </div>
            </div>
          )}

          <h2>{props.title}</h2>
          <p className="block-subtitle">{props.subTitle}</p>
        </>
      )}

      {props.googleButton && (
        <>
          <div className="google-button-wrapper">
            <button onClick={props.googleButton?.onClick} className="google-button">
              <GoogleIcon />
              {props.googleButton.title}
            </button>
          </div>
          <div className="or-block">
            <div className="text">OR</div>
          </div>
        </>
      )}
    </div>
  );
}
