import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const PhotoIcon = (props: IIcon) => {
  return (
    <Icon width="24" height="24" {...props}>
      <path
        d="M17.75 5.25H7.25C6.14543 5.25 5.25 6.14543 5.25 7.25V17.75C5.25 18.8546 6.14543 19.75 7.25 19.75H17.75C18.8546 19.75 19.75 18.8546 19.75 17.75V7.25C19.75 6.14543 18.8546 5.25 17.75 5.25Z"
        stroke="#D8DADB"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 16.5004L7.99619 13.0071C8.7749 12.0166 10.2645 11.9841 11.0856 12.9399L13.5 15.7504"
        stroke="#D8DADB"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.415 13.3234C12.4522 12.0041 13.8973 10.135 13.9913 10.0134L14.0013 10.0006C14.7814 9.01642 16.2663 8.98625 17.0856 9.93991L19.5 12.7504"
        stroke="#D8DADB"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
