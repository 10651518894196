import { useEffect, useMemo, useState } from 'react';

import { DocumentCard } from '../../components/DocumentCard';
import { EmptyPageAlert } from '../../components/EmptyPageAlert';
import { MainContentLayout } from '../../components/Layouts/MainContentLayout';
import { MyContentLayout } from '../../components/Layouts/MyContentLayout';
import useEmitter from '../../hooks/useEmitter';
import { useGetTrashDocumentsQuery } from '../../services/myContent';
import { EMITTER_EVENTS } from '../../utils/eventEmitter';
import { searchDocuments } from '../../utils/helpers';
import { DocumentContent } from '../MyContentPage/DocumentContent';
import styles from './TrashPage.module.scss';

export const TrashPage = () => {
  const { data, isLoading: isLoadingDocuments } = useGetTrashDocumentsQuery();
  const [query, setQuery] = useState('');
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const documentsData = data?.documents;
  const lastModifiedDocument = documentsData?.[0];
  const lastModifiedDocumentId = lastModifiedDocument?.id;

  const selectedDocument = useMemo(
    () => documentsData?.find(document => document.id === selectedDocumentId),
    [selectedDocumentId, documentsData]
  );

  const documentsFilteredByQuery = useMemo(() => {
    return searchDocuments(documentsData, query);
  }, [documentsData, query]);

  useEffect(() => {
    if (documentsFilteredByQuery?.length > 0 && query) {
      setSelectedDocumentId(documentsFilteredByQuery[0].id);
    }
  }, [documentsFilteredByQuery, query]);

  const onChange = (query: string) => {
    setQuery(query?.trim());
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> DELETE DOCUMENT START <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  useEmitter({
    emitterEventsName: EMITTER_EVENTS.MODAL_DELETE_DOCUMENT,
    func: () => {
      setSelectedDocumentId(null);
    },
  });

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> DELETE DOCUMENT END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const cardList = documentsFilteredByQuery?.map(card => {
    const { id } = card || {};

    return (
      <DocumentCard
        key={id}
        {...card}
        isSelected={
          id === selectedDocumentId || (id === lastModifiedDocumentId && !selectedDocumentId)
        }
        onClick={() => setSelectedDocumentId(id)}
        query={query}
        isDeleted
      />
    );
  });

  const cardDetail =
    selectedDocument || lastModifiedDocument ? (
      <DocumentContent
        key={selectedDocumentId || lastModifiedDocumentId}
        documentContent={selectedDocument || lastModifiedDocument}
        query={query}
        isDeleted={true}
      />
    ) : null;

  return (
    <MainContentLayout
      title="Trash"
      searchBarName="trash"
      searchBarPlaceholder="Search content"
      isSearchDisabled={isLoadingDocuments}
      onChange={onChange}
    >
      {documentsFilteredByQuery?.length > 0 || isLoadingDocuments ? (
        <MyContentLayout
          leftContent={cardList}
          rightContent={cardDetail}
          isLoading={isLoadingDocuments}
        />
      ) : (
        <EmptyPageAlert title="No items available" className={styles.alert} />
      )}
    </MainContentLayout>
  );
};
