import toast from 'react-hot-toast';

export type ErrorNode = {
  data: {
    message: string;
    statusCode: number;
  };
  status: number | string;
  originalStatus?: number;
};

export const handleError = (err: string) => toast.error(err);
export const handleInfo = (info: string) =>
  toast.success(info, {
    icon: '',
  });

export const handleSuccess = (success: string) => toast.success(success);
