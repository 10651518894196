/* eslint-disable @typescript-eslint/no-redeclare */
import { useEffect } from 'react';

import { ExtensionEventType } from './types';

type NativeEvent = CustomEvent;
const NativeEvent = CustomEvent;

export interface IExtensionEvent {
  type: ExtensionEventType | string;
  data: any;
}

export interface IEventListener {
  type: ExtensionEventType | string;
  handler: (event: IExtensionEvent) => void;
}

export class EventListener {
  type: ExtensionEventType | string;
  nativeType: string;
  nativeHandler: (e: Event) => void;

  constructor({ type, handler }: IEventListener) {
    this.type = type;
    this.nativeType = ExtensionEvents.createNativeEventType(type);
    this.nativeHandler = (e: Event) => {
      handler({
        type,
        data: (e as any)?.detail,
      });
    };
  }

  init() {
    document.addEventListener(this.nativeType, this.nativeHandler);
  }

  destroy() {
    document.removeEventListener(this.nativeType, this.nativeHandler);
  }
}

export class ExtensionEvents {
  static createNativeEventType(type: string): string {
    return 'alchemy-event-' + type;
  }

  static createNativeEvent(event: IExtensionEvent): NativeEvent {
    const nativeEventType = this.createNativeEventType(event.type);

    const nativeEvent = new NativeEvent(nativeEventType, {
      detail: event.data,
    });

    return nativeEvent;
  }

  static dispatch(type: ExtensionEventType | string, data?: any) {
    const nativeEvent = this.createNativeEvent({ type, data });

    document.dispatchEvent(nativeEvent);
  }

  static on(
    type: ExtensionEventType | string,
    handler: (event: IExtensionEvent) => void
  ): EventListener {
    const listener = new EventListener({ type, handler });

    listener.init();

    return listener;
  }

  static stopNativeEventPropagation(target: Element | ShadowRoot, events: string | string[]) {
    if (Array.isArray(events)) {
      events.forEach(event => target.addEventListener(event, e => e.stopPropagation()));
    } else {
      target.addEventListener(events, e => e.stopPropagation());
    }
  }
}

export const useExtensionEventListener = (
  type: ExtensionEventType | string,
  handler: (event: IExtensionEvent) => void,
  deps: React.DependencyList = []
): void => {
  useEffect(() => {
    const listener = ExtensionEvents.on(type, handler);

    return () => listener.destroy();
  }, [...deps]);
};
