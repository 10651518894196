export enum SUBSCRIPTION_TYPE {
  MONTH = '1',
  YEAR = '2',
}

export enum SUBSCRIPTION_TIER {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
}

export interface ISubscriptionLevel {
  subscription_level: number;
  hide_style_button: boolean;
  hide_tone_button: boolean;
  hide_ai_recommendations: boolean;
  trial_activated: boolean;
  subscription_type: number;
}

export interface ICreatePremiumSubscriptionRequest {
  type: SUBSCRIPTION_TYPE;
  stripeToken: string;
  tier: SUBSCRIPTION_TIER;
}

export interface ICreatePremiumSubscriptionResponse {
  id: string;
}
