import { FC } from 'react';

import { EyeIcon } from '../../../../assets/svg/EyeIcon';
import { UsageIcon } from '../../../../assets/svg/UsageIcon';
import styles from './UsageAndViewBadges.module.scss';

type Props = {
  views: number;
  usage: number;
};

export const UsageAndViewBadges: FC<Props> = ({ views, usage }) => {
  const formattedViews = getFormattedNumber(views);
  const formattedUsage = getFormattedNumber(usage);

  return (
    <div className={styles.badgesContainer}>
      {!!formattedViews && (
        <div className={styles.viewsBadge}>
          <EyeIcon />
          {formattedViews}
        </div>
      )}
      {!!formattedUsage && (
        <div className={styles.usageBadge}>
          <UsageIcon />
          {formattedUsage}
        </div>
      )}
    </div>
  );
};

const getFormattedNumber = (number: number) => {
  if (!number) return;

  if (number < 1000) {
    return number.toString();
  } else if (number === 1000) {
    return '1K';
  } else if (number < 1000000) {
    const formatted = (number / 1000).toString();
    const decimalIndex = formatted.indexOf('.');

    if (decimalIndex !== -1) {
      const firstDecimalDigit = formatted.charAt(decimalIndex + 1);

      if (firstDecimalDigit !== '0') {
        return formatted.substring(0, decimalIndex + 2) + 'K';
      }

      return formatted.substring(0, decimalIndex) + 'K';
    }

    return formatted + 'K';
  } else {
    const formatted = (number / 1000000).toString();
    const decimalIndex = formatted.indexOf('.');

    if (decimalIndex !== -1) {
      const firstDecimalDigit = formatted.charAt(decimalIndex + 1);

      if (firstDecimalDigit !== '0') {
        return formatted.substring(0, decimalIndex + 2) + 'M';
      }

      return formatted.substring(0, decimalIndex) + 'M';
    }

    return formatted + 'M';
  }
};
