import { errorMessages } from '../../services/errors';
import { useDeleteDocumentMutation } from '../../services/myContent';
import { handleError } from '../../utils/notifications';

export const useDeleteDocument = () => {
  const [deleteDocument] = useDeleteDocumentMutation();

  const handleDeleteDocument = async (documentId: number) => {
    try {
      await deleteDocument(documentId).unwrap();
    } catch (error) {
      handleError(errorMessages.DOCUMENT_NOT_DELETED);
      console.error('error', error);
    }
  };

  return { handleDeleteDocument };
};
