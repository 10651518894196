import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const ChainIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" {...props}>
      <path
        d="M7.03076 8.971L7.03154 8.97179C8.06506 10.0117 9.7524 10.0223 10.7922 8.98334L12.3977 7.38887C13.445 6.37098 13.4446 4.66426 12.4132 3.62573C11.3783 2.58364 9.6946 2.57781 8.65251 3.6127L8.65104 3.61417L7.2831 4.9821C7.04879 5.21642 7.04879 5.59632 7.2831 5.83063C7.51742 6.06494 7.89732 6.06494 8.13163 5.83063L9.4981 4.46416L9.49874 4.46352C10.0706 3.89628 10.9941 3.89969 11.5618 4.47132C12.1323 5.04579 12.1205 5.98658 11.5599 6.52982L11.5546 6.53497L9.94588 8.13258L9.94441 8.13405C9.37773 8.70073 8.45129 8.69845 7.88223 8.12542L7.88076 8.12395L7.77718 8.02037C7.54287 7.78606 7.16297 7.78606 6.92865 8.02037C6.69434 8.25469 6.69434 8.63459 6.92866 8.8689L7.03076 8.971Z"
        fill="#989898"
      />
      <path
        d="M8.96965 7.02904L8.96887 7.02825C7.93534 5.98839 6.24801 5.97776 5.20824 7.0167L3.60267 8.61118C2.55538 9.62906 2.55584 11.3358 3.58719 12.3743C4.62208 13.4164 6.30581 13.4222 7.3479 12.3873L7.34937 12.3859L8.71731 11.0179C8.95162 10.7836 8.95162 10.4037 8.71731 10.1694C8.48299 9.9351 8.10309 9.9351 7.86878 10.1694L6.50231 11.5359L6.50167 11.5365C5.92978 12.1038 5.00633 12.1003 4.43866 11.5287C3.86815 10.9543 3.87994 10.0135 4.44055 9.47022L4.44581 9.46507L6.05453 7.86747L6.056 7.86599C6.62268 7.29931 7.54911 7.30159 8.11818 7.87462L8.11965 7.87609L8.22323 7.97967C8.45754 8.21398 8.83744 8.21398 9.07175 7.97967C9.30607 7.74535 9.30607 7.36545 9.07175 7.13114L8.96965 7.02904Z"
        fill="#989898"
      />
    </Icon>
  );
};
