import cn from 'classnames';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { MainLogo } from '../../../../assets/svg/MainLogo';
import { PAGES_ENUM } from '../../../../utils/constants';
import styles from './Header.module.scss';

type Props = {
  withButtons?: boolean;
  className?: string;
};

export const Header: FC<Props> = ({ className, withButtons = true }) => {
  return (
    <header className={cn(styles.header, className)}>
      <MainLogo className={styles.logo} />
      {withButtons && (
        <div className={styles.headerBtnsWrapper}>
          <NavLink
            to={PAGES_ENUM.SIGN_IN}
            className={({ isActive }) =>
              cn(styles.headerBtn, 'alchemy-button', {
                [styles.headerBtnActive]: isActive,
                active: isActive,
              })
            }
          >
            Log In
          </NavLink>
          <NavLink
            to={PAGES_ENUM.SIGN_UP}
            className={({ isActive }) =>
              cn(styles.headerBtn, 'alchemy-button', {
                [styles.headerBtnActive]: isActive,
                active: isActive,
              })
            }
          >
            Sign Up
          </NavLink>
        </div>
      )}
    </header>
  );
};
