export default function BlobSidebarPromptLibraryIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3.5'
        y='6.5'
        width='17'
        height='11'
        rx='2'
        stroke={'currentColor'}
        strokeWidth='1.8'
      />
      <path
        d='M7.32117 13.6528H16.6788'
        stroke={'currentColor'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.32117 10.3472L11.8212 10.3472'
        stroke={'currentColor'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
