import { FC, ReactNode } from 'react';

import { GlobalSearchBar } from '../../GlobalSearchBar';
import styles from './MainContentLayout.module.scss';

type Props = {
  title: string;
  searchBarName?: string;
  searchBarPlaceholder?: string;
  onChange?: (value: string) => void;
  children: ReactNode;
  extraHeaderContent?: ReactNode;
  isLoading?: boolean;
  isRenderSearch?: boolean;
  searchValue?: string;
  isSearchDisabled?: boolean;
};

export const MainContentLayout: FC<Props> = ({
  title,
  children,
  isLoading,
  isRenderSearch = true,
  onChange = () => null,
  isSearchDisabled = false,
  searchBarName,
  searchBarPlaceholder,
  extraHeaderContent,
  ...rest
}) => {
  return (
    <div className={styles.mainContent}>
      <header className={styles.mainTitleAndSearchBarContainer}>
        <h1 className={styles.mainTitle}>{title}</h1>
        <div className={styles.mainSearchBarWrapper}>
          {extraHeaderContent}
          {isRenderSearch && (
            <GlobalSearchBar
              {...rest}
              name={searchBarName}
              placeholder={searchBarPlaceholder}
              onChange={onChange}
              isLoading={isLoading}
              isDisabled={isSearchDisabled}
            />
          )}
        </div>
      </header>
      {children}
    </div>
  );
};
