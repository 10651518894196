import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const UsageIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8C9.83333 8 8 9.83333 8 14C8 9.83333 6.16667 8 2 8C6.16667 8 8 6.16667 8 2C8 6.16667 9.83333 8 14 8Z"
        stroke="#B1B4B7"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
