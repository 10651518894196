import './index.scss';

import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { Button } from '../../button';
import { LoadingOverlay } from '../../loading';
import { Modal } from '..';

interface IPromptModalCallerProps {
  title?: string;
  text?: string;
  danger?: boolean;
  primary?: boolean;
  inputPlaceholder?: string;
  defaultValue?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  className?: string;
  theme?: 'default' | 'blob';
  handleConfirm?: (text: string) => Promise<void>;
  handleCancel?: (triggeredByButton: boolean) => Promise<void>;
}
export const prompt = (props: IPromptModalCallerProps) => {
  const rootNode = document.querySelector('#root');

  const portalNode = document.createElement('div');

  portalNode.className = 'alchemy-modal-portal';

  if (rootNode !== null) {
    rootNode.append(portalNode);

    const root = createRoot(portalNode);

    root.render(<PromptModal handleDestroy={() => root.unmount()} {...props} />);
  }
};

interface IPromptModal {
  handleDestroy?: () => void;
}

export function PromptModal({
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  ...props
}: IPromptModalCallerProps & IPromptModal) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>(props.defaultValue ?? '');

  useEffect(() => {
    setIsVisible(true);
  }, []);

  async function handleConfirm() {
    setIsLoading(true);
    await props.handleConfirm?.(inputValue);
    setIsLoading(false);
    setIsVisible(false);

    setTimeout(() => {
      props.handleDestroy?.();
    }, 500);
  }

  async function handleCancel(triggeredByButton: boolean) {
    setIsLoading(true);
    await props.handleCancel?.(triggeredByButton);
    setIsLoading(false);
    setIsVisible(false);

    setTimeout(() => {
      props.handleDestroy?.();
    }, 500);
  }

  return (
    <Modal
      visible={isVisible}
      className={'alchemy-prompt-modal' + (props.className ? ' ' + props.className : '')}
      title={props.title ?? 'Input prompt'}
      onClose={() => handleCancel(false)}
      hasCloseButton={true}
      theme={props.theme}
    >
      <div className="content">
        <p>{props.text}</p>
        <input
          placeholder={props.inputPlaceholder ?? 'Please enter text...'}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <div className="alchemy-footer">
          <Button size="mini" onClick={() => handleCancel(true)} className="cancel-btn">
            {cancelButtonText}
          </Button>
          <Button
            size="mini"
            onClick={() => handleConfirm()}
            danger={props.danger}
            active={props.primary}
            className="confirm-btn"
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
      <LoadingOverlay active={isLoading} />
    </Modal>
  );
}
