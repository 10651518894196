import { createRef } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { IInput } from '../../../../../../../interfaces';
import { VoiceInput } from '../../../../../../design/components/voice';

export interface IPromptInputField {
  input: IInput;
  value: string;
  setValue: (newValue: string) => void;
}

export const PromptInputField = (props: IPromptInputField) => {
  const textAreaRef = createRef<HTMLTextAreaElement>();

  return (
    <div className="prompt-input">
      <div className="prompt-input-name">{props.input.variable_name}</div>
      <ReactTextareaAutosize
        onChange={e => {
          props.setValue(e.target.value);
        }}
        ref={textAreaRef}
        placeholder={props.input.placeholder}
        maxRows={7}
        onHeightChange={height => {
          if (textAreaRef.current) {
            textAreaRef.current.style.height = height + 6 + 'px';
          }
        }}
        value={props.value}
      />
      <VoiceInput prompt={props.value} setPrompt={props.setValue} />
    </div>
  );
};
