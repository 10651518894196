import { errorMessages } from '../../../../services/errors';
import { useDeleteSnippetMutation } from '../../../../services/snippets';
import { handleError } from '../../../../utils/notifications';
import { Button } from '../../../Button';
import { ModalLayout } from '../../ModalLayout';
import styles from './DeleteSnippetModal.module.scss';

export const DeleteSnippetModal = ({ onClose, id }) => {
  const [deleteSnippet, { isLoading }] = useDeleteSnippetMutation();

  const handleDeleteSnippet = async () => {
    try {
      await deleteSnippet(id).unwrap();

      onClose();
    } catch (error) {
      handleError(errorMessages.SNIPPET_NOT_DELETED);
    }
  };

  return (
    <ModalLayout title="Delete snippet">
      <div className={styles.deleteSnippetDescribtion}>
        Are you sure you want to delete this snippet?
      </div>
      <div className={styles.btnsContainer}>
        <Button className={styles.cancelBtn} onClick={onClose} isSecondary disabled={isLoading}>
          Cancel
        </Button>
        <Button
          className={styles.deleteBtn}
          onClick={handleDeleteSnippet}
          isRed
          disabled={isLoading}
        >
          Delete
        </Button>
      </div>
    </ModalLayout>
  );
};
