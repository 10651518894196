import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const FilePlusIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M10.6253 3.95801H6.45866C5.53818 3.95801 4.79199 4.7042 4.79199 5.62467V14.3747C4.79199 15.2951 5.53818 16.0413 6.45866 16.0413H10.2087M10.6253 3.95801V6.87467C10.6253 7.79515 11.3715 8.54134 12.292 8.54134H15.2087L10.6253 3.95801Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.167 12.292V16.042"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.042 14.167H12.292"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
