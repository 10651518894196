import './index.scss';

import { Fragment, useEffect, useId, useState } from 'react';

import { ISwitchItem, SwitchItem } from './components/switchItem';

export interface IItemsSwitch {
  items: ISwitchItem[];
  isTabList?: boolean;
  vertical?: boolean;
  value?: number | string;
  beta?: boolean;
  showCheckbox?: boolean;
  onChange?: (value?: string | number) => void;
  type?: string;
}

export function ItemsSwitch(props: IItemsSwitch) {
  const id = useId();

  const [value, setValue] = useState<number | string | undefined>(
    props.value ?? undefined
  );

  useEffect(() => {
    if (value !== props.value) {
      if (props.onChange) props.onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value ?? undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <div
      className={'items-switch' + (props.vertical ? ' vertical' : '')}
      role={props.isTabList ? 'tablist' : undefined}
    >
      {props.items.map((item, index) => {
        const overridedItem = { ...item };

        delete overridedItem.onClick;

        return (
          <Fragment key={index}>
            {!overridedItem.hide && (
              <SwitchItem
                type={props.type}
                onClick={() => {
                  if (!item.disabled) setValue(item.value);
                  item.onClick?.();
                }}
                roveIndex={index}
                id={id}
                isTabList={props.isTabList}
                beta={props.beta}
                showCheckbox={props.showCheckbox}
                checked={value === item.value}
                {...overridedItem}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
