import './index.scss';

import { useState } from 'react';
import toast from 'react-hot-toast';

import { useExtensionServiceContext } from '../../../../../../../service/context';
import { ExtensionEvents } from '../../../../../../../service/events';
import { ExtensionEventType } from '../../../../../../../service/events/types';
import { ISnippet } from '../../../../../../../service/interfaces';
import { LoadingOverlay } from '../../../../../../design/components/loading';
import { Modal } from '../../../../../../design/components/modal';
import { CreateSnippet } from './create';

export interface IAddToSnippetModal {
  visible: boolean;
  initialText?: string;
  onClose: () => void;
}
export function AddToSnippetModal(props: IAddToSnippetModal) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const extensionService = useExtensionServiceContext();

  const [snippets] = useState<ISnippet[] | undefined>([]);

  return (
    <Modal
      className="message-to-snippet-modal"
      hasCloseButton={false}
      onClose={() => props.onClose}
      visible={props.visible}
    >
      <CreateSnippet
        isVisible={true}
        onCreate={async snippet => {
          try {
            setIsLoading(true);
            await extensionService.createSnippet(snippet.code, snippet.text);
            ExtensionEvents.dispatch(ExtensionEventType.RefreshSnippets);
            setIsLoading(false);

            toast.success('Snippet created successfully');

            props.onClose();
          } catch (e) {
            toast.error('Failed to create snippet');
          }
        }}
        checkDoesHaveSameCode={code =>
          (snippets ?? []).filter(snippet => code === snippet.code).length > 0
        }
        initialText={props.initialText ?? ''}
        setIsVisible={() => {
          /* pass */
        }}
        onCancel={() => props.onClose()}
      />
      <LoadingOverlay active={isLoading} />
    </Modal>
  );
}
