import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const EyeIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" {...props}>
      <path
        d="M12.8333 7.99967C12.8333 8.66634 11.6666 12.1663 7.99996 12.1663C4.33329 12.1663 3.16663 8.66634 3.16663 7.99967C3.16663 7.33301 4.33329 3.83301 7.99996 3.83301C11.6666 3.83301 12.8333 7.33301 12.8333 7.99967Z"
        stroke="#B99F15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8Z"
        stroke="#B99F15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
