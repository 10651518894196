export interface IArrowRightAltIcon {
  color?: string;
}
export default function ArrowRightAltIcon(props: IArrowRightAltIcon) {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.85414 12.2365L6.70357 11.0987L10.6986 7.10369H0.663086V5.44176H10.6986L6.70357 1.45312L7.85414 0.308948L13.8179 6.27273L7.85414 12.2365Z'
        fill={props.color ?? 'currentColor'}
      />
    </svg>
  );
}
