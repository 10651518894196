import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../utils/baseQuery';

export interface IComingSoonBody {
  email: string;
}

export interface IComingSoonResponse {
  status: string;
}

export const comingSoonApi = createApi({
  reducerPath: 'comingSoonApi',
  baseQuery,
  endpoints: builder => ({
    subscribeAlchemy: builder.mutation<IComingSoonResponse, IComingSoonBody>({
      query: body => ({
        url: '/user/store-waiting/',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSubscribeAlchemyMutation } = comingSoonApi;
