import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConversationType, IConversation } from '../../../service/base/ai/interfaces';
import { useExtensionServiceContext } from '../../../service/context';
import { StringTools } from '../../../tools/string';
import { CloseIcon } from '../../design/assets/svg/icons/CloseIcon';
import DeleteIcon from '../../design/assets/svg/icons/DeleteIcon';
import { Button } from '../../design/components/button';
import { LoadingOverlay } from '../../design/components/loading';
import { confirm } from '../../design/components/modal/confirmation';
import { SmoothVisibility } from '../../design/components/smoothVisibility';
import { BackendHistoryStorageProvider } from './providers/backend';

export function HistoryScreen() {
  const [conversations, setConversations] = useState<IConversation[]>([]);
  const [conversationsLoading, setConversationsLoading] = useState<boolean>(false);
  const [conversationsRefreshTrigger, setConversationsRefreshTrigger] = useState<boolean>(false);

  const [loadConversationsError, setLoadConversationsError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  function updateConversations() {
    setConversationsRefreshTrigger(prev => !prev);
  }

  const extensionService = useExtensionServiceContext();
  const isAuthorized = extensionService.useExtensionAuthorization();
  const user = extensionService.useExtensionUser();

  const history = useMemo(() => {
    return new BackendHistoryStorageProvider(extensionService);
  }, [extensionService, isAuthorized]);

  async function refreshConversations() {
    if (!isAuthorized) return;

    try {
      setConversationsLoading(true);
      const newConversations = await history.getConversationsList();

      setConversations(newConversations ?? []);
      setConversationsLoading(false);
      setIsLoading(false);
    } catch (e) {
      setLoadConversationsError(true);
      setConversationsLoading(false);
      console.error(e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (conversations.length === 0 || !!user.session.tier) {
      setTimeout(() => {
        refreshConversations();
      }, 10);
    }
  }, [isAuthorized, user.session.tier, conversationsRefreshTrigger]);

  const navigate = useNavigate();

  return (
    <>
      <SmoothVisibility className="smooth-section" visible={true}>
        <header>
          <div className="header-left-side-wrapper">
            Chat History
            <SmoothVisibility
              as="button"
              visible={conversations.length > 0}
              className="new-btn"
              onClick={() => {
                confirm({
                  text: `Are you sure you want to clear all chats?`,
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No',
                  primary: true,
                  danger: true,
                  theme: 'blob',
                  handleConfirm: async () => {
                    await history.removeAllConversations();

                    updateConversations();
                  },
                });
              }}
            >
              Clear All
            </SmoothVisibility>
          </div>

          <div className="header-right-side-wrapper">
            <button
              onClick={() => {
                navigate('/chat');
              }}
              className="close-tab-btn"
            >
              <CloseIcon />
            </button>
          </div>
        </header>
        <div className="chats-list">
          {conversations.map((item, index) => (
            <div
              key={index}
              onClick={async () => {
                setIsLoading(true);

                const id = extensionService.encryptor.encrypt(item.id);

                navigate(
                  '/' +
                    (item.chat_type === ConversationType.Synth ? 'synthesize' : 'chat') +
                    '?id=' +
                    id
                );

                // const newMessages = await history.getConversationMessages(item.id);

                // const conversation: IConversation = {
                //   ...item,
                //   messages: newMessages?.data ?? [],
                //   current_message_id: newMessages?.current_message_id,
                // };

                setIsLoading(false);
              }}
              className={`chat`}
            >
              <div className="chat-info">
                <div className="chat-title">{item.name}</div>
                <div className="chat-meta">
                  <div className="type">
                    {item?.chat_type === ConversationType.Synth ? 'Synth' : 'Chat'}
                  </div>
                  <div className="updated-at">
                    {StringTools.formatDate(item.updated_at || new Date(), 'string')}
                  </div>
                </div>
              </div>
              <button
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  confirm({
                    text: `Are you sure you want to delete chat "${item.name}"?`,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    danger: true,
                    primary: true,
                    theme: 'blob',
                    handleConfirm: async () => {
                      await history.removeConversation(item.id);

                      updateConversations();
                    },
                  });
                }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}
          <SmoothVisibility
            className="empty-message"
            visible={conversations.length === 0 && !conversationsLoading}
          >
            {loadConversationsError ? (
              <div className="loading-error">
                <span>Failed to load chat history</span>
                <Button onClick={() => window.location.reload()}>Reload page</Button>
              </div>
            ) : (
              <span>Your chats will be shown here...</span>
            )}
          </SmoothVisibility>
        </div>
      </SmoothVisibility>
      <LoadingOverlay active={isLoading} />
    </>
  );
}
