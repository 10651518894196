import './CreatePromptField.scss';

import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CustomPromptTypes, ICreateCustomPromptStep } from '../../../../../models/IPromptLibrary';
import suggestion from './suggestion';

interface IValues {
  title: string;
  description: string;
  prompt: string;
  category: number[];
  prompt_type: CustomPromptTypes;
  steps: ICreateCustomPromptStep[];
}

interface IHashtag {
  id: number;
  variable_name: string;
}

type Props = {
  hashtags: IHashtag[];
  name: string;
  disabled: boolean;
  hashtagItems: React.MutableRefObject<string[]>;
  htmlContent?: React.MutableRefObject<string>;
  onSetNewHashtagItems: (arg: string[]) => void;
  onChainChange?: (promptTemplate: string, promptMarkdownTemplate: string) => void;
  chainHtmlContent?: string;
};

export const CreatePromptField: FC<Props> = ({
  hashtags = [],
  name,
  disabled,
  hashtagItems,
  htmlContent,
  onSetNewHashtagItems,
  onChainChange,
  chainHtmlContent,
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<IValues>();

  const prevContent = htmlContent?.current || chainHtmlContent;

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder:
            "Craft your prompt. Include the # tag for areas needing user input. E.g., 'Imagine a world where every #object has a voice.'",
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: {
            ...suggestion,
            items: ({ query }) => {
              let result: IHashtag[] = [];

              if (hashtags.length > 0) {
                result = [...hashtags];
                for (const hashItem of hashtagItems.current) {
                  const existsInHashtagsList = hashtags.some(
                    item => item.variable_name === hashItem
                  );

                  if (!existsInHashtagsList) {
                    result.push({ variable_name: hashItem, id: uuidv4() as any });
                  }
                }
              } else if (hashtagItems.current.length > 0) {
                hashtagItems.current.map(hashItem =>
                  result.push({ variable_name: hashItem, id: uuidv4() as any })
                );
              }
              if (result.some(suggestion => suggestion.variable_name.includes(query))) {
                return result.filter(item =>
                  item.variable_name.toLowerCase().startsWith(query.toLowerCase())
                );
              }

              return [...result, { id: uuidv4(), variable_name: query }].filter(item =>
                item.variable_name.toLowerCase().startsWith(query.toLowerCase())
              );
            },
            command: ({ editor, range, props }) => {
              const nodeAfter = editor.view.state.selection.$to.nodeAfter;
              const overrideSpace = nodeAfter?.text?.startsWith(' ');

              if (overrideSpace) {
                range.to += 1;
              }

              editor
                .chain()
                .focus()
                .insertContentAt(range, [
                  {
                    type: 'mention',
                    attrs: props,
                  },
                  {
                    type: 'text',
                    text: ' ',
                  },
                ])
                .run();
              window.getSelection()?.collapseToEnd();
              if (props?.id && hashtagItems.current.every(item => item !== props.id)) {
                hashtagItems.current = [...hashtagItems.current, props.id];
                const content = extractTextFromHTML(editor.getHTML(), editor.getText());
                const renderedHashtags = getRenderedHashtags(
                  content,
                  hashtagItems.current,
                  editor.getHTML()
                );

                onSetNewHashtagItems(renderedHashtags);
              }
            },
          },
        }),
      ],
      onUpdate: ({ editor }) => {
        const content = extractTextFromHTML(editor.getHTML(), editor.getText());

        if (typeof onChainChange === 'function') {
          onChainChange(content, editor.getHTML());
        } else {
          htmlContent.current = editor.getHTML();
          setFieldValue(name, content);
        }
        const renderedHashtags = getRenderedHashtags(
          content,
          hashtagItems.current,
          editor.getHTML()
        );

        onSetNewHashtagItems(renderedHashtags);
      },
      onBlur: () => {
        setFieldTouched(name, true, true);
      },
      content: prevContent ? `${prevContent}` : '',
      editable: !disabled,
    },
    [hashtags]
  );

  if (!editor) {
    return null;
  }

  return <EditorContent editor={editor} />;
};

const extractTextFromHTML = (htmlString: string, text: string) => {
  const normalizedText = text.replace(/(\n+)/g, ' ');

  const parsedText = normalizedText
    .split(' ')
    .map(word => {
      if (
        (htmlString.includes(`data-id="${word.slice(1)}"`) ||
          htmlString.includes(`data-id='${word.slice(1)}'`)) &&
        word.startsWith('#')
      ) {
        return `[${word.slice(1)}]`;
      }

      return word;
    })
    .join(' ');

  return parsedText;
};

export const getRenderedHashtags = (text: string, hashItems: string[], htmlContent: string) => {
  if (Array.isArray(hashItems)) {
    return hashItems.filter(item => text.includes(`[${item}]`) && htmlContent.includes(`#${item}`));
  }
};
