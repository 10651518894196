import { createRef, RefObject } from 'react';

import { Button } from '../../../../../../design/components/button';
import { AuthBlockHeader } from '../../../../components/header';
import { IValidationFieldErrors } from '../../../../interfaces';
import { IResetPasswordFormState } from '../..';

export interface IResetPasswordCodeEnterStep {
  size?: 'default' | 'mini';
  isLoading?: boolean;

  formState: IResetPasswordFormState;
  errors: IValidationFieldErrors;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    formRef: RefObject<HTMLFormElement>
  ) => void;

  onSubmit: (
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>
  ) => void;
  onCancel: () => void;
}

export function ResetPasswordCodeEnterStep({
  size = 'default',
  ...props
}: IResetPasswordCodeEnterStep) {
  const formRef = createRef<HTMLFormElement>();

  return (
    <>
      <AuthBlockHeader
        size={size}
        title={'Confirmation Code Sent'}
        subTitle={
          'Please check your email and enter the code we sent you for resetting your password'
        }
      />
      <form ref={formRef} className='auth-form'>
        <div className='input'>
          <label className='input-label' htmlFor='code'>
            Confirmation Code
          </label>
          <input
            name='code'
            type='text'
            placeholder='Enter the code'
            id='code'
            required
            value={props.formState.code}
            onChange={(e) => props.handleInputChange(e, formRef)}
          />
          {props.errors.code && (
            <div className='auth-error-message'>{props.errors.code}</div>
          )}
        </div>
        <Button
          type='submit'
          isLoading={props.isLoading}
          active={true}
          className='enforce-blob-max-width'
          onClick={(e) => props.onSubmit(e, formRef)}
        >
          Next
        </Button>
        <Button
          className='enforce-blob-max-width'
          type='submit'
          onClick={() => props.onCancel()}
        >
          Cancel
        </Button>
      </form>
    </>
  );
}
