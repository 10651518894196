import React, { Dispatch, RefObject, SetStateAction, useState } from 'react';

import { IValidationFieldErrors } from '../../interfaces';
import { ResetPasswordCodeEnterStep } from './steps/code';
import { ResetPasswordInitialStep } from './steps/initial';
import { ResetPasswordChangeStep } from './steps/password';
import { ResetPasswordSuccessStep } from './steps/success';

export interface IResetPasswordBlock {
  size?: 'default' | 'mini';
  isLoading?: boolean;

  onSubmitEmail: (
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: IResetPasswordFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    setActiveStep: React.Dispatch<
      React.SetStateAction<'code' | 'success' | 'password' | 'initial'>
    >
  ) => void;

  onSubmitCode: (
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: IResetPasswordFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    setActiveStep: React.Dispatch<
      React.SetStateAction<'code' | 'success' | 'password' | 'initial'>
    >
  ) => void;

  onSubmitPasswords: (
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>,
    formState: IResetPasswordFormState,
    setErrors: Dispatch<SetStateAction<IValidationFieldErrors>>,
    setActiveStep: React.Dispatch<
      React.SetStateAction<'code' | 'success' | 'password' | 'initial'>
    >
  ) => void;

  onNavigateToLogin: () => void;
}

export interface IResetPasswordFormState {
  email: string;
  code: string;
  password: string;
  confirmation: string;
}

const validateField = (
  inputElement: HTMLInputElement,
  fieldName: string
): string | null => {
  if (inputElement.validity.valueMissing || inputElement.value.trim() === '') {
    return 'Please fill out the field';
  } else if (inputElement.validity.typeMismatch) {
    return 'Please enter a valid email address';
  } else if (fieldName === 'password' && inputElement.validity.tooShort) {
    return 'Please use at least 8 characters';
  }

  return null;
};

export function ResetPasswordBlock({ ...props }: IResetPasswordBlock) {
  const [formState, setFormState] = useState<IResetPasswordFormState>({
    email: '',
    code: '',
    password: '',
    confirmation: ''
  });
  const [errors, setErrors] = useState<IValidationFieldErrors>({
    email: null,
    password: null,
    confirmation: null
  });

  const [activeStep, setActiveStep] = useState<
    'initial' | 'code' | 'password' | 'success'
  >('initial');

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;

    setFormState((prev) => ({ ...prev, [name]: value }));

    if (e.target.name !== 'confirmation') {
      const error = validateField(e.target, name);

      setErrors((prev) => ({ ...prev, [name]: error }));
    }

    if (e.target.name === 'password' || e.target.name === 'confirmation') {
      if (formState.password !== formState.confirmation) {
        setErrors((prev) => ({
          ...prev,
          confirmation: 'Passwords must match'
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          confirmation: ''
        }));
      }
    }
  }

  async function handleOnCurrentStepSubmit(
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>
  ) {
    if (activeStep === 'initial') {
      props.onSubmitEmail(e, formRef, formState, setErrors, setActiveStep);
    } else if (activeStep === 'code') {
      props.onSubmitCode(e, formRef, formState, setErrors, setActiveStep);
    } else if (activeStep === 'password') {
      props.onSubmitPasswords(e, formRef, formState, setErrors, setActiveStep);
    }
  }

  const steps = {
    initial: ResetPasswordInitialStep,
    code: ResetPasswordCodeEnterStep,
    password: ResetPasswordChangeStep,
    success: ResetPasswordSuccessStep
  };

  const ActiveStepComponent = steps[activeStep];

  return (
    <div className='auth-block'>
      <ActiveStepComponent
        formState={formState}
        isLoading={props.isLoading}
        errors={errors}
        handleInputChange={handleInputChange}
        onSubmit={handleOnCurrentStepSubmit}
        onCancel={props.onNavigateToLogin}
        onFinish={props.onNavigateToLogin}
      />
    </div>
  );
}
