import cn from 'classnames';
import { Field } from 'formik';
import { FC, useRef, useState } from 'react';
import Select, {
  components,
  DropdownIndicatorProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
} from 'react-select';

import { ChevronDownIcon } from '../../../../../assets/svg/ChevronDownIcon';
import styles from './CategoryField.module.scss';

export interface IOption {
  id: number;
  value: string;
  label: string;
  icon?: string;
  whiteIcon?: string;
  color?: string;
  background?: string;
}

type Props = {
  name: string;
  options: IOption[];
  disabled: boolean;
  placeholder: string;
  isError: boolean;
};

interface IDropdownIndicatorProps extends DropdownIndicatorProps {
  iconClassName?: string;
}

const Option = (props: OptionProps<IOption>) => {
  const { data } = props || {};
  const { label, whiteIcon } = data || {};

  return (
    <components.Option {...props}>
      {whiteIcon && (
        <img className={styles.optionIcon} src={whiteIcon} alt="icon" width="20" height="20" />
      )}
      <div className={styles.categoryOption}>{label}</div>
    </components.Option>
  );
};

const DropdownIndicator = (props: IDropdownIndicatorProps) => {
  const { iconClassName } = props || {};

  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className={cn(styles.dropdownIcon, iconClassName)} />
    </components.DropdownIndicator>
  );
};

const SingleValue = ({ ...props }: SingleValueProps<IOption>) => {
  const { children, data } = props || {};
  const { whiteIcon } = data || {};

  return (
    <components.SingleValue {...props}>
      {whiteIcon ? (
        <img className={styles.optionIcon} src={whiteIcon} alt="icon" width="20" height="20" />
      ) : null}
      <div className={styles.singleValue}>{children}</div>
    </components.SingleValue>
  );
};

const staticStyles: StylesConfig = {
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 16px',
    borderRadius: '8px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
  }),
  placeholder: () => ({
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#989898',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  }),
  dropdownIndicator: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  singleValue: () => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#fff',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
  }),
  input: () => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  }),
  menu: baseStyles => ({
    ...baseStyles,
    border: '1px solid rgba(255, 255, 255, 0.08)',
    borderRadius: '8px',
    background: 'rgba(33, 36, 39, 0.56)',
    boxShadow: '0px 12px 30px 12px rgba(0, 0, 0, 0.25)',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    backdropFilter: 'blur(16px)',
    padding: '3px',
    marginTop: '8px',
    left: 0,
  }),
  menuList: baseStyles => ({
    ...baseStyles,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    borderRadius: '8px',
    padding: 0,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    maxHeight: '184px',
  }),
  option: (baseStyles, { isFocused, isSelected }) => ({
    ...baseStyles,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: isFocused || isSelected ? '#F3F4F5' : '#B1B4B7',
    background:
      isFocused || isSelected
        ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 100%)'
        : '',
    padding: '0 10px',
    height: '40px',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    borderRadius: '8px',
    '&:active': {
      background: '#2F3234',
    },
    flexShrink: 0,
    cursor: 'pointer',
  }),
  noOptionsMessage: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 16px',
    background: '#2F3234',
    color: '#D8DADB',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
  }),
};

export const CategoryField: FC<Props> = ({ name, options, disabled, placeholder, isError }) => {
  const [isDropdownIndicatorRotated, setIsDropdownIndicatorRotated] = useState(false);
  const ref = useRef(null);
  const customStyles = {
    ...staticStyles,
    container: baseStyles => ({
      ...baseStyles,
      position: 'relative',
      background: 'rgba(255, 255, 255, 0.04)',
      borderRadius: '8px',
      borderWidth: '1px',
      borderStyle: 'solid',
      border: '1px solid rgba(255, 255, 255, 0.08)',
      width: '100%',
      minWidth: '120px',
      maxWidth: '320px',
      cursor: 'pointer',
      borderColor: isError ? '#f04438' : 'rgba(255, 255, 255, 0.08)',
    }),
    dropdownIndicator: () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: isDropdownIndicatorRotated ? 'rotateX(180deg)' : 'none',
    }),
  };

  return (
    <Field name={name}>
      {({ field, form }) => (
        <Select
          {...field}
          ref={ref}
          defaultValue={null}
          options={options}
          aria-label="set the category"
          onChange={(option: IOption) => {
            form.setFieldValue(name, [option]);
          }}
          onBlur={() => {
            form.setFieldTouched(name, true, true);
          }}
          styles={customStyles}
          components={{
            DropdownIndicator: props => (
              <DropdownIndicator
                {...props}
                iconClassName={cn({ [styles.dropdownIconColored]: field?.value?.length > 0 })}
              />
            ),
            SingleValue: props => <SingleValue {...(props as SingleValueProps<IOption>)} />,
            Option,
          }}
          isDisabled={disabled}
          placeholder={placeholder}
          isSearchable={false}
          onMenuOpen={() => setIsDropdownIndicatorRotated(true)}
          onMenuClose={() => setIsDropdownIndicatorRotated(false)}
          unstyled
        />
      )}
    </Field>
  );
};
