import cn from 'classnames';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Button } from '../../../../../components/Button';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { SUBSCRIPTION_TIER } from '../../../../../models/ISubscription';
import { selectTheme, ThemeOptions } from '../../../../../redux/Theme';
import styles from './BillingCard.module.scss';
import { IBillingCard } from './IBillingCard';
import { PopularLabel } from './PopularLabel';

export const BillingCard: FC<
  IBillingCard & { onClick: () => void; getIsActiveBtn: (tier: SUBSCRIPTION_TIER) => boolean }
> = ({
  title,
  price,
  buttonText,
  benefits,
  isPopular,
  timePeriod,
  tier,
  onClick,
  getIsActiveBtn,
  isLoading,
  isTrialActivated,
  saving,
}) => {
  const theme = useTypedSelector(selectTheme);
  const isPurpleTheme = theme === ThemeOptions.Purple;
  const isZeroSubscription = tier === SUBSCRIPTION_TIER.ZERO;

  return (
    <div
      className={cn(styles.billingCard, {
        [styles.billingCardPurpleAndPopular]: isPurpleTheme && isPopular,
      })}
    >
      {isPopular && <PopularLabel className={styles.billingCardPopularLabel} />}
      <div className={styles.billingCardTitleWrapper}>
        <h2 className={styles.billingCardTitle}>{title}</h2>
        {saving && <span>{saving}</span>}
      </div>
      <div className={styles.billingCardTimePeriodAndPrice}>
        <span>&#36;{`${price}`}</span>
        {`/${timePeriod}`}
      </div>

      <Button
        className={getIsActiveBtn(tier) ? styles.billingCardBtnActive : styles.billingCardBtn}
        disabled={isLoading || getIsActiveBtn(tier)}
        onClick={onClick}
      >
        {isLoading ? (
          <div className={styles.billingCardBtnLoadingWrapper}>
            <Skeleton baseColor="#8961f3" />
          </div>
        ) : (
          <>
            {getIsActiveBtn(tier)
              ? 'Current plan'
              : isZeroSubscription || isTrialActivated
              ? buttonText
              : 'Start 7-day free trial'}
          </>
        )}
      </Button>
    </div>
  );
};
