import cn from 'classnames';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '../../../../components/Button';
import {
  CustomPromptTypes,
  ICreateCustomPromptRequest,
  ICustomInput,
  ICustomPrompt,
  ITag,
  IUpdateCustomPromptRequest,
} from '../../../../models/IPromptLibrary';
import { ICategory } from '../../../../models/IShared';
import { errorMessages } from '../../../../services/errors';
import {
  useCreateCustomInputFieldMutation,
  useCreateCustomPromptMutation,
  useCreatePromptStepMutation,
  useGetCategoriesQuery,
  useGetTagsQuery,
  useUpdateCustomPromptMutation,
} from '../../../../services/promptLibrary';
import { useGetSettingsQuery } from '../../../../services/settings';
import { handleError } from '../../../../utils/notifications';
import { CategoryField } from './CategoryField';
import styles from './CreateCustomPrompt.module.scss';
import { CreatePromptField } from './CreatePromptField';
import { CreatePromptTextAreaField } from './CreatePromptTextAreaField';
import { HashtagTextAreaField } from './HashtagTextAreaField';
import { PromptChainList } from './PromptChainList';
import { RadioButton } from './RadioButton';
import { validationSchema } from './validationService';

export const getIsWordMatchesHashtag = (hashtags: ITag[], word: string) => {
  return hashtags?.some(hashtag => hashtag.variable_name === word);
};

interface IProps {
  currEditPrompt?: ICustomPrompt;
  onSuccessCreateOrEditCustomPrompt: () => void;
}

export const CreateCustomPrompt: React.FC<IProps> = ({
  currEditPrompt,
  onSuccessCreateOrEditCustomPrompt,
}) => {
  const { data: dataCategories = { results: [] }, isFetching: isFetchingCategories } =
    useGetCategoriesQuery();
  const { data: dataTags = { results: [] }, isFetching: isFetchingTags } = useGetTagsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: userData } = useGetSettingsQuery();
  const [createCustomPrompt, resCreateCustomPrompt] = useCreateCustomPromptMutation();
  const [createCustomInputField, resCreateCustomInputField] = useCreateCustomInputFieldMutation();
  const [updateCustomPrompt] = useUpdateCustomPromptMutation();
  const [createCustomPromptSteps] = useCreatePromptStepMutation();
  const hashtagItems = useRef<string[]>(
    currEditPrompt?.inputs?.length > 0 ? currEditPrompt.inputs.map(inp => inp.variable_name) : []
  );

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>SIGNLE PROMPT START <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const [singlePromptInputValues, setSinglePromptInputValues] = useState<{ [key: string]: string }>(
    {}
  );
  const [inputHashtags, setInputHashtags] = useState<ICustomInput[]>(
    currEditPrompt?.inputs?.length > 0 && !currEditPrompt?.chain_prompt ? currEditPrompt.inputs : []
  );
  const htmlContent = useRef<string>(currEditPrompt?.prompt_markdown_template || '');

  useEffect(() => {
    setSinglePromptInputValues(currentInputValues => {
      const result = {};

      inputHashtags.forEach(ih => {
        // eslint-disable-next-line
        if (currentInputValues.hasOwnProperty(ih.variable_name)) {
          result[ih.variable_name] = currentInputValues[ih.variable_name];
        }
      });

      return result;
    });
  }, [inputHashtags]);

  const handleNewHashtagItems = (newHashItems: string[]) => {
    setInputHashtags(currentHashtagItems => {
      if (currentHashtagItems.length !== newHashItems.length) {
        const transformedHashtags = getTransformedHashtags(currentHashtagItems, newHashItems);

        return transformedHashtags;
      }

      const updateIsRequired = !newHashItems.every(newItem =>
        currentHashtagItems.some(currentItem => currentItem.variable_name === newItem)
      );

      if (!updateIsRequired) {
        return currentHashtagItems;
      }

      const transformedHashtags = getTransformedHashtags(currentHashtagItems, newHashItems);

      return transformedHashtags;
    });
  };

  const handlSinglePromptInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    variableName: string
  ) => {
    setSinglePromptInputValues({ ...singlePromptInputValues, [variableName]: e.target.value });
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>SIGNLE PROMPT END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>CHAIN PROMPT START <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const [chainPromptInputValues, setChainPromptInputValues] = useState<{ [key: string]: string }>(
    {}
  );
  const [chainInputHashtags, setChainInputHashtags] = useState<ICustomInput[]>(
    currEditPrompt?.inputs?.length > 0 && currEditPrompt?.chain_prompt ? currEditPrompt.inputs : []
  );

  useEffect(() => {
    setChainPromptInputValues(currentInputValues => {
      const result = {};

      chainInputHashtags.forEach(ih => {
        // eslint-disable-next-line
        if (currentInputValues.hasOwnProperty(ih.variable_name)) {
          result[ih.variable_name] = currentInputValues[ih.variable_name];
        }
      });

      return result;
    });
  }, [chainInputHashtags]);

  const handleNewChainHashtagItems = (newHashItems: string[], prompTemplates: string) => {
    setChainInputHashtags(currentChainHashtagItems => {
      if (currentChainHashtagItems.length !== newHashItems.length) {
        const transformedHashtags = getTransformedChainHashtags(
          currentChainHashtagItems,
          newHashItems,
          prompTemplates
        );

        return transformedHashtags;
      }

      const updateIsRequired = !newHashItems.every(newItem =>
        currentChainHashtagItems.some(currentItem => currentItem.variable_name === newItem)
      );

      if (!updateIsRequired) {
        return currentChainHashtagItems;
      }

      const transformedHashtags = getTransformedChainHashtags(
        currentChainHashtagItems,
        newHashItems,
        prompTemplates
      );

      return transformedHashtags;
    });
  };

  const handleChainPromptInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    variableName: string
  ) => {
    setChainPromptInputValues({ ...chainPromptInputValues, [variableName]: e.target.value });
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>CHAIN PROMPT END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const changedCategories = dataCategories.results?.map((category: ICategory) => ({
    id: category.id,
    value: category.name,
    label: category.name,
    icon: category.icon,
    whiteIcon: category.white_icon,
    color: category.color,
    background: category.background_color,
  }));
  const isEditModeDisabled = currEditPrompt?.editable === false;
  const isEditMode = currEditPrompt !== null && currEditPrompt.editable;
  const isPageDataLoading =
    isFetchingCategories ||
    isFetchingTags ||
    resCreateCustomPrompt.isLoading ||
    resCreateCustomInputField.isLoading;
  const userId = userData?.id;
  const categoryEditPrompt = currEditPrompt?.categories?.map(category => ({
    id: category.id,
    value: category.name,
    label: category.name,
    whiteIcon: category.white_icon,
  }));

  return (
    <div className={styles.createCustomPromptWrapper}>
      <Formik
        initialValues={{
          title: isEditMode ? currEditPrompt.name : '',
          description: isEditMode ? currEditPrompt.description : '',
          prompt: isEditMode ? currEditPrompt.prompt_template : '',
          category: isEditMode ? categoryEditPrompt : [],
          prompt_type:
            currEditPrompt?.chain_prompt && isEditMode
              ? CustomPromptTypes.chain
              : CustomPromptTypes.single,
          steps:
            isEditMode && currEditPrompt.steps?.length > 0
              ? currEditPrompt.steps
              : [
                  {
                    step_number: 1,
                    prompt_template: '',
                    prompt_markdown_template: '',
                    user_prompt: null,
                  },
                ],
        }}
        validationSchema={validationSchema}
        onSubmit={async values => {
          if (isEditMode) {
            try {
              const categories = values.category.map(c => c.id);

              if (values.prompt_type === CustomPromptTypes.single) {
                const customPromptId = currEditPrompt.id;
                const inputsBody = inputHashtags.map(inputTag => {
                  const updatedInputValue = singlePromptInputValues[inputTag.variable_name];

                  return {
                    user_prompt: customPromptId,
                    variable_name: inputTag.variable_name,
                    placeholder:
                      updatedInputValue || updatedInputValue === ''
                        ? updatedInputValue
                        : inputTag.placeholder,
                  };
                });

                const resCreateCustomInputField = await createCustomInputField(inputsBody).unwrap();
                const input_ids = Object.entries(resCreateCustomInputField)?.map(
                  ([, value]) => (value as { id: number })?.id
                );

                const updateCustomPromptBody: IUpdateCustomPromptRequest = {
                  name: values.title,
                  description: values.description,
                  prompt_template: values.prompt,
                  prompt_markdown_template: htmlContent.current,
                  categories,
                  user: userId,
                  input_ids,
                  chain_prompt: false,
                };

                await updateCustomPrompt({
                  id: customPromptId,
                  body: updateCustomPromptBody,
                }).unwrap();
                onSuccessCreateOrEditCustomPrompt();
              }
              if (values.prompt_type === CustomPromptTypes.chain) {
                if (values.steps.some(step => !step.prompt_template?.trim())) {
                  handleError('All prompts in the chain should be filled');

                  return;
                }
                const customPromptId = currEditPrompt.id;
                const chainInputsBody = chainInputHashtags.map(inputTag => {
                  const updatedInputValue = chainPromptInputValues[inputTag.variable_name];

                  return {
                    user_prompt: customPromptId,
                    variable_name: inputTag.variable_name,
                    placeholder:
                      updatedInputValue || updatedInputValue === ''
                        ? updatedInputValue
                        : inputTag.placeholder,
                  };
                });
                const resCreateCustomInputField = await createCustomInputField(
                  chainInputsBody
                ).unwrap();
                const input_ids = Object.entries(resCreateCustomInputField)?.map(
                  ([, value]) => (value as { id: number })?.id
                );
                const stepsBody = values.steps.map(step => ({
                  ...step,
                  user_prompt: customPromptId,
                }));
                const resCreateCustomPromptSteps = await createCustomPromptSteps(
                  stepsBody
                ).unwrap();
                const stepsIds = Object.entries(resCreateCustomPromptSteps)?.map(
                  ([, value]) => (value as { id: number })?.id
                );

                const createCustomPromptChainBody: IUpdateCustomPromptRequest = {
                  name: values.title,
                  description: values.description,
                  categories,
                  user: userId,
                  input_ids,
                  chain_prompt: true,
                  steps: stepsIds,
                };

                await updateCustomPrompt({
                  id: customPromptId,
                  body: createCustomPromptChainBody,
                }).unwrap();
                onSuccessCreateOrEditCustomPrompt();
              }
            } catch (error) {
              handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
              console.log(error);
            }
          } else {
            try {
              const categories = values.category.map(c => c.id);

              if (values.prompt_type === CustomPromptTypes.single) {
                const createCustomPromptBody: ICreateCustomPromptRequest = {
                  name: values.title,
                  description: values.description,
                  categories,
                  user: userId,
                  prompt_template: values.prompt,
                  prompt_markdown_template: htmlContent.current,
                };

                const resCreateCustomPrompt = await createCustomPrompt(
                  createCustomPromptBody
                ).unwrap();
                const user_prompt = resCreateCustomPrompt?.id;
                const inputsBody = inputHashtags.map(inputTag => ({
                  user_prompt,
                  variable_name: inputTag.variable_name,
                  placeholder: singlePromptInputValues[inputTag.variable_name] || '',
                }));

                await createCustomInputField(inputsBody).unwrap();
                onSuccessCreateOrEditCustomPrompt();
              }

              if (values.prompt_type === CustomPromptTypes.chain) {
                if (values.steps.some(step => !step.prompt_template?.trim())) {
                  handleError('All prompts in the chain should be filled');

                  return;
                }
                const createCustomPromptChainBody: ICreateCustomPromptRequest = {
                  name: values.title,
                  description: values.description,
                  categories,
                  user: userId,
                  chain_prompt: true,
                };

                const resCreateCustomPrompt = await createCustomPrompt(
                  createCustomPromptChainBody
                ).unwrap();
                const user_prompt = resCreateCustomPrompt?.id;
                const stepsBody = values.steps.map(step => ({
                  ...step,
                  user_prompt,
                }));

                await createCustomPromptSteps(stepsBody).unwrap();
                const chainInputsBody = chainInputHashtags.map(inputTag => ({
                  user_prompt,
                  variable_name: inputTag.variable_name,
                  placeholder: chainPromptInputValues[inputTag.variable_name] || '',
                }));

                await createCustomInputField(chainInputsBody).unwrap();
                onSuccessCreateOrEditCustomPrompt();
              }
            } catch (error) {
              if (typeof error?.data?.detail === 'string') {
                handleError(error.data?.detail);
              } else {
                handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
              }
              console.error(error);
            }
          }
        }}
      >
        {({ values, errors, touched }) => (
          <Form
            className={cn(styles.createCustomPromptForm, {
              [styles.createPromptFieldError]: errors['prompt'] && touched['prompt'],
            })}
          >
            <div className={styles.createCustomPromptTitleAndBtnWrapper}>
              <h1>Create custom prompt</h1>
              {!isEditModeDisabled && (
                <Button disabled={isPageDataLoading}>{isEditMode ? 'Edit' : 'Create'}</Button>
              )}
            </div>
            <div className={styles.createCustomPromptDivider} />
            <div className={styles.createCustomPromptContentWrapper}>
              <div className={styles.createCustomPromptLeftContent}>
                <div className={styles.createCustomPromptFieldWrapper}>
                  <div>Prompt Type</div>
                  <div className={styles.radioButtonsWrapper}>
                    <RadioButton
                      name="prompt_type"
                      value="single"
                      label="Single"
                      disabled={isEditModeDisabled || isPageDataLoading}
                    />
                    <RadioButton
                      name="prompt_type"
                      value="chain"
                      label="Chain"
                      disabled={isEditModeDisabled || isPageDataLoading}
                    />
                  </div>
                </div>
                <div className={styles.createCustomPromptFieldWrapper}>
                  <div>Title</div>
                  <CreatePromptTextAreaField
                    name="title"
                    minRows={1}
                    disabled={isEditModeDisabled || isPageDataLoading}
                    placeholder="Enter prompt title"
                    maxLength={100}
                    isError={!!(errors['title'] && touched['title'])}
                  />
                  <ErrorMessage
                    component="div"
                    name="title"
                    className={styles.customPromptInputError}
                  />
                </div>
                <div className={styles.createCustomPromptFieldWrapper}>
                  <div>Description</div>
                  <CreatePromptTextAreaField
                    name="description"
                    minRows={2}
                    disabled={isEditModeDisabled || isPageDataLoading}
                    placeholder="Briefly describe the purpose of your prompt"
                    isError={!!(errors['description'] && touched['description'])}
                  />
                  <ErrorMessage
                    component="div"
                    name="description"
                    className={styles.customPromptInputError}
                  />
                </div>
                <div className={styles.createCustomPromptFieldWrapper}>
                  <div>Main category</div>
                  <CategoryField
                    name="category"
                    options={changedCategories}
                    disabled={isEditModeDisabled || isPageDataLoading}
                    placeholder="Select a category"
                    isError={!!(errors['category'] && touched['category'])}
                  />
                  <ErrorMessage
                    component="div"
                    name="category"
                    className={styles.customPromptInputError}
                  />
                </div>
                <div className={styles.createCustomPromptDivider} />
                <div className={styles.createCustomPromptInputTagsAndTitleWrapper}>
                  <div className={styles.createCustomPromptTagsTitle}>
                    Input tags created will appear below
                  </div>
                  {values.prompt_type === CustomPromptTypes.single && (
                    <div className={styles.createCustomPromptInputTagsWrapper}>
                      {inputHashtags?.map(inputTag => (
                        <HashtagTextAreaField
                          input={inputTag}
                          key={inputTag.id}
                          onChange={handlSinglePromptInputChange}
                          disabled={isPageDataLoading}
                          value={singlePromptInputValues[inputTag.variable_name]}
                        />
                      ))}
                    </div>
                  )}
                  {values.prompt_type === CustomPromptTypes.chain && (
                    <div
                      className={styles.createCustomPromptInputTagsWrapper}
                      key={CustomPromptTypes.chain}
                    >
                      {chainInputHashtags?.map(chainInputTag => (
                        <HashtagTextAreaField
                          input={chainInputTag}
                          key={chainInputTag.id}
                          onChange={handleChainPromptInputChange}
                          disabled={isPageDataLoading}
                          value={chainPromptInputValues[chainInputTag.variable_name]}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.createCustomPromptRightContent}>
                <div className={styles.createCustomPromptRightContentTitle}>Prompt</div>
                {values.prompt_type === CustomPromptTypes.single && (
                  <>
                    <div className={styles.createCustomPromptEditorWrapper}>
                      <CreatePromptField
                        hashtags={dataTags?.results}
                        name="prompt"
                        disabled={isEditModeDisabled || isPageDataLoading}
                        hashtagItems={hashtagItems}
                        htmlContent={htmlContent}
                        onSetNewHashtagItems={handleNewHashtagItems}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="prompt"
                      className={styles.customPromptInputError}
                    />
                  </>
                )}
                {values.prompt_type === CustomPromptTypes.chain && (
                  <>
                    <PromptChainList
                      name="steps"
                      hashtags={dataTags?.results}
                      disabled={isEditModeDisabled || isPageDataLoading}
                      hashtagItems={hashtagItems}
                      onSetNewChainHashtagItems={handleNewChainHashtagItems}
                      chainPromptId={currEditPrompt?.id}
                    />
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const getTransformedHashtags = (hashItems: ICustomInput[], newHashItems: string[]) => {
  const filteredObjects = hashItems.filter(obj => newHashItems.includes(obj.variable_name));

  newHashItems.forEach(hash => {
    if (!filteredObjects.some(obj => obj.variable_name === hash)) {
      filteredObjects.push({
        id: uuidv4() as any,
        variable_name: hash,
        placeholder: '',
        is_textarea: false,
      });
    }
  });

  return filteredObjects;
};

const getTransformedChainHashtags = (
  hashItems: ICustomInput[],
  newHashItems: string[],
  prompTemplates: string
) => {
  const filteredObjects = hashItems.filter(obj => {
    return (
      newHashItems.includes(obj.variable_name) || prompTemplates.includes(`[${obj.variable_name}]`)
    );
  });

  newHashItems.forEach(hash => {
    if (!filteredObjects.some(obj => obj.variable_name === hash)) {
      filteredObjects.push({
        id: uuidv4() as any,
        variable_name: hash,
        placeholder: '',
        is_textarea: false,
      });
    }
  });

  return filteredObjects;
};
