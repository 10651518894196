import { Link } from 'react-router-dom';

const alchemyUrl =
  process.env.NODE_ENV !== 'production' ? 'stage.alchemy-app.com' : 'web.alchemy-app.com';

export interface IAuthBlockFooter {
  isBlob?: boolean;
}

export function AuthBlockFooter(props: IAuthBlockFooter) {
  return (
    <p className="text-links">
      By continuing, you agree to Alchemy’s{' '}
      <Link to={`https://${alchemyUrl}/terms-of-service`}>Terms & Conditions</Link> and{' '}
      <Link to="https://alchemy-app.notion.site/Privacy-Policy-ef58f155eb6b469eb144fa4907cce6aa">
        Privacy Policy
      </Link>
      {!props.isBlob && ', and to receive periodic emails with updates.'}
    </p>
  );
}
