import { createApi } from '@reduxjs/toolkit/query/react';

import * as I from '../models/ISnippet';
import { baseQuery } from '../utils/baseQuery';

export const snippetsApi = createApi({
  reducerPath: 'snippetsApi',
  baseQuery,
  tagTypes: ['Snippets'],
  endpoints: builder => ({
    getSnippets: builder.query<I.ISnippetsListResponse, void>({
      query: () => '/sidebar/get-snippets/',
      providesTags: () => ['Snippets'],
      keepUnusedDataFor: 120,
    }),
    deleteSnippet: builder.mutation<unknown, number>({
      query: id => ({ url: `/sidebar/delete-snippet/${id}`, method: 'DELETE' }),
      invalidatesTags: () => ['Snippets'],
    }),
    updateSnippet: builder.mutation<I.ISnippet, I.ICreatedSnippet>({
      query: params => {
        const { id, ...rest } = params || {};

        return { url: `/sidebar/update-snippet/${id}`, body: rest, method: 'PUT' };
      },
      invalidatesTags: () => ['Snippets'],
    }),
    createSnippet: builder.mutation<I.ICreatedSnippet, I.ISnippet>({
      query: body => ({ url: '/sidebar/create-snippet/', body, method: 'POST' }),
      invalidatesTags: () => ['Snippets'],
    }),
  }),
});

export const {
  useGetSnippetsQuery,
  useCreateSnippetMutation,
  useDeleteSnippetMutation,
  useUpdateSnippetMutation,
} = snippetsApi;
