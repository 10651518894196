import detectUrlChange from 'detect-url-change';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AlchemyModel,
  ConversationType,
  IConversation,
} from '../../external/extension/service/base/ai/interfaces';
import { useExtensionServiceContext } from '../../external/extension/service/context';
import { useAsyncProcessManagerContext } from '../../external/extension/tools/async/context';
import { LoadingOverlay } from '../../external/extension/ui/design/components/loading';
import { ChatScreen } from '../../external/extension/ui/screens/chat';

export interface IChatPage {
  mode?: 'default' | 'synth' | 'search';
}
export function ChatPage({ mode = 'default' }: IChatPage) {
  const [activeConversation, setActiveConversation] = useState<IConversation | null>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const [isConversationLoading, setIsConversationLoading] = useState<boolean>(true);

  const asyncProcessManager = useAsyncProcessManagerContext();
  const extensionService = useExtensionServiceContext();

  const location = useLocation();
  const navigate = useNavigate();

  const user = extensionService.useExtensionUser();

  const [realURL, setRealURL] = useState<string>(window.location.href);

  useEffect(() => {
    const updateURL = (currentUrl: string) => {
      setRealURL(currentUrl);
    };

    updateURL(window.location.href);

    detectUrlChange.on('change', updateURL);

    return () => {
      detectUrlChange.off('change', updateURL);
    };
  }, []);

  useEffect(() => {
    if (isGenerating) return;

    if (user.session.tier === null) return;

    const searchParams = new URLSearchParams(location.search);

    const chatIdFromGet = searchParams.get('id');
    const chatId = chatIdFromGet ? extensionService.encryptor.decrypt(chatIdFromGet) : null;

    asyncProcessManager.doProcess({
      name: 'Fetch chat messages',
      onError: () => {
        setActiveConversation(null);
        setIsConversationLoading(false);

        return false;
      },
      action: async () => {
        if (chatId) {
          setIsConversationLoading(true);

          const response = await extensionService.getSidebarConversationMessages(chatId as any);

          if (response.data) {
            setActiveConversation({
              id: +chatId,
              model: AlchemyModel.Claude35Sonnet,
              chat_type: ConversationType.Chat,
              name: 'New chat',
              messages: response.data,
              current_message_id: response.current_message_id,
            });
          } else {
            setActiveConversation(null);
          }

          setIsConversationLoading(false);
        } else {
          setActiveConversation(null);
          setIsConversationLoading(false);

          const pathToNavigate =
            mode === 'default'
              ? '/chat'
              : mode === 'synth'
              ? '/synthesize'
              : mode === 'search'
              ? '/search'
              : '/';

          if (window.location.pathname !== pathToNavigate || window.location.search.length > 0) {
            if (window.location.href !== pathToNavigate) navigate(pathToNavigate);
          }
        }
      },
    });
  }, [realURL, user.session.tier]);

  return (
    <div className="blob-sidebar-wrapper">
      <ChatScreen
        visible={!isConversationLoading}
        mode={mode}
        activeConversation={activeConversation}
        setActiveConversation={setActiveConversation}
        isGenerating={isGenerating}
        setIsGenerating={setIsGenerating}
      />
      <LoadingOverlay active={isConversationLoading} />
    </div>
  );
}
