import { FC } from 'react';

import { CheckedSubscribtionIcon } from '../../../../../assets/svg/CheckedSubscribtionIcon';
import { UncheckedSubscribtionicon } from '../../../../../assets/svg/UncheckedSubscribtionicon';
import { SUBSCRIPTION_TYPE } from '../../../../../models/ISubscription';
import styles from './SubscribtionType.module.scss';

interface IProps {
  title: string;
  price: string;
  type: SUBSCRIPTION_TYPE;
  isSelected: boolean;
  onSelectType: () => void;
}

export const SubscribtionType: FC<IProps> = ({ title, price, type, isSelected, onSelectType }) => {
  return (
    <button className={styles.subscribtionType} onClick={onSelectType}>
      <div className={styles.subscribtionTypeInfo}>
        <div className={styles.subscribtionTypeTitle}>
          {title} {type === SUBSCRIPTION_TYPE.YEAR && <span>15% OFF</span>}
        </div>
        <div className={styles.subscribtionTypePrice}>
          {price} <span>per month</span>
        </div>
        <div className={styles.subscribtionCancel}>cancel anytime</div>
      </div>
      {isSelected ? <CheckedSubscribtionIcon /> : <UncheckedSubscribtionicon />}
    </button>
  );
};
