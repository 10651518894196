export interface IMicrophoneIcon {
  color?: string;
  className?: string;
}

export default function MicrophoneIcon(props: IMicrophoneIcon) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ?? ''}
      style={{ fill: 'none' }}
    >
      <path
        d="M7.29102 6.66732C7.29102 5.17155 8.50358 3.95898 9.99935 3.95898C11.4951 3.95898 12.7077 5.17155 12.7077 6.66732V9.16732C12.7077 10.6631 11.4951 11.8757 9.99935 11.8757C8.50358 11.8757 7.29102 10.6631 7.29102 9.16732V6.66732Z"
        stroke={props.color ?? 'var(--app-light)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.79102 10.625C4.79102 10.625 4.99935 14.375 9.99935 14.375C14.9993 14.375 15.2077 10.625 15.2077 10.625"
        stroke={props.color ?? 'var(--app-light)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.791V16.041"
        stroke={props.color ?? 'var(--app-light)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
