import { Form, Formik } from 'formik';
import { FC } from 'react';

import { Button } from '../../../components/Button';
import { ISnippet } from '../../../models/ISnippet';
import { errorMessages } from '../../../services/errors';
import { useCreateSnippetMutation, useUpdateSnippetMutation } from '../../../services/snippets';
import { handleError, handleSuccess } from '../../../utils/notifications';
import { ISnippetContent, SNIPPET_MODE } from '../types';
import styles from './SnippetContent.module.scss';
import { SnippetContentInput } from './SnippetContentInput';
import { SnippetContentTextArea } from './SnippetContentTextArea';
import { validationService } from './validationService';

export const SnippetContent: FC<ISnippetContent> = ({
  selectedSnippet,
  mode,
  onResetModeAndSelectedSnippetId,
}) => {
  const [createSnippet, { isLoading: isLoadingCreateSnippet }] = useCreateSnippetMutation();
  const [updateSnippet, { isLoading: isLoadingUpdateSnippet }] = useUpdateSnippetMutation();
  const isLoading = isLoadingCreateSnippet || isLoadingUpdateSnippet;
  const { id: selectedSnippetId, code, text } = selectedSnippet || {};
  const { validationSchema } = validationService();
  const isUpdateMode = mode === SNIPPET_MODE.UPDATE;

  const handleCreateSnippet = async (values: ISnippet) => {
    const { code, text } = values || {};

    try {
      await createSnippet({ code: code.trim(), text: text.trim() }).unwrap();
      onResetModeAndSelectedSnippetId();
      handleSuccess('The snippet has been successfully created');
    } catch (error) {
      if (error?.data?.non_field_errors) {
        handleError(errorMessages.ALREADY_EXISTS_SNIPPET);
      } else if (error?.data?.detail) {
        handleError(error.data.detail);
      } else {
        handleError(errorMessages.SNIPPET_NOT_CREATED);
      }
      console.error(error);
    }
  };

  const handleUpdateSnippet = async (values: ISnippet) => {
    const { code, text } = values || {};

    try {
      await updateSnippet({ id: selectedSnippetId, code: code.trim(), text: text.trim() }).unwrap();
      onResetModeAndSelectedSnippetId();
      handleSuccess('The snippet has been successfully updated');
    } catch (error) {
      if (error?.data?.non_field_errors) {
        handleError(errorMessages.ALREADY_EXISTS_SNIPPET);
      } else {
        handleError(errorMessages.SNIPPET_NOT_CREATED);
      }
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={{
        code: isUpdateMode ? code : '',
        text: isUpdateMode ? text : '',
      }}
      validationSchema={validationSchema}
      onSubmit={isUpdateMode ? handleUpdateSnippet : handleCreateSnippet}
    >
      <Form method="POST" className={styles.snippetForm}>
        <SnippetContentInput id="code" name="code" label="Keyword" placeholder="Add your keyword" />
        <SnippetContentTextArea
          id="text"
          name="text"
          label="Snippet Text"
          placeholder="Add your snippet text"
        />
        <div className={styles.snippetFormBtnsWrapper}>
          <Button
            type="button"
            isSecondary
            disabled={isLoading}
            onClick={onResetModeAndSelectedSnippetId}
          >
            Cancel
          </Button>
          <Button disabled={isLoading}>{isUpdateMode ? 'Update' : 'Create'}</Button>
        </div>
      </Form>
    </Formik>
  );
};
