import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const LeftAlignmentIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M7.29199 11.0422L7.29199 8.95866C7.29199 8.03818 8.03818 7.29199 8.95866 7.29199L14.3753 7.29199C15.2958 7.29199 16.042 8.03819 16.042 8.95866V11.0422C16.042 11.9627 15.2958 12.7089 14.3753 12.7089L8.95866 12.7089C8.03818 12.7089 7.29199 11.9627 7.29199 11.0422Z"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.95801 3.95801V16.0413"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
