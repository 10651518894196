import './index.scss';

import { Dispatch, SetStateAction } from 'react';

import { CloseIcon } from '../../../../design/assets/svg/icons/CloseIcon';
import SearchIcon from '../../../../design/assets/svg/icons/SearchIcon';
import { SmoothVisibility } from '../../../../design/components/smoothVisibility';

export interface IDocumentsSearch {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
}

export function DocumentsSearch(props: IDocumentsSearch) {
  return (
    <div className={'documents-search-bar' + (props.query.length > 0 ? ' has-value' : '')}>
      <SearchIcon />{' '}
      <input
        onChange={e => {
          props.setQuery(e.target.value);
        }}
        value={props.query}
        type=""
        placeholder="Search"
      />
      <SmoothVisibility
        className="clear-button"
        as="button"
        onClick={() => props.setQuery('')}
        visible={props.query.length > 0}
      >
        <CloseIcon width={14} />
      </SmoothVisibility>
    </div>
  );
}
