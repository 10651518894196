import cn from 'classnames';
import { FieldAttributes, useField } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { selectTheme, ThemeOptions } from '../../../../../redux/Theme';
import styles from './CreatePromptTextAreaField.module.scss';

export const CreatePromptTextAreaField: React.FC<FieldAttributes<any>> = ({
  className,
  maxLength,
  isError,
  ...props
}) => {
  const [field] = useField(props);
  const currentTheme = useTypedSelector(selectTheme);

  return (
    <TextareaAutosize
      className={cn(styles.customPromptTextArea, className, {
        [styles.purpleOutline]: currentTheme === ThemeOptions.Purple,
        [styles.customPromptTextAreaError]: isError,
      })}
      maxRows={9}
      maxLength={maxLength}
      {...field}
      {...props}
    />
  );
};
