/* eslint-disable no-case-declarations */
import './index.scss';

import {
  ClipboardEvent,
  Fragment,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { IPrompt } from '../../../../../interfaces';
import { ExtensionService } from '../../../../../service';
import { BaseExtensionService } from '../../../../../service/base';
import { AlchemyModel } from '../../../../../service/base/ai/interfaces';
import { ITextareaCommand } from '../../../../../service/base/interfaces';
import { IUploadItem, UploadStatus } from '../../../../../service/base/uploads';
import { useExtensionServiceContext } from '../../../../../service/context';
import { ExtensionEvents, useExtensionEventListener } from '../../../../../service/events';
import { ExtensionEventType } from '../../../../../service/events/types';
import { useAsyncProcessManagerContext } from '../../../../../tools/async/context';
import { FileTools } from '../../../../../tools/file';
import { GraphicTools } from '../../../../../tools/graphics';
import { StringTools } from '../../../../../tools/string';
import ArrowDownIcon from '../../../../design/assets/svg/icons/ArrowDownIcon';
import ArrowRightAltIcon from '../../../../design/assets/svg/icons/ArrowRightAltIcon';
import AttachmentFileIcon from '../../../../design/assets/svg/icons/AttachmentFileIcon';
import { CloseIcon } from '../../../../design/assets/svg/icons/CloseIcon';
import DocumentIcon from '../../../../design/assets/svg/icons/DocumentIcon';
import SendIcon from '../../../../design/assets/svg/icons/SendIcon';
import StopIcon from '../../../../design/assets/svg/icons/StopIcon';
import { Dropdown } from '../../../../design/components/dropdown';
import { LoadingSpinner } from '../../../../design/components/loading';
import { CircleLoader } from '../../../../design/components/loading/progress/circle';
import { SmoothVisibility } from '../../../../design/components/smoothVisibility';
import { VoiceInput } from '../../../../design/components/voice';
import { FloatingSelectedCommand } from './components/command';
import { ITextareaCommandBE, TextAreaCommand } from './components/commands';

interface IPromptTextarea {
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  conversationModel: AlchemyModel;
  setConversationModel: (model: AlchemyModel) => void;
  setSidebarModel?: (model: AlchemyModel) => void;
  isPromptSending: boolean;
  placeholder?: string;
  onSend: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    customPromptText?: string,
    customPromptAlias?: string
  ) => void;
  onPaste?: (e: ClipboardEvent<HTMLElement>) => void;
  maxRows?: number;
  minRows?: number;
  disableImageGenerativeModels?: boolean;
  onModalPromptSended?: () => void;
  containerRef?: RefObject<HTMLDivElement>;
  modelDropdownVerticalPosition?: 'top' | 'bottom';
  modelDropdownHorizontalPosition?: 'left' | 'center' | 'right';
  isInToolbar?: boolean;
  hideModels?: boolean;
  customButtons?: ReactNode | ReactNode[];
  onShowCommands?: () => void;
  onHideCommands?: () => void;
  attachmentsSection?: {
    items: IUploadItem[];
    upload: (file: File) => Promise<void>;
    removeFile: (id: string) => void;
    clearAll: () => void;
  };
}

export enum CommandsMode {
  Default,
  QuickPrompts,
}

export const PromptTextarea = (props: IPromptTextarea) => {
  const [commandsMode, setCommandsMode] = useState<CommandsMode>(CommandsMode.Default);
  const [isShowTextAreaCommand, setIsShowTextAreaCommand] = useState<boolean>(false);
  const [isShowSelectedCommand, setIsShowSelectedCommand] = useState<boolean>(false);
  const [currHoverPrompsterIndex, setCurrHoverPrompsterIndex] = useState(0);

  const [commandsList, setCommandsList] = useState<ITextareaCommand[] | undefined>();

  const [changedCommandsList, setChangedCommandsList] = useState<ITextareaCommand[]>([]);

  const [quickPromptsList, setQuickPromptsList] = useState<ITextareaCommand[] | undefined>();

  const [isListening, setIsListening] = useState<boolean>(false);

  const [currentPrompt, setCurrentPrompt] = useState<IPrompt | null>(null);

  const asyncProcessManager = useAsyncProcessManagerContext();
  const extensionService = useExtensionServiceContext();

  useExtensionEventListener(ExtensionEventType.ShowQuickPrompts, () => {
    setCommandsMode(CommandsMode.QuickPrompts);
    setIsShowTextAreaCommand(true);
  });

  const user = extensionService.useExtensionUser();

  useEffect(() => {
    if (!user.profile) return;

    asyncProcessManager?.doProcess({
      name: 'Get quick prompts',
      action: async () => {
        const response = await extensionService.getQuickPrompts();

        if (!!response && response?.status >= 200 && response?.status < 400) {
          const data = response?.data;

          let results: ITextareaCommand[] = (data.results as ITextareaCommandBE[]).map(c => ({
            ...c,
            _hovered: false,
          }));

          results = results?.map(p =>
            p.command.includes('/') ? p : { ...p, command: `/${p.command}` }
          );

          setQuickPromptsList(results);
        }
      },
    });
  }, [extensionService, user.profile]);

  useEffect(() => {
    if (!user.profile) return;

    const getCommandList = () => {
      asyncProcessManager?.doProcess({
        name: 'Get commands',
        action: async () => {
          // setIsLoading(true);
          const response = await extensionService.getCommands();

          if (!!response && response?.status >= 200 && response?.status < 400) {
            const data = response?.data;

            let results: ITextareaCommand[] = (data.results as ITextareaCommandBE[]).map(c => ({
              ...c,
              _hovered: false,
            }));

            results = results?.map(p =>
              p.command.includes('/') ? p : { ...p, command: `/${p.command}` }
            );

            setCommandsList(results);
          }
        },
      });
    };

    getCommandList();

    document.addEventListener('addCommand', getCommandList);

    return () => {
      document.removeEventListener('addCommand', getCommandList);
    };
  }, [extensionService, user.profile]);

  useEffect(() => {
    setTimeout(() => {
      setChangedCommandsList(commandsList ?? []);
    }, 50);
  }, [commandsList]);

  const filterPrompsterItems = (searchText: any) => {
    setChangedCommandsList(() => {
      if (searchText === '') {
        setCurrHoverPrompsterIndex(0);

        return commandsList ?? [];
      } else {
        setCurrHoverPrompsterIndex(0);

        return [...(commandsList ?? [])].filter((c: any) =>
          c.command.toLowerCase().includes(searchText.toLowerCase())
        );
      }
    });
  };

  useEffect(() => {
    if (!isShowTextAreaCommand) return;
    filterPrompsterItems(props.prompt);
  }, [props.prompt]);

  useEffect(() => {
    if (!isShowTextAreaCommand) {
      setTimeout(() => {
        filterPrompsterItems('');
      }, 300);
    }
  }, [isShowTextAreaCommand]);

  useEffect(() => {
    const selectorUlPromster = document?.querySelector('#alchemy-commands-list');

    if (selectorUlPromster) {
      const childs = selectorUlPromster.querySelectorAll('.alchemy-command');

      selectorUlPromster.scrollTop =
        parseInt((childs[currHoverPrompsterIndex] as any)?.offsetTop ?? 0) - 30;

      const item = childs[currHoverPrompsterIndex] as any;

      setTimeout(() => {
        item?.focus();
      }, 1000);
    }
  }, [currHoverPrompsterIndex]);

  const willChangeHoverItem = useCallback(
    (currHoverPrompsterIndex: any) => {
      setChangedCommandsList((pre: any) =>
        pre.map((p: any, idx: any) =>
          idx === currHoverPrompsterIndex ? { ...p, _hovered: true } : { ...p, _hovered: false }
        )
      );
    },
    [setChangedCommandsList]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    switch (event.key) {
      case 'ArrowUp':
        const newHoverIndexUp = currHoverPrompsterIndex > 0 ? currHoverPrompsterIndex - 1 : 0;

        setCurrHoverPrompsterIndex(newHoverIndexUp);
        willChangeHoverItem(newHoverIndexUp);
        break;

      case 'ArrowDown':
        const lastIndex = changedCommandsList.length - 1;
        const newHoverIndexDown =
          currHoverPrompsterIndex < lastIndex ? currHoverPrompsterIndex + 1 : lastIndex;

        setCurrHoverPrompsterIndex(newHoverIndexDown);
        willChangeHoverItem(newHoverIndexDown);
        break;

      case 'Escape':
        setIsShowTextAreaCommand(false);
        break;

      case 'Backspace':
        if (props.prompt.length === 0 || props.prompt === '/') {
          setIsShowTextAreaCommand(false);
        }
        break;

      case '/':
        if (props.prompt.length === 0 || props.prompt === '/') {
          event.preventDefault();

          setCommandsMode(CommandsMode.Default);
          setIsShowTextAreaCommand(true);
          setCurrHoverPrompsterIndex(0);
          willChangeHoverItem(0);
        }
        break;

      case 'Enter':
        if (isShowTextAreaCommand) {
          event.preventDefault();
          event.stopPropagation();
          if ((commandsList?.length ?? 0) > 0) {
            const currHoverCommand = document?.querySelector('#root .item-hover') as any;

            currHoverCommand?.click();
            setChangedCommandsList(commandsList ?? []);
            setIsShowTextAreaCommand(false);
            setCurrHoverPrompsterIndex(0);
          }
          break;
        }

        if (!event.shiftKey) {
          event.preventDefault();
          ExtensionEvents.dispatch(ExtensionEventType.RefreshVoiceInputText);
          setTimeout(() => {
            props.onSend();
          }, 100);
        }
        break;

      default:
        break;
    }
  };

  const onClickCommand = useCallback(
    (command: ITextareaCommand) => {
      const relatedPrompt: IPrompt | null = BaseExtensionService.getPromptFromCommand(
        command,
        true
      );

      if (relatedPrompt) {
        setCurrentPrompt(relatedPrompt);
        setIsShowSelectedCommand(true);
        setIsShowTextAreaCommand(false);

        return;
      }

      props.setPrompt(command.prompt);
      setIsShowTextAreaCommand(false);

      props.onSend(undefined, command.prompt, command.prompt);
    },
    [props]
  );

  const onClickSendPrompt = useCallback(
    (inputValues: { [key: string]: string }) => {
      if (!currentPrompt) return;

      setIsShowSelectedCommand(false);

      const promptText = ExtensionService.fillPromptTemplate(currentPrompt, inputValues);
      const promptAlias = ExtensionService.generatePromptAlias(currentPrompt, inputValues);

      props.onSend(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }) as any,
        promptText,
        promptAlias
      );

      props.onModalPromptSended?.();

      asyncProcessManager?.doProcess({
        name: 'Send prompt',
        action: async () => {
          await extensionService.clickSendPrompt({
            prompt_extension_pk: currentPrompt?.id,
          });
        },
      });
    },
    [currentPrompt]
  );

  const models = Object.values(AlchemyModel).filter(model =>
    props.disableImageGenerativeModels
      ? ![AlchemyModel.DALLE3, AlchemyModel.ChatPDF].includes(model)
      : true
  );
  const sendBtnRef = useRef<HTMLButtonElement>(null);

  const showCommands = isShowTextAreaCommand && !props.isPromptSending;

  useEffect(() => {
    if (showCommands) {
      props.onShowCommands?.();
    } else {
      props.onHideCommands?.();
    }
  }, [showCommands]);

  const handleFileUploadButtonClick = async () => {
    const file = await FileTools.uploadFile(['png', 'jpeg', 'jpg', 'gif', 'pdf']);

    if (file) props.attachmentsSection?.upload(file);
  };

  const attachments = props.attachmentsSection?.items ?? [];

  function handleSuggestionSend(suggestion: string) {
    props.onSend(undefined, suggestion);
  }

  return (
    <div
      ref={props.containerRef}
      className={
        'textarea-wrapper' + (showCommands && props.isInToolbar ? ' commands-visible' : '')
      }
    >
      <TextAreaCommand
        design={'alternative'}
        visible={showCommands}
        title={'Quick Prompts'}
        commandItems={
          commandsMode === CommandsMode.Default ? changedCommandsList : quickPromptsList ?? []
        }
        onClickOutside={() => setIsShowTextAreaCommand(false)}
        onClickCommand={(command: ITextareaCommand) => onClickCommand(command)}
      />
      <FloatingSelectedCommand
        visible={isShowSelectedCommand}
        prompt={currentPrompt}
        onClose={() => {
          setIsShowSelectedCommand(false);
          setCurrentPrompt(null);
          setIsShowTextAreaCommand(true);
        }}
        onClickSendPrompt={onClickSendPrompt}
      />
      <ReactTextareaAutosize
        onPaste={props.onPaste}
        placeholder={props.placeholder ?? 'Make your magic...'}
        value={props.prompt}
        onChange={e => props.setPrompt(e.target.value)}
        maxRows={props.maxRows ?? 5}
        minRows={props.minRows ?? 2}
        onKeyDown={handleKeyDown}
        style={{ transition: 'all 0.3s ease' }}
      />
      <div className="textarea-buttons-wrapper">
        <SmoothVisibility className="attachments-section" visible={!!props.attachmentsSection}>
          {(props.attachmentsSection?.items.length ?? 0) > 0 ? (
            <div className="attachments">
              {props.attachmentsSection?.items.map((attachment: IUploadItem, index: number) => {
                const uploadStatus = (
                  <div
                    onClick={async () => {
                      if (
                        attachment.status !== UploadStatus.Uploading &&
                        attachment.mime.startsWith('image')
                      )
                        await GraphicTools.viewImage(attachment.preview);
                    }}
                    className={`attachment-status ${attachment.status}`}
                  >
                    {attachment.status === 'uploading' ? (
                      attachment.uploadPercent === 100 ? (
                        <LoadingSpinner width={20} color="white" />
                      ) : (
                        <CircleLoader progress={attachment.uploadPercent ?? 0} />
                      )
                    ) : null}
                    {attachment.error && <span>Error</span>}
                  </div>
                );

                return (
                  <Fragment key={index}>
                    <div
                      className={
                        'attachment-item ' +
                        (attachment.mime.startsWith('image') ? 'image-item' : 'file-item')
                      }
                    >
                      {attachment.mime.startsWith('image') ? (
                        <div
                          className="attachment-image"
                          style={{
                            backgroundImage: `url(${attachment.preview})`,
                          }}
                        />
                      ) : (
                        <div className="attachment-file">
                          <div className="attachment-icon">
                            <DocumentIcon />
                            {uploadStatus}
                          </div>

                          <div className="attachment-meta">
                            <span className="title">{attachment.filename}</span>
                            <span className="filetype">
                              {StringTools.getFileTypeInfo(attachment.filename)?.title}
                            </span>
                          </div>
                        </div>
                      )}

                      {attachment.mime.startsWith('image') && uploadStatus}

                      <div className="image-item-btn-wrapper">
                        <button
                          className="attachment-delete"
                          onClick={() => props.attachmentsSection.removeFile(attachment.id)}
                        >
                          <CloseIcon color="white" />
                        </button>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <button className="upload-button" onClick={handleFileUploadButtonClick}>
              <AttachmentFileIcon />
            </button>
          )}
        </SmoothVisibility>

        <Dropdown
          hidden={props.hideModels}
          verticalPosition={props.modelDropdownVerticalPosition}
          horizontalPosition={props.modelDropdownHorizontalPosition}
          className="alchemy-model-dropdown"
          items={models
            .filter(m => m !== AlchemyModel.Claude3Sonnet)
            .map(model => {
              const modelId = model;

              return {
                isLink: true,
                linkExternal: true,
                linkTo: '',
                kind: 'item',
                id: modelId,
                text: modelId,
                onLinkClick: e => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.setConversationModel(model);
                  props.setSidebarModel?.(model);
                },
                active: model === props.conversationModel,
              };
            })}
        >
          <span>{props.conversationModel}</span>
          <ArrowDownIcon color="var(--app-text)" />
        </Dropdown>

        <div className="right-side">
          {props.customButtons}
          <VoiceInput
            prompt={props.prompt}
            setPrompt={props.setPrompt}
            onListeningStopped={() => {
              setTimeout(() => {
                sendBtnRef.current?.focus();
              }, 100);
            }}
            onChange={isListening => {
              setIsListening(isListening);
            }}
          />
          <button
            ref={sendBtnRef}
            onClick={props.onSend}
            className={`${props.prompt && !isListening ? 'active' : ''}`}
            tabIndex={-1}
          >
            {props.isPromptSending ? <StopIcon /> : <SendIcon />}
          </button>
        </div>
      </div>
      <SmoothVisibility
        visible={attachments.length > 0}
        className="textarea-attachment-suggestions-wrapper"
      >
        <>
          {attachments[0]?.mime.startsWith('image') ? (
            <>
              <button
                onClick={() => handleSuggestionSend('Describe the image')}
                className="suggestion"
              >
                <span>Describe the image</span>
                <ArrowRightAltIcon />
              </button>
              <button
                onClick={() => handleSuggestionSend('Extract text from image')}
                className="suggestion"
              >
                <span>Extract text</span>
                <ArrowRightAltIcon />
              </button>
            </>
          ) : (
            <>
              <button onClick={() => handleSuggestionSend('Describe file')} className="suggestion">
                <span>Describe file</span>
                <ArrowRightAltIcon />
              </button>
              <button onClick={() => handleSuggestionSend('Extract text')} className="suggestion">
                <span>Extract text</span>
                <ArrowRightAltIcon />
              </button>
            </>
          )}
        </>
      </SmoothVisibility>
    </div>
  );
};
