import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const UnorderedListIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M5.41634 4.99967C5.41634 5.22979 5.22979 5.41634 4.99967 5.41634C4.76956 5.41634 4.58301 5.22979 4.58301 4.99967C4.58301 4.76956 4.76956 4.58301 4.99967 4.58301C5.22979 4.58301 5.41634 4.76956 5.41634 4.99967Z"
        stroke="#EBECED"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41634 9.99967C5.41634 10.2298 5.22979 10.4163 4.99967 10.4163C4.76956 10.4163 4.58301 10.2298 4.58301 9.99967C4.58301 9.76956 4.76956 9.58301 4.99967 9.58301C5.22979 9.58301 5.41634 9.76956 5.41634 9.99967Z"
        stroke="#EBECED"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41634 14.9997C5.41634 15.2298 5.22979 15.4163 4.99967 15.4163C4.76956 15.4163 4.58301 15.2298 4.58301 14.9997C4.58301 14.7696 4.76956 14.583 4.99967 14.583C5.22979 14.583 5.41634 14.7696 5.41634 14.9997Z"
        stroke="#EBECED"
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 5H15.2083"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 10H15.2083"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.125 15H15.2083"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
