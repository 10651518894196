import React from 'react';

export const WarningIcon = () => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ fill: 'transparent' }}
    >
      <path
        d='M3.95898 7.33268C3.95898 6.41221 4.70518 5.66602 5.62565 5.66602H14.3757C15.2962 5.66602 16.0423 6.41221 16.0423 7.33268V13.5827C16.0423 14.5032 15.2962 15.2493 14.3757 15.2493H12.1882L10.0007 17.7493L7.81315 15.2493H5.62565C4.70518 15.2493 3.95898 14.5032 3.95898 13.5827V7.33268Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 8.37305V10.0397'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.4173 12.5397C10.4173 12.7698 10.2307 12.9564 10.0007 12.9564C9.77057 12.9564 9.58398 12.7698 9.58398 12.5397C9.58398 12.3096 9.77057 12.123 10.0007 12.123C10.2307 12.123 10.4173 12.3096 10.4173 12.5397Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
