import { IUser } from '../../../../models/ISettings';
import { AccountForm } from '../AccountForm';
import styles from './GeneralTabContent.module.scss';

interface IGeneralTabContent {
  generalTabProps: {
    first_name: string;
    last_name: string;
    username: string;
    avatar: string;
    email: string;
    language_preference: string;
  };
  onSetSettings: (settings: IUser) => void;
  isLoading: boolean;
}

export const GeneralTabContent: React.FC<IGeneralTabContent> = ({
  generalTabProps,
  onSetSettings,
  isLoading,
}) => {
  return (
    <div className={styles.generalTab}>
      <h2 className={styles.generalTabTitle}>Account Information</h2>
      <AccountForm {...generalTabProps} onSetSettings={onSetSettings} isLoading={isLoading} />
    </div>
  );
};
