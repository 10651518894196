import { errorMessages } from '../../services/errors';
import { useUpdateDocumentMutation } from '../../services/myContent';
import { handleError } from '../../utils/notifications';

export const useUpdateDocument = () => {
  const [updateDocument] = useUpdateDocumentMutation();

  const handleUpdateDocument = async (id: number, file: string) => {
    try {
      await updateDocument({ id, body: { file } }).unwrap();
    } catch (error) {
      handleError(errorMessages.DOCUMENT_NOT_UPDATED);
      console.error('error', error);
    }
  };

  return { handleUpdateDocument };
};
