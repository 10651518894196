export interface IDocumentPlusIcon {
  size?: number;
}
export default function DocumentPlusIcon(props: IDocumentPlusIcon) {
  return (
    <svg
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.3763 16.0413H6.45964C5.53916 16.0413 4.79297 15.2951 4.79297 14.3747V5.62467C4.79297 4.7042 5.53916 3.95801 6.45964 3.95801H11.668L15.2096 7.49967V9.37467'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.168 12.292V16.042'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.043 14.167H12.293'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.9987 7.70866H11.457V4.16699'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
