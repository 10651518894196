import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const MessageIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" {...props}>
      <path
        d="M7.99984 12.1663C10.3332 12.1663 12.8332 10.9997 12.8332 7.99967C12.8332 4.99967 10.3332 3.83301 7.99984 3.83301C5.6665 3.83301 3.1665 4.99967 3.1665 7.99967C3.1665 8.68621 3.29743 9.27674 3.52732 9.77921C3.67062 10.0925 3.751 10.4379 3.68753 10.7764L3.51273 11.7087C3.42633 12.1695 3.83002 12.5732 4.29084 12.4868L6.42909 12.0859C6.59717 12.0543 6.7695 12.0571 6.93837 12.0839C7.28984 12.1396 7.64677 12.1663 7.99984 12.1663Z"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33317 8.00033C6.33317 8.18439 6.18393 8.33366 5.99984 8.33366C5.81574 8.33366 5.6665 8.18439 5.6665 8.00033C5.6665 7.81626 5.81574 7.66699 5.99984 7.66699C6.18393 7.66699 6.33317 7.81626 6.33317 8.00033Z"
        stroke="#BDBDBD"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33317 8.00033C8.33317 8.18439 8.1839 8.33366 7.99984 8.33366C7.81577 8.33366 7.6665 8.18439 7.6665 8.00033C7.6665 7.81626 7.81577 7.66699 7.99984 7.66699C8.1839 7.66699 8.33317 7.81626 8.33317 8.00033Z"
        stroke="#BDBDBD"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3332 8.00033C10.3332 8.18439 10.1839 8.33366 9.99984 8.33366C9.81577 8.33366 9.6665 8.18439 9.6665 8.00033C9.6665 7.81626 9.81577 7.66699 9.99984 7.66699C10.1839 7.66699 10.3332 7.81626 10.3332 8.00033Z"
        stroke="#BDBDBD"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
