export const errorMessages = {
  USER_ALREADY_EXISTS: 'User already exists',
  GOOGLE_ACCOUNT_ALREADY_REGISTERED: 'This account already exists, try to Log in',
  GOOGLE_ACCOUNT_IS_NOT_REGISTERED: 'This account is not registered, try to Sign up',
  INCORRECT_CREDENTIALS: 'Incorrect credentials',
  INVALID_CODE: 'Invalid code',
  ALREADY_SUBSCRIBED_TO_NEWSLETTER: 'You are already subscribed',
  ALREADY_EXISTS_USERNAME: "The username you've entered is already in use",
  ALREADY_EXISTS_EMAIL: "The email you've entered is already in use",
  ALREADY_EXISTS_EMAIL_AND_USERNAME: "The email and username you've entered are already in use",
  FAILED_PAYMENT: 'Payment failed',
  FAILED_SUBSCRIPTION: 'Subscription failed',
  HTML_DOCUMENT_TEXT_COPYING_FAILED: 'Document copying failed',
  PASSWORD_UPDATE_FAILED: 'Your password has not been changed. Please check your old password.',
  EMAIL_NOT_FOUND: "The email you've entered is not registered",
  ACCOUNT_DELETION_ERROR: 'Your account has not been deleted. Please try again.',
  LOG_OUT_ERROR: 'You have not been logged out. Please try again.',
  ADD_OWN_PROMPT_FORBIDDEN: "You can't add your own prompt",
  DEFAULT_ERROR_MESSAGE: 'Something went wrong.',
  NOT_ENOUGH_LEVEL: "You don't have access to the dashboard yet",
  USER_NOT_IN_WAITING_LIST: 'You need to join Beta waitlist to use the dashboard',
  ALREADY_EXISTS_SNIPPET: 'The snippet with this keyword already exists',
  SNIPPET_NOT_CREATED: 'The snippet has not been created',
  SNIPPET_NOT_UPDATED: 'The snippet has not been updated',
  SNIPPET_NOT_DELETED: 'The snippet has not been deleted',
  DOCUMENT_NOT_CREATED: 'The document has not been created',
  DOCUMENT_NOT_DELETED: 'The document has not been deleted',
  DOCUMENT_NAME_NOT_UPDATED: 'The document name has not been updated',
  DOCUMENT_NOT_UPDATED: 'The document has not been updated',
};
