import { SUBSCRIPTION_TIER, SUBSCRIPTION_TYPE } from '../../models/ISubscription';
import { IBillingCard } from '../../pages/SettingsPage/components/BillingAndPlansTabContent/BillingCard/IBillingCard';

const pricingPlanMonth: IBillingCard[] = [
  {
    id: 1,
    tier: SUBSCRIPTION_TIER.ZERO,
    title: 'Free',
    price: '0',
    buttonText: 'Try for free',
    benefits: {
      list: [
        {
          id: 1,
          title: 'Access to Dashboard',
          subList: [
            { id: 1, title: 'Full Prompt Library' },
            { id: 2, title: '5 Favorite Prompts' },
            { id: 3, title: '5 Custom Prompts' },
            { id: 4, title: '5 Documents' },
          ],
        },
        {
          id: 2,
          title: 'Access to Alchemy for ChatGPT',
          subList: [
            { id: 1, title: 'Chat Folders' },
            { id: 2, title: 'Themes' },
          ],
        },
        {
          id: 3,
          title: 'Access to Blob',
          subList: [
            { id: 1, title: '1000 Chat Tokens/Day' },
            { id: 2, title: 'Quick Prompts' },
            { id: 3, title: '5 Snippets' },
          ],
        },
        { id: 4, title: 'Fast /Commands' },
        { id: 5, title: 'Speech to Text' },
      ],
    },
    isPopular: false,
    timePeriod: 'month',
  },
  {
    id: 2,
    tier: SUBSCRIPTION_TIER.ONE,
    title: 'Pro',
    price: '12.99',
    buttonText: 'Start 7-day free trial',
    benefits: {
      list: [
        { id: 1, title: 'Everything in Free', isCheckboxWhite: true },
        {
          id: 2,
          title: 'Access to Dashboard',
          subList: [
            { id: 1, title: 'Unlimited Custom Prompts' },
            { id: 2, title: 'Unlimited Favorite Prompts' },
            { id: 3, title: 'Unlimited Documents' },
          ],
        },
      ],
    },
    isPopular: true,
    timePeriod: 'month',
  },
  {
    id: 3,
    tier: SUBSCRIPTION_TIER.TWO,
    title: 'Premium',
    price: '49.99',
    buttonText: 'Start 7-day free trial',
    benefits: {
      list: [
        { id: 1, title: 'Everything in Pro', isCheckboxWhite: true },
        {
          id: 2,
          title: 'Access to Dashboard',
          subList: [{ id: 1, title: 'Unlimited Snippets' }],
        },
        {
          id: 3,
          title: 'Access to Blob',
          subList: [
            { id: 1, title: 'Unlimited Chat Tokens' },
            { id: 2, title: 'Unlimited Snippets' },
            { id: 3, title: 'Webpage Summarization' },
            { id: 4, title: 'Youtube Summarization' },
            { id: 5, title: 'Image Generation' },
            { id: 6, title: 'AI Search' },
            { id: 7, title: 'Image OCR' },
            { id: 8, title: 'Chat with PDF' },
            { id: 9, title: 'Capture Screenshots' },
          ],
        },
      ],
    },
    isPopular: true,
    timePeriod: 'month',
  },
];

const pricingPlanYear: IBillingCard[] = [
  {
    id: 1,
    tier: SUBSCRIPTION_TIER.ZERO,
    title: 'Free',
    price: '0',
    buttonText: 'Try for free',
    benefits: {
      list: [
        {
          id: 1,
          title: 'Access to Dashboard',
          subList: [
            { id: 1, title: 'Full Prompt Library' },
            { id: 2, title: '5 Favorite Prompts' },
            { id: 3, title: '5 Custom Prompts' },
            { id: 4, title: '5 Documents' },
          ],
        },
        {
          id: 2,
          title: 'Access to Alchemy for ChatGPT',
          subList: [
            { id: 1, title: 'Chat Folders' },
            { id: 2, title: 'Themes' },
          ],
        },
        {
          id: 3,
          title: 'Access to Blob',
          subList: [
            { id: 1, title: '1000 Chat Tokens/Day' },
            { id: 2, title: 'Quick Prompts' },
            { id: 3, title: '5 Snippets' },
          ],
        },
        { id: 4, title: 'Fast /Commands' },
        { id: 5, title: 'Speech to Text' },
      ],
    },
    isPopular: false,
    timePeriod: 'month',
  },
  {
    id: 2,
    tier: SUBSCRIPTION_TIER.ONE,
    title: 'Pro',
    price: '10.99',
    buttonText: 'Start 7-day free trial',
    benefits: {
      list: [
        { id: 1, title: 'Everything in Free', isCheckboxWhite: true },
        {
          id: 2,
          title: 'Access to Dashboard',
          subList: [
            { id: 1, title: 'Unlimited Custom Prompts' },
            { id: 2, title: 'Unlimited Favorite Prompts' },
            { id: 3, title: 'Unlimited Documents' },
          ],
        },
      ],
    },
    isPopular: true,
    timePeriod: 'month',
    saving: "-15.4% OFF"
  },
  {
    id: 3,
    tier: SUBSCRIPTION_TIER.TWO,
    title: 'Premium',
    price: '29.99',
    buttonText: 'Start 7-day free trial',
    benefits: {
      list: [
        { id: 1, title: 'Everything in Pro', isCheckboxWhite: true },
        {
          id: 2,
          title: 'Access to Dashboard',
          subList: [{ id: 1, title: 'Unlimited Snippets' }],
        },
        {
          id: 3,
          title: 'Access to Blob',
          subList: [
            { id: 1, title: 'Unlimited Chat Tokens' },
            { id: 2, title: 'Unlimited Snippets' },
            { id: 3, title: 'Webpage Summarization' },
            { id: 4, title: 'Youtube Summarization' },
            { id: 5, title: 'Image Generation' },
            { id: 6, title: 'AI Search' },
            { id: 7, title: 'Image OCR' },
            { id: 8, title: 'Chat with PDF' },
            { id: 9, title: 'Capture Screenshots' },
          ],
        },
      ],
    },
    isPopular: true,
    timePeriod: 'month',
    saving: "-40% OFF"
  },
];

export const pricingPlan = {
  [SUBSCRIPTION_TYPE.MONTH]: pricingPlanMonth,
  [SUBSCRIPTION_TYPE.YEAR]: pricingPlanYear,
};
