import cn from 'classnames';
import { FC } from 'react';

import styles from './Badge.module.scss';

interface IBadge {
  icon?: string;
  title: string;
  color: string;
  background: string;
  className?: string;
  subcategory?: string;
  style?: object;
}

export const Badge: FC<IBadge> = ({
  icon,
  title,
  color,
  background,
  className,
  subcategory,
  style,
}) => {
  const badgeStyles = { color, background, ...style };

  return (
    <div className={styles.badgeWrapper}>
      <div
        className={cn(styles.badge, className, { [styles.badgeAfter]: subcategory })}
        style={badgeStyles}
      >
        {icon && <img src={icon} alt="icon" />}
        {title}
      </div>
      {subcategory && (
        <div className={styles.badgeSubcategory} style={badgeStyles}>
          {subcategory}
        </div>
      )}
    </div>
  );
};
