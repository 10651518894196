import cn from 'classnames';
import { FC, ReactNode } from 'react';

import { MainLogo } from '../../../../assets/svg/MainLogo';
import { MaxWidthContainer } from '../../../../components/Layouts/MaxWidthContainer';
import { StepControls } from '../StepControls';
import { UserInfoAndLogout } from '../UserInfoAndLogout';
import styles from './OnboardingStepLayout.module.scss';

type Props = {
  children: ReactNode;
  onSkip: () => void;
  onNext: () => void;
  title: string;
  description: string;
  currentStep: number;
  headerClassName?: string;
};

export const OnboardingStepLayout: FC<Props> = ({
  children,
  onSkip,
  onNext,
  title,
  description,
  currentStep,
  headerClassName,
}) => {
  return (
    <div className={styles.onboardingStep}>
      <MaxWidthContainer className={styles.onboardingStepWrapper}>
        <div className={styles.onboardingStepContent}>
          <div className={cn(styles.onboardingStepHeader, headerClassName)}>
            <MainLogo className={styles.logo} />
            <UserInfoAndLogout />
          </div>
          <main className={styles.onboardingStepMainContent}>
            <div className={styles.onboardingStepTitleAndDescription}>
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
            {children}
          </main>
          <StepControls currentStep={currentStep} onSkip={onSkip} onNext={onNext} />
        </div>
      </MaxWidthContainer>
    </div>
  );
};
