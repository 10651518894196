import './index.scss';

import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { Button } from '../../button';
import { LoadingOverlay } from '../../loading';
import { Modal } from '..';

interface IAlertModalCallerProps {
  title?: string;
  text?: string;
  confirmButtonText?: string;
  theme?: 'default' | 'blob';
  handleClose?: () => Promise<void>;
  root?: Element;
}
export const alert = (props: IAlertModalCallerProps) => {
  const rootNode = document.querySelector('#root');

  const portalNode = document.createElement('div');

  portalNode.className = 'alchemy-modal-portal';

  if (rootNode !== null) {
    const root = createRoot(portalNode);

    root.render(<AlertModal handleDestroy={() => root.unmount()} {...props} />);

    rootNode.append(portalNode);
  }
};

interface IAlertModal {
  handleDestroy?: () => void;
}

export function AlertModal({
  confirmButtonText = 'OK',
  ...props
}: IAlertModalCallerProps & IAlertModal) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  async function handleClose() {
    setIsLoading(true);
    await props.handleClose?.();
    setIsLoading(false);
    setIsVisible(false);

    setTimeout(() => {
      props.handleDestroy?.();
    }, 500);
  }

  return (
    <Modal
      visible={isVisible}
      className="alchemy-alert-modal"
      title={props.title ?? 'Action alert'}
      onClose={() => handleClose()}
      theme={props.theme}
    >
      <div className="content">
        <p className="text">{props.text}</p>
        <div className="alchemy-footer">
          <Button size="mini" active={true} onClick={() => handleClose()} className="confirm-btn">
            {confirmButtonText}
          </Button>
        </div>
      </div>
      <LoadingOverlay active={isLoading} />
    </Modal>
  );
}
