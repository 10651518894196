import './index.scss';

import { useEffect, useState } from 'react';

export interface IProgressCircle {
  progress: number;
  title: string | JSX.Element;
  hasError?: boolean;
  autoMove?: boolean;
  color?: string;
}
export function ProgressCircle(props: IProgressCircle) {
  const [autoMoveValue, setAutoMoveValue] = useState<number>(0);

  useEffect(() => {
    const interval = props.autoMove
      ? setInterval(() => {
          setAutoMoveValue(prevValue => prevValue + 1);
        }, 500)
      : null;

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  const progress = Math.max(
    Math.min(
      props.progress < 100 && !props.hasError ? Math.min(props.progress + autoMoveValue, 90) : 100,
      100
    ),
    0
  );

  return (
    <div className="alchemy-progress-circle-wrapper">
      <svg
        className={
          'alchemy-progress-circle' +
          (progress === 100 ? ' alchemy-finished' : '') +
          (props?.hasError ? ' alchemy-error' : '')
        }
        width="20"
        height="20"
        viewBox="0 0 160 160"
      >
        <g transform="rotate(0 0 0)">
          <circle
            className="alchemy-progress-bg"
            r="70"
            cx="80"
            cy="80"
            fill="transparent"
            stroke="rgba(102, 51, 238,.3)"
            strokeWidth="20px"
          ></circle>
          <circle
            className="alchemy-progress"
            r="70"
            cx="80"
            cy="80"
            stroke={'var(--app-accent)'}
            strokeLinecap="round"
            fill="transparent"
            strokeWidth="20px"
            strokeDasharray="439.6px"
            strokeDashoffset={439.6 * ((100 - progress) / 100) + 'px'}
          ></circle>
          <circle fill="transparent" className="alchemy-bg" r="80" cx="80" cy="80"></circle>
        </g>

        <g transform="rotate(90) scale(0.7)">
          <svg
            className="alchemy-done-icon"
            height="160"
            fill="transparent"
            viewBox="0 -960 960 960"
            width="160"
          >
            <g>
              {props?.hasError ? (
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              ) : (
                <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
              )}
            </g>
          </svg>
        </g>
      </svg>
      <p>{props.title}</p>
    </div>
  );
}
