import { createRef, RefObject } from 'react';

import { Button } from '../../../../../../design/components/button';
import { AuthBlockHeader } from '../../../../components/header';
import { IValidationFieldErrors } from '../../../../interfaces';
import { IResetPasswordFormState } from '../..';

export interface IResetPasswordChangeStep {
  size?: 'default' | 'mini';
  isLoading?: boolean;

  formState: IResetPasswordFormState;
  errors: IValidationFieldErrors;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    formRef: RefObject<HTMLFormElement>
  ) => void;

  onSubmit: (
    e: React.FormEvent<HTMLButtonElement>,
    formRef: RefObject<HTMLFormElement | null>
  ) => void;
  onCancel: () => void;
}

export function ResetPasswordChangeStep({
  size = 'default',
  ...props
}: IResetPasswordChangeStep) {
  const formRef = createRef<HTMLFormElement>();

  return (
    <>
      <AuthBlockHeader
        size={size}
        title={'Reset Password'}
        subTitle={'Please enter a new password for your account'}
      />
      <form ref={formRef} className='auth-form'>
        <div className='input password'>
          <div className='label-button-container'>
            <label className='input-label' htmlFor='password'>
              New Password
            </label>
          </div>
          <input
            name='password'
            id='password'
            type={'password'}
            placeholder='Enter your new password'
            required
            value={props.formState.password}
            onChange={(e) => props.handleInputChange(e, formRef)}
          />
          {props.errors.password && (
            <div className='auth-error-message'>{props.errors.password}</div>
          )}
        </div>
        <div className='input password'>
          <div className='label-button-container'>
            <label className='input-label' htmlFor='password-confirmation'>
              Password confirmation
            </label>
          </div>
          <input
            name='confirmation'
            id='confirmation'
            type={'password'}
            placeholder='Enter password here'
            required
            value={props.formState.confirmation}
            onChange={(e) => props.handleInputChange(e, formRef)}
          />
          {props.errors.confirmation && (
            <div className='auth-error-message'>
              {props.errors.confirmation}
            </div>
          )}
        </div>
        <Button
          type='submit'
          isLoading={props.isLoading}
          active={true}
          onClick={(e) => {
            props.onSubmit(e, formRef);
          }}
        >
          Next
        </Button>
        <Button type='submit' onClick={() => props.onCancel()}>
          Cancel
        </Button>
      </form>
    </>
  );
}
