export default function AttachmentFileIcon() {
  return (
    <svg
      width='11'
      height='14'
      viewBox='0 0 11 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='alchemy-attachment-icon'
    >
      <path
        d='M6.85 4.85714V8.85357C6.85 9.72342 6.14485 10.4286 5.275 10.4286V10.4286C4.40515 10.4286 3.7 9.72342 3.7 8.85357V4.15C3.7 2.4103 5.1103 1 6.85 1V1C8.5897 1 10 2.4103 10 4.15V9C10 11.2091 8.20914 13 6 13H5C2.79086 13 1 11.2091 1 9V4.85714'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
}
