import ReactModal from 'react-modal';

import styles from './MenuBarTipTap.module.scss';

export function LinkModal(props: IProps) {
  const { url, closeModal, onChangeUrl, onSaveLink, onRemoveLink, ...rest } = props;

  return (
    <Modal {...rest}>
      <h2 className={styles.modalTitle}>Edit link</h2>
      <button className={styles.modalClose} type="button" onClick={closeModal}>
        x
      </button>
      <input className={styles.modalInput} autoFocus value={url} onChange={onChangeUrl} />
      <div className={styles.modalButtons}>
        <button className={styles.buttonRemove} type="button" onClick={onRemoveLink}>
          Remove
        </button>
        <button className={styles.buttonSave} type="button" onClick={onSaveLink}>
          Save
        </button>
      </div>
    </Modal>
  );
}

const modalStyles = {
  overlay: {
    zIndex: 10000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 480,
    marginRight: '-50%',
    padding: 24,
    transform: 'translate(-50%, -50%)',
    border: '2px solid var(--color-gray-4)',
    borderRadius: '4px',
    background: '#181b1e',
    color: 'white',
  },
};

ReactModal.setAppElement('#root');

export function Modal(props: ReactModal.Props) {
  const { style, children, ...rest } = props;

  return (
    <ReactModal style={{ ...modalStyles, ...style }} {...rest}>
      {children}
    </ReactModal>
  );
}

interface IProps extends ReactModal.Props {
  url: string;
  closeModal: () => void;
  onChangeUrl: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSaveLink: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onRemoveLink: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isOpen: boolean;
  onRequestClose: () => void;
  contentLabel: string;
}
