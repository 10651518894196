import * as Yup from 'yup';

export const validationSchema = Yup.object({
  first_name: Yup.string().matches(/(^\S$)|(^\S.*\S$)/, "Can't start or end with spaces"),
  last_name: Yup.string().matches(/(^\S$)|(^\S.*\S$)/, "Can't start or end with spaces"),
  email: Yup.string().email('Invalid email address').required('Enter current email'),
  newEmail: Yup.string().email('Invalid email address'),
  new_password: Yup.string()
    .matches(/^\S*$/, 'Spaces are not allowed for password')
    .min(8, 'Password must be at least 8 characters long'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('new_password')], 'Passwords must match')
    .when('new_password', {
      is: newPassword => newPassword?.length > 0,
      then: schema => schema.required('Confirm your new password'),
    }),
  old_password: Yup.string().when(['new_password', 'confirmPassword'], {
    is: newPassword => newPassword?.length > 0,
    then: schema => schema.required('Enter your old password'),
  }),
});
