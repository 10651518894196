import { Icon } from './icon';
import { IIcon } from './icon/interfaces';

interface IStartIcon {
  props?: IIcon;
  isFavorite?: boolean;
}

export const StarIcon = ({ isFavorite, ...props }: IStartIcon) => {
  const fill = isFavorite ? '#fac515' : 'none';

  return (
    <Icon width='24' height='24' {...props} fill={fill}>
      <path
        d='M12 4.75L13.75 10.25H19.25L14.75 13.75L16.25 19.25L12 15.75L7.75 19.25L9.25 13.75L4.75 10.25H10.25L12 4.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
