import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const HorizontalAlignmentIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M11.0422 14.375H8.95866C8.03818 14.375 7.29199 13.6288 7.29199 12.7083V7.29167C7.29199 6.37119 8.03819 5.625 8.95866 5.625H11.0422C11.9627 5.625 12.7089 6.37119 12.7089 7.29167V12.7083C12.7089 13.6288 11.9627 14.375 11.0422 14.375Z"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.042 3.95801V16.0413"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.95801 3.95801V16.0413"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
