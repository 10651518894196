import cn from 'classnames';
import { FC } from 'react';

import CheckboxIcon from '../../../../external/extension/ui/design/assets/svg/icons/CheckboxIcon';
import styles from './Category.module.scss';

type Props = {
  title: string;
  isSelected?: boolean;
  onClick?: (title: string) => void;
};

export const Category: FC<Props> = ({ title, isSelected = false, onClick }) => {
  return (
    <button
      className={cn(styles.category, { [styles.categorySelected]: isSelected })}
      onClick={() => onClick(title)}
    >
      <CheckboxIcon radius={6} checked={isSelected} />
      {title}
    </button>
  );
};
