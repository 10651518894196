import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const RadioIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <g filter="url(#filter0_d_1_31)">
        <rect
          x="3.95833"
          y="3.95833"
          width="12.0833"
          height="12.0833"
          rx="6.04167"
          fill="#181B1E"
        />
        <rect
          x="3.95833"
          y="3.95833"
          width="12.0833"
          height="12.0833"
          rx="6.04167"
          stroke="#656565"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_31"
          x="1.20833"
          y="2.20833"
          width="17.5833"
          height="17.5833"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0705882 0 0 0 0 0.0705882 0 0 0 0 0.0901961 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_31" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_31" result="shape" />
        </filter>
      </defs>
    </Icon>
  );
};
