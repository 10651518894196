import CryptoJS from 'crypto-js';
import { useMemo } from 'react';

function useRandomShortMD5() {
  return useMemo(() => {
    // Generate a random input
    const randomInput = (Math.random() + 1).toString(36).substring(7);
    const hash = CryptoJS.MD5(randomInput).toString();

    // Truncate the hash to the desired length
    return hash.substring(0, 8);
  }, []); // Empty dependency array means it will only compute on mount
}

export class AlchemyEncryptor {
  private secretKey: string;
  private readonly marker = '_-_'; // Символ "ключ" как маркер конца данных

  constructor(secretKey: string) {
    this.secretKey = secretKey;
  }

  // Метод для шифрования
  encrypt(input: string | number): string {
    const inputStr = input.toString() + this.marker; // Добавляем маркер в конец строки
    const key = this.secretKey;
    let encrypted = '';

    for (let i = 0; i < inputStr.length; i++) {
      encrypted += String.fromCharCode(inputStr.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }

    // Конвертация в строку, похожую на UUID v4, с учетом необходимых символов и структуры
    let hexString = this.toHex(Buffer.from(encrypted));

    // Если строка слишком короткая, повторяем её до нужной длины
    while (hexString.length < 32) {
      hexString += this.toHex(Buffer.from(hexString));
    }

    // Форматируем строку под UUID v4 формат
    return this.formatAsUUID(hexString);
  }

  // Метод для дешифрования
  decrypt(encrypted: string): string {
    const key = this.secretKey;

    // Убираем тире перед декодированием
    const cleaned = encrypted.replace(/-/g, '');

    // Возвращаем строку в исходный формат перед дешифрованием
    const originalHexString = this.removeUUIDFormatting(cleaned);

    const decoded = this.fromHex(originalHexString).toString('binary');
    let decrypted = '';

    for (let i = 0; i < decoded.length; i++) {
      decrypted += String.fromCharCode(decoded.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }

    // Отсекаем всё после маркера
    const markerIndex = decrypted.indexOf(this.marker);

    if (markerIndex !== -1) {
      decrypted = decrypted.substring(0, markerIndex);
    }

    return decrypted;
  }

  // Метод для форматирования строки в UUID v4-подобный формат
  private formatAsUUID(hexString: string): string {
    // Обрезаем строку до нужной длины (32 символа)
    hexString = hexString.slice(0, 32);

    return `${hexString.slice(0, 8)}-${hexString.slice(8, 12)}-${hexString.slice(
      12,
      16
    )}-${hexString.slice(16, 20)}-${hexString.slice(20, 32)}`;
  }

  // Восстанавливаем оригинальный hex без форматирования UUID
  private removeUUIDFormatting(hexString: string): string {
    return (
      hexString.slice(0, 8) +
      hexString.slice(8, 12) +
      hexString.slice(12, 16) +
      hexString.slice(16, 20) +
      hexString.slice(20, 32)
    );
  }

  // Метод для конвертации в строку из цифр и букв a-f (hex)
  private toHex(buffer: Buffer): string {
    return buffer.toString('hex');
  }

  // Метод для декодирования из hex строки
  private fromHex(hexString: string): Buffer {
    return Buffer.from(hexString, 'hex');
  }
}

export default useRandomShortMD5;
