import axios, { AxiosError } from 'axios';

export class RequestTools {
  static getApiErrorMessage(error: any): string {
    return (
      (error?.response &&
        error.response.data &&
        ((error.response.data.message
          ? error.response.data.message +
            (error.response.data.details instanceof Array
              ? '\n\n' + error.response.data.details.join('\n')
              : '')
          : null) ||
          error.response.data.title ||
          error.response.data.detail ||
          error.response.data.status ||
          error.response.data.error)) ||
      error.message ||
      error.toString()
    );
  }

  static getApiErrorCode(error: any): number | null {
    if (error instanceof AxiosError) {
      return error.response ? error.response.status : null;
    }

    return null;
  }

  static getBase64FromUrl = async (url: string) => {
    const blob: Blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };
      xhr.responseType = 'blob';
      xhr.open('GET', url, true);
      xhr.send(xhr.response);
    });

    return await blob.text();
  };

  static buildQueryString(data: any) {
    return Object.keys(data)
      .map((key) => {
        const value = data[key];

        if (Array.isArray(value)) {
          return value
            .map((value: any) => `${key}=${encodeURIComponent(value)}`)
            .join('&');
        }

        return `${key}=${encodeURIComponent(value)}`;
      })
      .filter(Boolean) // фильтруем пустые строки
      .join('&');
  }

  static async fetchHtmlContent(url: string): Promise<string | null> {
    try {
      // Sending a GET request to the specified URL
      const response = await axios.get<string>(url, {
        headers: {
          Accept: 'text/html'
        }
      });

      // Returning the HTML content as a string
      return response.data;
    } catch (error) {
      // Handling any errors that occur during the request
      if (axios.isAxiosError(error)) {
        console.error('Error fetching HTML content:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }

      return null;
    }
  }

  static htmlStringToDocument(htmlString: string): Document {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    return doc;
  }
}
