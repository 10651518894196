import cn from 'classnames';
import { FC, useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';

import { PhotoIcon } from '../../../../assets/svg/PhotoIcon';
import { Button } from '../../../../components/Button';
import { ModalLayout } from '../../../../components/Modal/ModalLayout';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { selectTheme, ThemeOptions } from '../../../../redux/Theme';
import styles from './CropImageModal.module.scss';
import { getCroppedImage } from './cropImageService';

type Props = {
  imgSrc: string;
  onApply: (img: File) => void;
  onClose: () => void;
};

export const CropImageModal: FC<Props> = ({ imgSrc, onApply, onClose }) => {
  const theme = useTypedSelector(selectTheme);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleRangeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;

    setZoom(value);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: File = await getCroppedImage(imgSrc, croppedAreaPixels);

      onApply(croppedImage);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [imgSrc, croppedAreaPixels]);

  return (
    <ModalLayout title="Crop Image">
      <div className={styles.cropImageModalContent}>
        <div className={styles.cropContainer}>
          <Cropper
            image={imgSrc}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            cropSize={{ width: 239, height: 239 }}
            classes={{
              cropAreaClassName: styles.cropArea,
            }}
          />
        </div>
        <div className={styles.cropControls}>
          <PhotoIcon className={styles.cropPhotoIcon} />
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={handleRangeInputChange}
            className={cn(styles.cropInputRange, {
              [styles.cropInputPurpleRange]: theme === ThemeOptions.Purple,
            })}
            style={{
              backgroundSize: `${50 * (zoom - 1)}%`,
            }}
          />
          <PhotoIcon className={cn(styles.cropPhotoIcon, styles.cropPhotoIconBig)} />
        </div>
        <div className={styles.cropImageModalBtnsContainer}>
          <Button onClick={onClose} isSecondary type="button">
            Cancel
          </Button>
          <Button onClick={showCroppedImage} type="button">
            Apply
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
