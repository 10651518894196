import { useEffect, useState } from 'react';

import { IClipBase } from '../../../../../../service/interfaces';
import { Button } from '../../../../../design/components/button';
import { Modal } from '../../../../../design/components/modal';

export interface IClipsModal {
  mode: 'create' | 'edit';
  visible: boolean;
  clip?: IClipBase | null;
  onSubmit: (url: string, title: string) => void;
  onCancel: () => void;
}
export function ClipsModal(props: IClipsModal) {
  const [url, setURL] = useState<string>(props.clip?.url ?? '');
  const [title, setTitle] = useState<string>(props.clip?.title ?? '');

  useEffect(() => {
    setURL(props.clip?.url ?? '');
    setTitle(props.clip?.title ?? '');
  }, [props.clip]);

  const handleCancel = () => {
    props.onCancel();
  };

  const handleSubmit = () => {
    props.onSubmit(url, title);
  };

  return (
    <Modal
      title={props.mode === 'create' ? 'Add new clip' : 'Edit clip'}
      visible={props.visible}
      onClose={props.onCancel}
      theme="blob"
      hasCloseButton={true}
    >
      <label>
        <span>Link</span>
        <input
          value={url}
          onChange={e => setURL(e.target.value)}
          placeholder="Add your link"
          type="text"
        />
      </label>

      <label>
        <span>Name</span>
        <input
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Name"
          type="text"
        />
      </label>

      <div className="footer">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit} active={true}>
          {props.mode === 'create' ? 'Add' : 'Save'}
        </Button>
      </div>
    </Modal>
  );
}
