import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const SlashIcon = (props: IIcon) => {
  return (
    <Icon width="24" height="24" {...props}>
      <path
        d="M11 18.25L13.5 5.75"
        stroke="#989898"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
