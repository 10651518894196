import './index.scss';

import cn from 'classnames';
import { useMemo } from 'react';

import { GraphicTools } from '../../../../tools/graphics';
import { StringTools } from '../../../../tools/string';
import { Avatar } from '../avatar';

interface IBadge {
  active?: boolean;
  icon?: string | null;
  title?: string;
  color?: string;
  background?: string;
  className?: string;
  subcategory?: string | null;
  style?: object;
  isProBadge?: boolean;
  noIconFadeIn?: boolean;
  onClick?: () => void;
}

export function Badge({
  active,
  icon,
  title,
  color,
  background,
  className,
  subcategory,
  style,
  isProBadge,
  noIconFadeIn,
  onClick,
}: IBadge) {
  const alphaBackground = useMemo(() => {
    if (background) {
      try {
        return GraphicTools.replaceBlackWithAlpha(background);
      } catch (e) {
        console.error('🛑 Failed to convert badge color to alpha', e, background);

        return background;
      }
    } else {
      return undefined;
    }
  }, [background]);

  const finalBoxShadow = color
    ? `0px 0px 0px 1px ${color} inset, 0px 0px 0px 0px #fff inset`
    : '0px 0px 0px 1px rgba(185, 159, 21, 0.2) inset, 0px 0px 0px 0px #fff inset';
  const badgeStyles = {
    color,
    background: alphaBackground,
    boxShadow: finalBoxShadow,
    ...style,
  };

  return (
    <div onClick={() => onClick()} className={'badge-wrapper'}>
      <div
        className={cn('badge', className, {
          badgeAfter: subcategory,
          pro: isProBadge,
          active: active,
        })}
        style={badgeStyles}
      >
        {icon && <Avatar noFadeIn={noIconFadeIn} url={icon} size="micro" />}
        {title}
      </div>
      {subcategory && (
        <div className="badgeSubcategory" style={badgeStyles}>
          {StringTools.truncate(subcategory, 20)}
        </div>
      )}
    </div>
  );
}
