import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { v4 } from 'uuid';

import { GraphicTools } from '../../../tools/graphics';

export enum UploadStatus {
  Pending = 'pending',
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  UploadError = 'upload-error',
}

export interface IUploadItem {
  id: string;
  file: File;
  filename: string;
  mime: string;
  status: UploadStatus;
  preview?: string;
  uploadPercent?: number;
  error?: string;
  url?: string;
}

export class UploadManager {
  private maxFiles: number;
  private concurrentUploads: number;
  private queue: IUploadItem[];
  private uploads: IUploadItem[];
  private activeUploads: Map<string, CancelTokenSource>;
  private axiosInstance: AxiosInstance;
  private onChangeCallbacks: ((items: IUploadItem[]) => void)[];

  constructor(axiosInstance: AxiosInstance, maxFiles = 10, concurrentUploads = 3) {
    this.maxFiles = maxFiles;
    this.concurrentUploads = concurrentUploads;
    this.queue = [];
    this.uploads = [];
    this.activeUploads = new Map();
    this.axiosInstance = axiosInstance;
    this.onChangeCallbacks = [];
  }

  public async upload(file: File, axiosInstance: AxiosInstance): Promise<void> {
    this.axiosInstance = axiosInstance;

    if (this.queue.length >= this.maxFiles) {
      throw new Error('Max file limit reached');
    }

    const uploadItem: IUploadItem = {
      id: v4(),
      file: file,
      filename: file.name,
      mime: file.type,
      status: UploadStatus.Pending,
      preview: file.type.startsWith('image') ? await GraphicTools.fileToDataURL(file) : undefined,
    };

    this.queue.push(uploadItem);
    this.uploads.push(uploadItem);

    this.triggerOnChange();
    this.processQueue();
  }

  private processQueue(): void {
    const uploadingCount = this.uploads.filter(
      item => item.status === UploadStatus.Uploading
    ).length;

    if (uploadingCount < this.concurrentUploads) {
      const nextItem = this.queue.find(item => item.status === UploadStatus.Pending);

      if (nextItem) {
        this.startUpload(nextItem);
      }
    }
  }

  private async startUpload(item: IUploadItem): Promise<void> {
    item.status = UploadStatus.Uploading;
    item.uploadPercent = 0;
    this.triggerOnChange();

    const formData = new FormData();

    formData.append('file_type', '1');
    formData.append('_file', item.file);

    const cancelTokenSource = axios.CancelToken.source();

    this.activeUploads.set(item.id, cancelTokenSource);

    try {
      const response = await this.axiosInstance.post('shop/create-attachment/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        cancelToken: cancelTokenSource.token,
        onUploadProgress: progressEvent => {
          if (progressEvent.total) {
            item.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.triggerOnChange();
          }
        },
      });

      item.status = UploadStatus.Uploaded;
      item.url = response.data?._file; // Adjust according to your response structure
      item.uploadPercent = 100;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        item.status = UploadStatus.Pending;
        item.uploadPercent = 0;
      } else {
        item.status = UploadStatus.UploadError;
        item.error = error.message;
      }
    } finally {
      this.activeUploads.delete(item.id);
      this.triggerOnChange();
      this.processQueue();
    }
  }

  public removeFile(id: string): void {
    const index = this.uploads.findIndex(item => item.id === id);

    if (index !== -1) {
      const item = this.uploads[index];

      if (item.status === UploadStatus.Uploading) {
        const cancelTokenSource = this.activeUploads.get(item.id);

        if (cancelTokenSource) {
          cancelTokenSource.cancel('Upload canceled by user.');
        }
      }

      this.uploads.splice(index, 1);
      const queueIndex = this.queue.findIndex(qItem => qItem.id === id);

      if (queueIndex !== -1) {
        this.queue.splice(queueIndex, 1);
      }

      this.triggerOnChange();
    }
  }

  public clearAll(): void {
    // Cancel all active uploads
    this.activeUploads.forEach(cancelTokenSource => {
      cancelTokenSource.cancel('Upload canceled by user.');
    });

    // Clear the queue and uploads array
    this.queue = [];
    this.uploads = [];
    this.activeUploads.clear();

    // Trigger onChange callbacks
    this.triggerOnChange();
  }

  public getAllItems(): IUploadItem[] {
    return [...this.uploads];
  }

  public onChange(callback: (items: IUploadItem[]) => void): void {
    this.onChangeCallbacks.push(callback);
  }

  private triggerOnChange(): void {
    const items = this.getAllItems();

    this.onChangeCallbacks.forEach(callback => callback(items));
  }
}
