import './index.scss';

import { useDrop } from 'react-dnd';
import Highlighter from 'react-highlight-words';

import { IClip, IClipFolder } from '../../../../../service/interfaces';
import { StringTools } from '../../../../../tools/string';
import DeleteIcon from '../../../../design/assets/svg/icons/DeleteIcon';
import EditIcon from '../../../../design/assets/svg/icons/EditIcon';
import { Avatar } from '../../../../design/components/avatar';
import { SmoothVisibility } from '../../../../design/components/smoothVisibility';

export interface ClipFolderParams {
  hideQuickActions?: boolean;
  folder: IClipFolder;
  searchQuery: string;
  clips: IClip[];
  onDropClip?: (clip: IClip) => void;
  onClick: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export function ClipFolder({
  hideQuickActions,
  folder,
  searchQuery,
  clips,
  onDropClip,
  onClick,
  onDelete,
  onEdit,
}: ClipFolderParams) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CLIPS_ITEM',
    drop: (item: any) => {
      onDropClip?.(item); // Обработка перемещения элемента в папку
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} className="clips-item folder" onClick={onClick}>
      <div className="folder-preview">
        {clips.slice(0, 4).map((clip, index) => (
          <Avatar
            key={index}
            url={clip.preview !== 'none' ? clip.preview : undefined}
            userName={clip.title.slice(0, 1)}
            size="big"
          />
        ))}
      </div>
      <div className="item-info">
        <span className="item-title">
          <Highlighter
            highlightClassName="alchemy-highlight"
            searchWords={[searchQuery ?? '']}
            autoEscape={true}
            textToHighlight={folder.title}
          />
        </span>
        <span className="item-url">
          {!isOver
            ? StringTools.numWord(clips.length, ['item', 'items', 'items'], true)
            : 'Move here'}
        </span>
      </div>
      <SmoothVisibility visible={!hideQuickActions} className="quick-actions">
        <button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            onEdit?.();
          }}
          className="item-edit"
        >
          <EditIcon color="var(--color-titanium-600)" />
        </button>
        <button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            onDelete?.();
          }}
          className="item-delete"
        >
          <DeleteIcon color="var(--color-titanium-600)" />
        </button>
      </SmoothVisibility>
    </div>
  );
}
