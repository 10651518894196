import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import modalSlice from '../components/Modal/ModalSlice';
import { authApi } from '../services/auth';
import { comingSoonApi } from '../services/comingSoon';
import { myContentApi } from '../services/myContent';
import { promptLibraryApi } from '../services/promptLibrary';
import { settingsApi } from '../services/settings';
import { snippetsApi } from '../services/snippets';
import { subscribtionApi } from '../services/subscribtion';
import { authSlice } from './Auth';
import { globalSlice } from './Global';
import { subscriptionSlice } from './Subscription';
import themeSlice from './Theme';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [modalSlice.name]: modalSlice.reducer,
  [themeSlice.name]: themeSlice.reducer,
  [globalSlice.name]: globalSlice.reducer,
  [promptLibraryApi.reducerPath]: promptLibraryApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [myContentApi.reducerPath]: myContentApi.reducer,
  [subscribtionApi.reducerPath]: subscribtionApi.reducer,
  [subscriptionSlice.name]: subscriptionSlice.reducer,
  [comingSoonApi.reducerPath]: comingSoonApi.reducer,
  [snippetsApi.reducerPath]: snippetsApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      promptLibraryApi.middleware,
      settingsApi.middleware,
      myContentApi.middleware,
      subscribtionApi.middleware,
      comingSoonApi.middleware,
      snippetsApi.middleware
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
