import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { ExtensionEvents, useExtensionEventListener } from '../../../../service/events';
import { ExtensionEventType } from '../../../../service/events/types';
import MicrophoneIcon from '../../assets/svg/icons/MicrophoneIcon';

export interface IVoiceInput {
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (listening: boolean) => void;
  onListeningStopped?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  tabIndex?: number;
  isAnyVoiceInputEnabled?: boolean;
  className?: string;
  svgClassName?: string;
}

export const VoiceInput: React.FC<IVoiceInput> = ({
  prompt,
  onChange,
  onListeningStopped,
  setPrompt,
  onBlur,
  onFocus,
  tabIndex,
  isAnyVoiceInputEnabled,
  className,
  svgClassName,
}: any) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();

  const [promptBeforeTranscript, setPromptBeforeTranscript] = useState<string>(prompt);

  const [isActive, setIsActive] = useState<boolean>(false);

  const prefferedLanguage = (function () {
    return 'en-US';
  })();

  useExtensionEventListener(
    ExtensionEventType.StopAllVoiceInputs,
    async () => {
      if (isActive) {
        await SpeechRecognition.stopListening();
        onListeningStopped?.();
        setIsActive(false);
      }
    },
    [isActive]
  );

  useExtensionEventListener(ExtensionEventType.RefreshVoiceInputText, () => {
    resetTranscript();
  });

  useEffect(() => {
    if (isMicrophoneAvailable) onChange?.(isActive);

    if (listening && isActive) {
      setPrompt?.((promptBeforeTranscript + ' ' + transcript).trim());
    }
  }, [transcript, listening, isActive]);

  function handleMicrophoneClick() {
    if (!browserSupportsSpeechRecognition) {
      toast('This feature is unsupported by your browser 😥');

      return;
    }

    if (!isActive) {
      setPromptBeforeTranscript(prompt);

      resetTranscript();
      ExtensionEvents.dispatch(ExtensionEventType.StopAllVoiceInputs);

      setTimeout(() => {
        setIsActive(true);
        SpeechRecognition.startListening({
          continuous: true,
          language: prefferedLanguage,
        });
      }, 100);
    } else {
      SpeechRecognition.stopListening();

      setIsActive(false);

      onListeningStopped?.();
    }

    if (!isMicrophoneAvailable) {
      toast('Your microphone is not available 😥');

      return;
    }
  }

  return (
    <button
      className={(!className ? `microphone-button` : className) + (isActive ? ' active' : '')}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        handleMicrophoneClick();
      }}
      onBlur={onBlur}
      onFocus={() => {
        onFocus?.();
        if (isAnyVoiceInputEnabled) handleMicrophoneClick();
      }}
      tabIndex={!isAnyVoiceInputEnabled ? tabIndex : undefined}
    >
      <MicrophoneIcon color="currentColor" className={svgClassName} />
    </button>
  );
};
