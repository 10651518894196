import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const CustomPromptsIcon = (props: IIcon) => {
  return (
    <Icon width="24" height="24" {...props}>
      <mask
        id="mask0_1908_25715"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1908_25715)">
        <path
          d="M5.31438 19.4822C4.84295 19.4822 4.43938 19.3025 4.10367 18.943C3.76795 18.5836 3.6001 18.1516 3.6001 17.6469V6.6351C3.6001 6.13039 3.76795 5.69833 4.10367 5.33892C4.43938 4.97951 4.84295 4.7998 5.31438 4.7998H19.0287C19.5001 4.7998 19.9037 4.97951 20.2394 5.33892C20.5751 5.69833 20.743 6.13039 20.743 6.6351V12.1639H19.0287V6.6351H5.31438V17.6469H13.9072V19.4822H5.31438ZM18.6001 20.3998L17.6572 18.1975L15.6001 17.188L17.6572 16.1786L18.6001 13.9763L19.543 16.1786L21.6001 17.188L19.543 18.1975L18.6001 20.3998ZM12.1715 15.8116L13.243 13.288L15.6001 12.141L13.243 10.9939L12.1715 8.47039L11.1001 10.9939L8.74296 12.141L11.1001 13.288L12.1715 15.8116Z"
          fill="#898F93"
        />
      </g>
    </Icon>
  );
};
