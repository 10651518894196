import { Navigate, Outlet } from 'react-router-dom';

import { PAGES_ENUM } from '../../utils/constants';

export const RequireOnboarding = () => {
  const isOnboardingPassed =
    JSON.parse(localStorage.getItem('onboarding_passed')) ||
    JSON.parse(sessionStorage.getItem('onboarding_passed'));

  if (isOnboardingPassed) {
    return <Outlet />;
  }

  return <Navigate to={PAGES_ENUM.ONBOARDING_FIRST_STEP} />;
};
