export interface IAllCategoryIcon {
  color?: string;
}
export default function AllCategoryIcon(props: IAllCategoryIcon) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4831_19900"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17"
      >
        <rect y="0.858154" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4831_19900)">
        <path
          d="M3.67875 12.3582C2.69804 12.3582 1.8674 12.0192 1.18685 11.3412C0.506294 10.6631 0.166016 9.83675 0.166016 8.86207C0.166016 7.88274 0.506338 7.05115 1.18698 6.3673C1.86764 5.68347 2.69718 5.34155 3.67562 5.34155C4.07642 5.34155 4.46113 5.40822 4.82975 5.54155C5.19837 5.67489 5.53268 5.87489 5.83268 6.14155L7.19102 7.34989L6.35768 8.09155L5.11602 6.97489C4.92157 6.79711 4.70273 6.66655 4.4595 6.58322C4.21627 6.49989 3.9642 6.45822 3.70332 6.45822C3.03015 6.45822 2.45842 6.69204 1.98813 7.15969C1.51783 7.62733 1.28268 8.194 1.28268 8.85969C1.28268 9.52077 1.5192 10.0858 1.99225 10.5548C2.46528 11.0237 3.03639 11.2582 3.70557 11.2582C3.9594 11.2582 4.20377 11.2152 4.43867 11.1291C4.67357 11.0429 4.88824 10.9138 5.08268 10.7416L10.215 6.14265C10.5101 5.89192 10.8389 5.69572 11.2013 5.55405C11.5636 5.41239 11.942 5.34155 12.3363 5.34155C13.3169 5.34155 14.1475 5.68157 14.8283 6.3616C15.509 7.04164 15.8493 7.86829 15.8493 8.84155C15.8493 9.82943 15.5067 10.6625 14.8215 11.3408C14.1362 12.0191 13.3011 12.3582 12.316 12.3582C11.9166 12.3582 11.5335 12.2916 11.1665 12.1582C10.7995 12.0249 10.466 11.8249 10.166 11.5582L8.79102 10.3332L9.65768 9.60822L10.8993 10.7249C11.0938 10.9027 11.3126 11.0332 11.5559 11.1166C11.7991 11.1999 12.0512 11.2416 12.312 11.2416C12.9852 11.2416 13.5569 11.0077 14.0272 10.5401C14.4975 10.0724 14.7327 9.50577 14.7327 8.84009C14.7327 8.179 14.4962 7.61397 14.0231 7.145C13.5501 6.67604 12.979 6.44155 12.3098 6.44155C12.056 6.44155 11.8116 6.48461 11.5767 6.57072C11.3418 6.65683 11.1271 6.786 10.9327 6.95822L5.80435 11.5558C5.50657 11.8185 5.17647 12.0179 4.81403 12.1541C4.45159 12.2902 4.07316 12.3582 3.67875 12.3582Z"
          fill={props.color ?? 'currentColor'}
        />
      </g>
    </svg>
  );
}
