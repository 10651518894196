import cn from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { CheckboxIconChecked } from '../../../../assets/svg/CheckboxIconChecked';
import { Button } from '../../../../components/Button';
import { SUBSCRIPTION_TIER } from '../../../../models/ISubscription';
import { errorMessages } from '../../../../services/errors';
import { useSetSettingsMutation } from '../../../../services/settings';
import { PAGES_ENUM } from '../../../../utils/constants';
import { handleError } from '../../../../utils/notifications';
import { IBillingCard } from '../../../SettingsPage/components/BillingAndPlansTabContent/BillingCard/IBillingCard';
import styles from './BillingCard.module.scss';

type Props = {
  onClick: () => void;
  isLoading: boolean;
};

export const BillingCard: FC<IBillingCard & Props> = ({
  tier,
  title,
  price,
  buttonText,
  benefits,
  timePeriod,
  isPopular,
  onClick,
  isLoading,
  saving
}) => {
  const navigate = useNavigate();
  const [setSettings] = useSetSettingsMutation();
  const isZeroLevelSubscription = tier === SUBSCRIPTION_TIER.ZERO;

  const handleZeroLevelSubscription = async () => {
    try {
      await setSettings({ onboarding_passed: true }).unwrap();
      navigate(PAGES_ENUM.HOME, { replace: true });
      localStorage.setItem('onboarding_passed', 'true');
    } catch (error) {
      handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
      console.error('User Update Failed', error);
    }
  };

  return (
    <div className={styles.billingCard}>
      <div className={styles.billingCardTierInfo}>
        <div>{title}</div>
        {saving && <span>{saving}</span>}
      </div>
      <div className={styles.billingCardTimePeriodAndPrice}>
        &#36;{`${price}`}
        <span>{`/${timePeriod}`}</span>
      </div>
      <div className={styles.billingCardDivider} />
      <ul className={styles.billingCardBenefitsList}>
        {benefits?.list.map(benefit =>
          benefit.subList ? (
            <li key={benefit.id} className={styles.billingCardBenefitWithSublist}>
              <div className={styles.billingCardBenefit}>
                <CheckboxIconChecked
                  className={cn(styles.billingCardCheckbox, {
                    [styles.billingCardwhiteCheckbox]: benefit.isCheckboxWhite,
                    [styles.billingCardGrayCheckbox]: isZeroLevelSubscription,
                  })}
                />
                {benefit.title}
              </div>
              <ul className={styles.billingCardBenefitSublist}>
                {benefit.subList.map(subBenefit => (
                  <li key={subBenefit.id}>
                    <span>&#8226;</span> {subBenefit.title}
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li key={benefit.id} className={styles.billingCardBenefit}>
              <CheckboxIconChecked
                className={cn(styles.billingCardCheckbox, {
                  [styles.billingCardwhiteCheckbox]: benefit.isCheckboxWhite,
                  [styles.billingCardGrayCheckbox]: isZeroLevelSubscription,
                })}
              />
              {benefit.title}
            </li>
          )
        )}
      </ul>
      <Button
        className={cn(styles.billingCardBtn, { [styles.billingCardPopularBtn]: isPopular })}
        isSecondary
        onClick={isZeroLevelSubscription ? handleZeroLevelSubscription : onClick}
        disabled={isLoading}
      >
        {buttonText}
      </Button>
    </div>
  );
};
