import cn from 'classnames';
import { marked } from 'marked';
import { FC } from 'react';

import { formatDate, highlightAndSanitizeHtml } from '../../utils/helpers';
import styles from './DocumentCard.module.scss';
import { IDocumentCard } from './types';

export const DocumentCard: FC<IDocumentCard> = ({
  name,
  file,
  created_at,
  deleted_at,
  updated_at,
  onClick,
  isSelected,
  isDeleted,
  query,
}) => {
  const lowerCaseQuery = query?.toLowerCase();
  const slicedName = name?.slice(0, 60);
  const sanitizedName = highlightAndSanitizeHtml(slicedName, lowerCaseQuery);
  const previewMarkdown = file?.slice(0, 70);
  const rawHtml = marked.parse(previewMarkdown ? previewMarkdown : '');
  // This is for sanitizing potentially dangerous html
  const sanitizedPreview = highlightAndSanitizeHtml(rawHtml as string, lowerCaseQuery);
  const formattedDate = formatDate(isDeleted ? deleted_at : updated_at ? updated_at : created_at);

  return (
    <button
      className={cn(styles.documentCard, { [styles.documentCardSelected]: isSelected })}
      onClick={onClick}
    >
      <div
        className={styles.documentCardTitle}
        dangerouslySetInnerHTML={{ __html: sanitizedName }}
      />
      <div
        className={styles.documentCardPreview}
        dangerouslySetInnerHTML={{ __html: sanitizedPreview }}
      />
      <div className={styles.documentCardTime}>{formattedDate}</div>
    </button>
  );
};
