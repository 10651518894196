import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const ItalicFontIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M11.6663 3.95801H9.79134M11.6663 3.95801H13.5413M11.6663 3.95801L8.33301 16.0413M8.33301 16.0413H6.45801M8.33301 16.0413H10.208"
        stroke="#EBECED"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
