import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const PuzzleIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M12.3776 5.14795H15.6492V8.36314C15.1696 8.36314 13.6298 8.16528 13.6298 9.97074C13.6298 11.3967 14.8607 11.5219 15.6492 11.5219V14.7935H12.3776C12.3776 16.0627 11.758 16.7396 10.8016 16.7396C9.84524 16.7396 9.27526 15.7726 9.27526 14.7935H6.20114V11.5219C5.54113 11.5219 4.35083 11.5783 4.35083 9.97074C4.35083 8.68466 5.58065 8.36314 6.20114 8.36314V5.14795H9.27526C9.28465 4.51807 9.53809 3.26025 10.8016 3.26025C12.0651 3.26025 12.3776 4.38263 12.3776 5.14795Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
