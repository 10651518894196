export default function CopyIconChat({ copied, fill }: { copied: boolean; fill?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={copied ? fill ?? 'var(--app-light)' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41536 12.708C4.60995 12.708 3.95703 12.0551 3.95703 11.2497V5.62467C3.95703 4.7042 4.70322 3.95801 5.6237 3.95801H11.2487C12.0541 3.95801 12.707 4.61093 12.707 5.41634"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.29297 8.95866C7.29297 8.03818 8.03916 7.29199 8.95964 7.29199H14.3763C15.2968 7.29199 16.043 8.03818 16.043 8.95866V14.3753C16.043 15.2958 15.2968 16.042 14.3763 16.042H8.95964C8.03916 16.042 7.29297 15.2958 7.29297 14.3753V8.95866Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
