import './index.scss';

import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { Button } from '../../button';
import { LoadingOverlay } from '../../loading';
import { Modal } from '..';

interface IConfirmationModalCallerProps {
  title?: string;
  text?: string;
  danger?: boolean;
  primary?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  theme?: 'default' | 'blob';
  handleConfirm?: () => Promise<void>;
  handleCancel?: () => Promise<void>;
}
export const confirm = (props: IConfirmationModalCallerProps) => {
  const rootNode = document.querySelector('#root');

  const portalNode = document.createElement('div');

  portalNode.className = 'alchemy-modal-portal';

  if (rootNode !== null) {
    const root = createRoot(portalNode);

    root.render(<ConfirmationModal handleDestroy={() => root.unmount()} {...props} />);

    rootNode.append(portalNode);
  }
};

interface IConfirmationModal {
  handleDestroy?: () => void;
}

export function ConfirmationModal({
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  ...props
}: IConfirmationModalCallerProps & IConfirmationModal) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  async function handleConfirm() {
    setIsLoading(true);
    await props.handleConfirm?.();
    setIsLoading(false);
    setIsVisible(false);

    setTimeout(() => {
      props.handleDestroy?.();
    }, 500);
  }

  async function handleCancel() {
    setIsLoading(true);
    await props.handleCancel?.();
    setIsLoading(false);
    setIsVisible(false);

    setTimeout(() => {
      props.handleDestroy?.();
    }, 500);
  }

  return (
    <Modal
      visible={isVisible}
      theme={props.theme}
      className="alchemy-confirmation-modal"
      title={props.title ?? 'Action confirmation'}
      onClose={() => handleCancel()}
    >
      <div className="content">
        <p className="modal-text">{props.text}</p>
        <div className="alchemy-footer">
          <Button size="mini" onClick={() => handleCancel()} className="cancel-btn">
            {cancelButtonText}
          </Button>
          <Button
            size="mini"
            onClick={() => handleConfirm()}
            danger={props.danger}
            active={props.primary}
            className="confirm-btn"
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
      <LoadingOverlay active={isLoading} />
    </Modal>
  );
}
