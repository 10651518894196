import { StringTools } from '../tools/string';
import { StorageKey } from './keys';

interface ILocalStorageItem {
  key: string;
  isAlchemyStorageData: boolean;
  data: any;
  createdTime: number;
  updatedTime: number;
}

export class ExtensionStorage {
  static prefix = 'achemyStorage-' + process.env.NODE_ENV + '-';

  private static generateStorageKey(source: StorageKey | string): string {
    return (
      this.prefix +
      (typeof source === 'string' ? source : StringTools.lowercaseFirstLetter(StorageKey[source]))
    );
  }

  private static getLocalStorageItem(localStorageKey: string): ILocalStorageItem | null {
    const itemRAW = localStorage.getItem(localStorageKey);
    const item = itemRAW ? JSON.parse(itemRAW) : null;

    return item?.isAlchemyStorageData ? (item as ILocalStorageItem) : null;
  }

  private static getAllStorageKeys() {
    return Object.keys(localStorage).filter(key => key.includes(this.prefix));
  }

  public static getAll(): any[] {
    const result: any[] = [];

    this.getAllStorageKeys().forEach(key => {
      const item = ExtensionStorage.getLocalStorageItem(key);

      result.push({
        [key]: item ? { ...item, decodedData: StringTools.base64DecodeUnicode(item.data) } : null,
      });
    });

    return result;
  }

  public static getItem(key: StorageKey | string): string | null {
    const localStorageKey = ExtensionStorage.generateStorageKey(key);

    const item = ExtensionStorage.getLocalStorageItem(localStorageKey);

    return item?.data ? StringTools.base64DecodeUnicode(item.data) : null;
  }

  public static setItem(key: StorageKey | string, value: string): void {
    const localStorageKey = ExtensionStorage.generateStorageKey(key);

    const existingItem = ExtensionStorage.getLocalStorageItem(localStorageKey);

    const createdTime = existingItem ? existingItem.createdTime : Date.now();

    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        key: key,
        isAlchemyStorageData: true,
        data: StringTools.base64EncodeUnicode(value),
        createdTime,
        updatedTime: Date.now(),
      })
    );
  }

  public static removeItem(key: StorageKey): void {
    const localStorageKey = ExtensionStorage.generateStorageKey(key);

    localStorage.removeItem(localStorageKey);
  }

  public static clear(): void {
    this.getAllStorageKeys().forEach(key => {
      localStorage.removeItem(key);
    });
  }
}
