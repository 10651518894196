import cn from 'classnames';
import { FC } from 'react';

import emptyContentImage from '../../assets/images/empty-content.png';
import { Button } from '../Button';
import styles from './EmptyPageAlert.module.scss';

type Props = {
  title: string;
  description?: string;
  buttonText?: string;
  onClick?: () => void;
  to?: string;
  className?: string;
};

export const EmptyPageAlert: FC<Props> = ({
  title,
  description,
  buttonText,
  onClick,
  className,
  to,
}) => {
  return (
    <div className={cn(styles.alert, className)}>
      <img width="280" height="163" src={emptyContentImage} alt="empty content" />
      <div className={styles.alertContent}>
        <h2 className={styles.alertTitle}>{title}</h2>
        <p className={styles.alertDescription}>{description}</p>
        {buttonText && (to || onClick) ? (
          onClick ? (
            <Button className={styles.alertButton} onClick={onClick}>
              {buttonText}
            </Button>
          ) : (
            <Button className={styles.alertButton} to={to}>
              {buttonText}
            </Button>
          )
        ) : null}
      </div>
    </div>
  );
};
