import { createApi } from '@reduxjs/toolkit/query/react';

import * as I from '../models/IPromptLibrary';
import * as IS from '../models/IShared';
import { IParams } from '../pages/PromptLibraryPage';
import { baseQuery } from '../utils/baseQuery';
import { transformResponseWithStatusCode } from '../utils/transformResponse/transformResponseWithStatusCode';

export const promptLibraryApi = createApi({
  reducerPath: 'promptLibrary',
  baseQuery,
  refetchOnFocus: true,
  endpoints: builder => ({
    getPrompts: builder.query<IS.IResponse<I.IPrompt[]>, IParams>({
      query: params => {
        const { sort, ...otherParams } = params;

        return { url: '/shop/search/', params: String(sort) === '0' ? otherParams : params };
      },
    }),

    getSubCategoriesByCategory: builder.query<IS.IResponse<IS.ICategory[]>, number>({
      query: id => `/shop/get-subcategories-by-category/${id}`,
    }),

    getCategories: builder.query<IS.IResponse<IS.ICategory[]>, void>({
      query: () => '/shop/get-categories/',
    }),

    getCustomPrompts: builder.query<IS.IResponse<any>, unknown>({
      query: params => ({ url: '/shop/get-custom-user-prompts/', params }),
    }),

    setFavorite: builder.mutation<unknown, I.ISetFavoriteRequest>({
      query: body => ({ url: '/chat/prompt-favourite/', body, method: 'POST' }),
      transformResponse: transformResponseWithStatusCode,
    }),

    getTags: builder.query<IS.IResponse<I.ITag[]>, unknown>({
      query: () => '/shop/get-tags/',
    }),

    createCustomPrompt: builder.mutation<I.ICreateCustomPromptRes, I.ICreateCustomPromptRequest>({
      query: body => ({ url: '/shop/create-custom-user-prompt/', body, method: 'POST' }),
    }),

    createCustomInputField: builder.mutation<unknown, I.ICreateCustomPromptInputField[]>({
      query: body => ({ url: '/shop/create-input-field/', body, method: 'POST' }),
    }),

    updateCustomPrompt: builder.mutation<unknown, I.IUpdateCustomPrompt>({
      query: ({ id, body }) => ({
        url: `/shop/update-custom-user-prompt/${id}`,
        body,
        method: 'PUT',
      }),
    }),
    getShareCustomPrompt: builder.query<I.ICustomPrompt, string>({
      query: id => `/shop/share-custom/${id}`,
      keepUnusedDataFor: 0,
    }),
    getSharedCustomPrompt: builder.query<I.ICustomPrompt, string>({
      query: id => `/shop/shared-custom/${id}`,
      keepUnusedDataFor: 0,
    }),
    getAddSharedCustomPrompt: builder.query<I.ICustomPrompt, string>({
      query: id => `/shop/add-shared-custom/${id}`,
    }),
    customPromptClickAmountUpdate: builder.mutation<
      I.IResponseUpdateCustomPromptClicksAndLookups,
      I.IUpdateCustomPromptClicksAndLookups
    >({
      query: body => ({
        url: '/shop/custom-prompt-click-amount-update/',
        method: 'POST',
        body,
      }),
    }),
    customPromptLookupsAmountUpdate: builder.mutation<
      I.IResponseUpdateCustomPromptClicksAndLookups,
      I.IUpdateCustomPromptClicksAndLookups
    >({
      query: body => ({
        url: '/shop/custom-prompt-lookups-amount-update/',
        method: 'POST',
        body,
      }),
    }),
    addToCommand: builder.mutation<unknown, I.IAddToCommand>({
      query: body => ({ url: '/shop/add-custom-command/', body, method: 'POST' }),
      transformResponse: transformResponseWithStatusCode,
    }),
    createPromptStep: builder.mutation<unknown, I.ICreateCustomPromptStep[]>({
      query: body => ({ url: '/shop/create-prompt-step/', body, method: 'POST' }),
    }),
  }),
});

export const {
  useGetPromptsQuery,
  useLazyGetPromptsQuery,
  useGetCategoriesQuery,
  useGetCustomPromptsQuery,
  useLazyGetCustomPromptsQuery,
  useSetFavoriteMutation,
  useGetTagsQuery,
  useCreateCustomPromptMutation,
  useCreateCustomInputFieldMutation,
  useGetSubCategoriesByCategoryQuery,
  useUpdateCustomPromptMutation,
  useGetShareCustomPromptQuery,
  useGetSharedCustomPromptQuery,
  useGetAddSharedCustomPromptQuery,
  useCustomPromptClickAmountUpdateMutation,
  useCustomPromptLookupsAmountUpdateMutation,
  useAddToCommandMutation,
  useCreatePromptStepMutation,
} = promptLibraryApi;
