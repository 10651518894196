export const replaceNewLinesWithBr = (str: string) => {
  if (str) {
    return str.replace(/\n\n/g, '<br /><br />');
  }
}

export const replaceUnderscores = (str: string) => {
  if (str) {
    return str.replace(/_/g, ' ');
  }
};
