import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { selectTheme, ThemeOptions } from '../../redux/Theme';
import styles from './Button.module.scss';

interface IButton {
  onClick?: (arg: any) => void;
  children: React.ReactNode;
  className?: string;
  ariaLabel?: string;
  disabled?: boolean;
  isSecondary?: boolean;
  isRed?: boolean;
  type?: 'submit' | 'button' | 'reset';
  to?: string;
}

export const Button: React.FC<IButton> = ({
  onClick,
  children,
  className,
  ariaLabel,
  disabled = false,
  type = 'submit',
  isSecondary,
  isRed,
  to,
}) => {
  const theme = useTypedSelector(selectTheme);

  const classes = cn(
    styles.button,
    { [styles.purple]: theme === ThemeOptions.Purple },
    { [styles.secondary]: isSecondary },
    { [styles.red ]: isRed },
    { [styles.disabled]: disabled },
    className
  );

  return to ? (
    <Link className={classes} to={to}>
      {children}
    </Link>
  ) : (
    <button
      onClick={onClick}
      className={classes}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
