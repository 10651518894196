import cn from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import qs from 'qs';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import Emitter, { EMITTER_EVENTS } from '../../../../utils/eventEmitter';
import { Button } from '../../../Button';
import styles from './CreateCategoryModal.module.scss';
import { validationSchema } from './validationService';

interface IProps {
  onClose: () => void;
  emmitType: EMITTER_EVENTS.MODAL_CREATE_CATEGORY;
  // id: number;
}

interface Param {
  name: string;
  id: number;
}

interface IFormValues {
  title: string;
}

export const CreateCategoryModal: FC<IProps> = ({ onClose, emmitType }) => {
  const location = useLocation();
  const getBreadcrumbFromUrlParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const paramsArray = searchParams.get('myContentParams');

    if (paramsArray) {
      const decodedParamsArray = decodeURIComponent(paramsArray);
      const parsedParams: unknown = qs.parse(decodedParamsArray);
      const parsedParamsArray: Param[] = Object.values(parsedParams).map((param: any) => ({
        name: param.name,
        id: Number(param.id),
      }));

      const breadcrumbs = parsedParamsArray.map((it, idx, arr) =>
        arr.length - 1 === idx ? { ...it, hasChildren: false } : { ...it, hasChildren: true }
      );

      return breadcrumbs;
    }

    return [{ id: 0, name: 'My Content', hasChildren: false }];
  };

  const onCreate = (values: IFormValues) => {
    const breadcrumbs = getBreadcrumbFromUrlParams();

    Emitter.emit(emmitType, { name: values.title, breadcrumbs });
    onClose();
  };

  return (
    <Formik initialValues={{ title: '' }} onSubmit={onCreate} validationSchema={validationSchema}>
      {({ errors, touched }) => (
        <Form>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <h1 className={styles.title}>Create folder</h1>
            </div>
            <div className={styles.content}>
              <label htmlFor="title" className={styles.inpTitle}>
                Folder name
              </label>
              <Field
                name="title"
                type="text"
                id="title"
                placeholder="Untitled folder"
                className={cn(styles.inpName, {
                  [styles.inpError]: errors['title'] && touched['title'],
                })}
                autoComplete="off"
              />
              <ErrorMessage component="div" name="title" className={styles.inputErrorMessage} />
            </div>
            <div className={styles.action}>
              <Button>Create folder</Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
