import './index.scss';

import { IDocument } from '../../../../../../../interfaces';
import { DocumentsManager } from '../../../../../../components/documents';
import { Modal } from '../../../../../../design/components/modal';

export interface IDocumentsManagerModal {
  visible: boolean;
  onClose: () => void;
  onReplaceExistingDocument: (document: IDocument) => void;
  onCreateNewDocument: () => void;
}
export function DocumentsManagerModal(props: IDocumentsManagerModal) {
  return (
    <Modal onClose={props.onClose} className="documents-manager-modal" visible={props.visible}>
      <DocumentsManager {...props} />
    </Modal>
  );
}
