import './index.scss';

import { createRef } from 'react';

import { IInput, IPrompt } from '../../../../../../../interfaces';
import { useOutsideClickDetector } from '../../../../../../../tools/events';
import CommandIcon from '../../../../../../design/assets/svg/icons/CommandIcon';
import { SmoothVisibility } from '../../../../../../design/components/smoothVisibility';

export interface ITextareaCommand {
  _hovered?: boolean;
  prompt: string;
  command: string;
  related_prompt: null | IPrompt;
  description?: string;
  input_fields?: IInput[];
}

export interface ITextareaCommandBE {
  prompt: string;
  command: string;
  related_prompt: null | IPrompt;
  input_fields?: IInput[];
}

interface IProps {
  title?: string;
  onClickCommand: (command: ITextareaCommand) => void;
  commandItems: ITextareaCommand[];
  onClickOutside: () => void;
  visible: boolean;
  design: 'default' | 'alternative';
  floating?: boolean;
}

export const TextAreaCommand: React.FC<IProps> = ({
  design = 'default',
  title,
  onClickCommand,
  commandItems,
  onClickOutside,
  visible,
  floating = true,
}: any) => {
  const ref = createRef<HTMLDivElement>();

  useOutsideClickDetector(ref, onClickOutside);

  return (
    <SmoothVisibility
      visible={visible}
      className={'alchemy-commands-list-wrapper design-' + design}
      containerRef={ref}
    >
      {design === 'alternative' && <p className="commands-list-title">{title ?? 'Commands'}</p>}
      <SmoothVisibility
        as="ul"
        visible={visible}
        id="alchemy-commands-list"
        className={`alchemy-commands-list active ${floating ? '' : 'not-floating'}`}
      >
        {commandItems.length > 0 ? (
          commandItems.map((item: any, idx: any) => (
            <li
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();

                onClickCommand(item);
              }}
              className={
                item.isHover ? 'alchemy-command visible item-hover' : 'alchemy-command visible'
              }
              key={`${idx}-${item.command}`}
            >
              <div className="alchemy-command-content">
                <span className="flex-wrapper">
                  <SmoothVisibility
                    visible={item.command.startsWith('/') && design !== 'alternative'}
                    className="command-slash"
                    as="b"
                  >
                    /
                  </SmoothVisibility>
                  <SmoothVisibility
                    visible={design === 'alternative'}
                    className="command-search-icon"
                    as="b"
                  >
                    <CommandIcon />
                  </SmoothVisibility>
                  {design === 'default'
                    ? item.command.startsWith('/')
                      ? item.command.slice(1)
                      : item.command
                    : !!item.related_prompt || item.prompt.length > 10
                    ? item.command.startsWith('/')
                      ? item.command.substring(1)
                      : item.command
                    : item.prompt}
                </span>{' '}
                {item?.description}
              </div>
            </li>
          ))
        ) : (
          <p className="not-found">{title ?? 'Commands'} not found</p>
        )}
      </SmoothVisibility>
    </SmoothVisibility>
  );
};
