export interface ICheckIcon {
  stroke?: string;
}
export default function CheckIcon(props: ICheckIcon) {
  return (
    <svg
      stroke={props.stroke ?? 'var(--app-text)'}
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="10"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  );
}
